import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  CircularProgress,
  Fade,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Chip as MuiChip,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Toolbar,
  Tooltip,
  Typography,
  Alert,
  AlertTitle,
  LinearProgress,
  TextField,
  useMediaQuery,
  tooltipClasses,
  TooltipProps,
  CardHeader,
  CardActions,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridState,
  gridPaginationModelSelector,
  useGridApiRef,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import { IGraphUser } from "../../types/user";
import AzureADAvatar from "../avatars/AzureADAvatar";
import {
  useDeleteContractMutation,
  useGetContractsQuery,
  useGetPromptsQuery,
} from "../../redux/slices/indexApiSlice";
import { Delete, LegendToggle } from "@mui/icons-material";
import { format, set } from "date-fns";
import VendorAvatar from "../avatars/VendorAvatar";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";
import { Check, Star } from "react-feather";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import EnhancedDataGridToolbar from "./EnhancedDataGridToolbar";
import ContractDialog from "../dialogs/ContractDialog";
import { DialogMode } from "../../types/dialogmode";
import { ContractStatus, IContract } from "../../types/contract";
import { useGetDriveItemsQuery } from "../../redux/slices/graphApiSlice";
import { AIReadingStatus } from "../../types/contract";
import { useTheme } from "@mui/material/styles";
const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const Chip = styled(MuiChip)<{ color?: string }>`
  height: 24px;
  border-radius: 12px;
  // font-size: 85%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"]}; // .light
  // color: ${(props) => props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent", // theme.palette.common.white,
    // color: "rgba(0, 0, 0, 0.87)",
    // boxShadow: theme.shadows[1],
    // fontSize: 11,
  },
}));

interface IDataGridContractPromptResponsesProps {
  managed?: boolean;
}

function DataGridContractPromptResponses({
  ...props
}: IDataGridContractPromptResponsesProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const [searchText, setSearchText] = useState<string>("");
  const [title, setTitle] = useState<string>("");

  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);

  const { data: prompts = [], isSuccess: promptsLoaded } = useGetPromptsQuery();

  const {
    data: contracts = [],
    isLoading: contractsLoading,
    isSuccess: contractsLoaded,
  } = useGetContractsQuery(ContractStatus.ALL);

  // every prompt will be a column
  const nameColumn: GridColDef = {
    // flex: 1,
    field: "name",
    headerName: t("Name")!,
    disableColumnMenu: true,
    cellClassName: "name-column--cell",
    minWidth: 330,
    renderCell: (cellValues: any) => {
      return (
        <LightTooltip
          placement="top-start"
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -14],
                  },
                },
              ],
            },
          }}
          title={
            <React.Fragment>
              <Card variant="outlined">
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {cellValues?.row?.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {cellValues?.row?.summary}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    onClick={() => navigate(`/contract/${cellValues?.row?.id}`)}
                  >
                    {t("go_to_contract")}
                  </Button>
                </CardActions>
              </Card>
            </React.Fragment>
          }
        >
          <Typography
            variant="body2"
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {/* {cellValues.row?.analyzeStatus === ReadingStatus.READING && (
                <Chip
                  sx={{ mr: 2 }}
                  label={t("Analyzing")}
                  color="primary"
                  variant="outlined"
                />
              )} */}

            {cellValues.row?.analyzeStatus === AIReadingStatus.ANALYZING && (
              <Chip
                sx={{ mr: 2 }}
                label={t("Reading")}
                color="primary"
                variant="outlined"
              />
            )}

            {cellValues?.row?.name}
          </Typography>
        </LightTooltip>
      );
    },
  };

  const promptColumns: GridColDef[] = prompts
    ?.filter((p) => !p.hidden)
    .map((prompt, i) => {
      const column: GridColDef = {
        flex: 1,
        field: prompt.title ? t(prompt.title) : "",
        headerName: prompt?.title ? t(prompt?.title)! : "",
        disableColumnMenu: true,
        minWidth: 140,
        valueGetter: (params: any) => {
          const contract: IContract = params?.row;
          const response = contract?.prompts?.find(
            (c) => prompt.id && c.id === prompt.id
          );

          return response?.text;
        },
        renderCell: (cellValues: any) => {
          const contract: IContract = cellValues?.row;

          const details = contract?.prompts?.find(
            (c) => prompt.id && c.id === prompt.id
          )?.text;

          const hasDetails = details && details.length > 0;

          return (
            <Box>
              <Typography variant="body2">
                {hasDetails ? (
                  <LightTooltip
                    placement="left-start"
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, -14],
                            },
                          },
                        ],
                      },
                    }}
                    title={
                      <React.Fragment>
                        <Card variant="outlined">
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                            >
                              {prompt?.title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {details}
                            </Typography>
                          </CardContent>
                          <CardActions>
                            <Button
                              size="small"
                              onClick={() =>
                                navigate(`/contract/${contract?.id}`)
                              }
                            >
                              {t("View in contract")}
                            </Button>
                          </CardActions>
                        </Card>
                      </React.Fragment>
                    }
                  >
                    <IconButton>
                      <Check />
                    </IconButton>
                  </LightTooltip>
                ) : (
                  <span />
                )}
              </Typography>
            </Box>
          );
        },
      };

      return column;
    });

  const totalColumn: GridColDef = {
    field: "total",
    headerName: t("Score")!,
    disableColumnMenu: true,
    minWidth: 100,
    valueGetter: (params: any) => {
      return params?.row?.prompts?.length;
    },
    renderCell: (cellValues: any) => {
      return (
        <Typography variant="body2">
          {getPercentage(cellValues?.row?.prompts?.length)}%
        </Typography>
      );
    },
  };

  const columns = [nameColumn, totalColumn, ...promptColumns];

  useEffect(() => {
    if (contractsLoaded) {
      const p = contracts?.filter(
        (c: IContract) => c.status === ContractStatus.PUBLISHED
      );

      const d = contracts?.filter(
        (c: IContract) => c.status !== ContractStatus.PUBLISHED
      );

      const pText = p.length === 1 ? t("contracts") : t("contracts");
      const draftsText = d.length === 1 ? t("draft") : t("drafts");

      const els = [];
      if (p.length > 0) {
        els.push(`${p?.length} ${pText}`);
      }

      if (d.length > 0) {
        els.push(`${d?.length} ${draftsText}`);
      }

      const newTitle = els.join(", ").toLowerCase();
      setTitle(newTitle);
    }
  }, [contracts, contractsLoaded]);

  const handleRowDoubleClick = (params: any) => {
    navigate(
      `/${props.managed ? "managed-" : ""}contract/${params?.row?.id}/${
        DialogMode.ViewOnly
      }`,
      {
        state: { reload: true },
      }
    );
  };

  const handleViewDetails = (event: any, cellValues: any) => {
    // stop the row selection event from triggering
    event.stopPropagation();

    navigate(
      `/${props.managed ? "managed-" : ""}contract/${cellValues?.row?.id}/${
        DialogMode.ViewOnly
      }`,
      { state: { reload: true } }
    );
  };

  const handleEditDetails = (event: any, cellValues: any) => {
    // stop the row selection event from triggering
    event.stopPropagation();

    navigate(
      `/${props.managed ? "managed-" : ""}contract/${cellValues?.row?.id}/${
        DialogMode.Edit
      }`,
      { state: { reload: true } }
    );
  };

  const getPercentage = (value: number) => {
    const perc =
      ((value / prompts?.filter((p) => !p.hidden).length) * 1000) / 10;

    // round to 0 decimal
    return Math.round(perc);
  };

  const isUpXxl = useMediaQuery(theme.breakpoints.up(1920));
  const isUpXl = useMediaQuery(theme.breakpoints.up("xl"));
  const isUpLg = useMediaQuery(theme.breakpoints.up("lg"));
  const isUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const isUpSm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Paper mb={16}>
      <Box
        // height="55vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
        }}
      >
        <Paper p={4}>
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            alignItems="center"
          >
            <Grid size={12}>
              <TextField // Search field
                variant="outlined"
                label={t("Search")}
                fullWidth
                value={searchText}
                onChange={(event) => {
                  setSearchText(event.target.value);
                }}
                sx={{ mb: 2 }}
              />
            </Grid>
          </Grid>
        </Paper>

        {contractsLoading && <CircularProgress sx={{ margin: 4 }} />}
        {contractsLoaded && contracts?.length === 0 && (
          <Box p={6}>
            <CustomNoRowsOverlay label={t("No contracts")} />
          </Box>
        )}

        {!contractsLoading && contractsLoaded && contracts?.length > 0 && (
          <DataGrid
            initialState={{
              pagination: { paginationModel: { page: 0, pageSize: 25 } },
            }}
            pageSizeOptions={[5, 10, 25]}
            rows={contracts}
            columns={columns}
            autoHeight
            checkboxSelection
            loading={contractsLoading}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            filterModel={{
              items: [
                {
                  id: 1,
                  field: "name",
                  operator: "contains",
                  value: searchText,
                },
              ],
            }}
            slots={{
              toolbar: () => (
                <EnhancedDataGridToolbar
                  view="table"
                  // setView={setView}
                  rowSelectionModel={rowSelectionModel}
                  title={props.managed ? t("Managed Contracts") : title}
                  isLoading={contractsLoading}
                  customActions={[]}
                />
              ),
              noRowsOverlay: () => (
                <CustomNoRowsOverlay label={t("No contracts")} />
              ),
            }} // GridToolbar    GridToolbarQuickFilter
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
              pagination: {
                showFirstButton: true,
                showLastButton: true,
              },
              // pagination: {
              //   labelRowsPerPage: t("Rows per page"),
              //   labelDisplayedRows(paginationInfo) {
              //     return `${paginationInfo.from}-${paginationInfo.to} ${t("of")} ${paginationInfo.count}`;
              //   },
              // },
            }}
            sx={{
              "& .MuiDataGrid-cell": {
                alignContent: "center",
              },
            }}
            density="comfortable"
            onRowDoubleClick={handleRowDoubleClick}
          />
        )}
      </Box>
    </Paper>
  );
}

export default DataGridContractPromptResponses;
