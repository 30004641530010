import React, { useEffect, useState, useTransition } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Avatar,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  TextField as MuiTextField,
  Typography,
  CircularProgress,
  CardMedia,
  AlertTitle,
  Alert,
  CardHeader,
  Button,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { spacing, SpacingProps } from "@mui/system";
import { useTranslation } from "react-i18next";
import {
  useGetConnectedDrivesQuery,
  useGetSettingsQuery,
  useSyncConnectedDriveMutation,
} from "../../../redux/slices/indexApiSlice";

import { DialogMode } from "../../../types/dialogmode";

import { ISharePointConnection } from "../../../types/sharepointconnection";
import SharePointIcon from "../../../icons/SharePointIcon";
import { IConnectedDrive } from "../../../types/connecteddrive";
import ConnectedSharePointDriveDialog from "../../../components/dialogs/ConnectedSharePointDriveDialog";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  useGetDriveItemsQuery,
  useLazyGetDriveItemsQuery,
} from "../../../redux/slices/graphApiSlice";
import { IDriveItem } from "../../../types/driveItem";
import ConnectedDriveCard from "../../../components/cards/ConnectedDriveCard";
import AnalyzingContractsLoader from "../../../components/AnalyzingContractsLoader";
import MSTeamsIcon from "../../../icons/MSTeamsIcon";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

// enum for the data location
enum DataLocationOption {
  Default = "default",
  SharePoint = "sharepoint",
  AzureBlobStorage = "azureblobstorage",
}

function ConnectedDrives() {
  const { t } = useTranslation();

  const [selectedDataLocation, setSelectedDataLocation] = useState<string>(
    DataLocationOption.Default
  );
  const [selectedSharePointConnection, setSelectedSharePointConnection] =
    useState<ISharePointConnection>();

  const { data: settings } = useGetSettingsQuery();
  const {
    data: connectedDrives = [],
    isLoading: connectedDrivesLoading,
    isSuccess: connectedDrivesLoaded,
    refetch: refetchConnectedDrives,
    isError: connectedDrivesError,
    error: connectedDrivesErrorObject,
  } = useGetConnectedDrivesQuery();

  return (
    <React.Fragment>
      <Helmet title={t("Trusted Partner")!} />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid>
          <Typography variant="h3" gutterBottom display="inline">
            {t("Connected Drives")}
          </Typography>
          {/* 
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/admin">
              {t("Admin")}
            </Link>
            <Typography>{t("Connected Drives")}</Typography>
          </Breadcrumbs> */}
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container direction="column" gap={6}>
        <Grid>
          <AnalyzingContractsLoader />
        </Grid>
        {!settings?.microsoftTeamsId && (
          <Grid>
            <ConnectedSharePointDriveDialog
              mode={DialogMode.Add}
              refetch={refetchConnectedDrives}
            />
          </Grid>
        )}

        <Grid>
          {settings?.microsoftTeamsId ? (
            <Card>
              <CardHeader
                avatar={<MSTeamsIcon />}
                title={t("Microsoft Teams")}
                subheader={t("Connected Drives")}
              />
              <CardContent>
                <Typography variant="body1" gutterBottom>
                  {t("Connected to MS Teams ID", {
                    id: settings.microsoftTeamsId,
                  })}
                </Typography>
              </CardContent>
            </Card>
          ) : (
            <>
              {connectedDrivesLoading ? (
                <CircularProgress />
              ) : (
                <Grid container spacing={6} direction="column">
                  <Grid>
                    <Grid container gap={3} flexDirection="column">
                      {connectedDrivesError && (
                        <Grid>
                          <Alert severity="error">
                            <AlertTitle>{t("An error occurred!")}</AlertTitle>
                            {JSON.stringify(connectedDrivesErrorObject)}
                          </Alert>
                        </Grid>
                      )}

                      <Grid>
                        <Grid container display="flex" direction="row" gap={3}>
                          {connectedDrives?.map(
                            (connectedDrive: IConnectedDrive, i: number) => (
                              <Grid
                                key={`connectedDriveCard-${i}`}
                                size={{
                                  sm: 12,
                                  md: 6,
                                  lg: 3,
                                }}
                                sx={{ width: "100%" }}
                              >
                                <ConnectedDriveCard
                                  connectedDrive={connectedDrive}
                                />
                              </Grid>
                            )
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ConnectedDrives;
