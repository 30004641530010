import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { blue, grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import {
  Box,
  Chip as MuiChip,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  StepButton,
  Typography,
  useMediaQuery,
  useTheme,
  Menu,
  ListItemSecondaryAction,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  Fade,
  Alert,
  AlertTitle,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { IContract } from "../../../../types/contract";
import OwlAvatarIcon from "../../../../icons/OwlAvatarIcon";
import { ContractExtractionResult } from "../../../../types/aiOutput";
import {
  useAddHumanDataVerificationMutation,
  useGetHumanDataVerificationsQuery,
  useUpdateContractMutation,
} from "../../../../redux/slices/indexApiSlice";
import AISuggestion from "./AISuggestion";
import { HowToReg } from "@mui/icons-material";
import VerifiedAISuggestion from "./VerifiedAISuggestion";
import useAuth from "../../../../hooks/useAuth";

interface IContractCategoryProps {
  contract: IContract;
  aiOutput?: ContractExtractionResult;
  hideTitle?: boolean;
}
function ContractCategory({ ...props }: IContractCategoryProps) {
  const { contract } = props;
  const { t } = useTranslation();

  const { data: verifications } = useGetHumanDataVerificationsQuery(contract);

  const verificationRecord = verifications?.find((v) => v.field === "category");

  const isVerified =
    contract?.category || verificationRecord?.isCorrect ? true : false;

  const isAiSuggestion = props.aiOutput?.metadata?.structuralData?.procurement
    ?.category
    ? true
    : false;

  return (
    <Box pb={2}>
      {!props.hideTitle && (
        <Typography variant="h6" mb={2}>
          {t("Category")}
        </Typography>
      )}

      <VerifiedAISuggestion
        isVerified={isVerified}
        content={
          <Typography variant="body2">
            {/* color="textSecondary" */}
            {props.contract?.category}
          </Typography>
        }
        verificationRecord={verificationRecord}
      />

      {props.aiOutput?.metadata?.structuralData?.procurement?.category && (
        <AISuggestion
          contract={contract}
          isAiSuggestion={isAiSuggestion}
          isVerified={isVerified || verificationRecord?.isCorrect !== undefined}
          field="category"
          value={
            props.aiOutput?.metadata?.structuralData?.procurement?.category
          }
          content={
            <Typography variant="body1">
              {props.aiOutput?.metadata?.structuralData?.procurement?.category}
            </Typography>
          }
        />
      )}
    </Box>
  );
}

export default ContractCategory;
