import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { blue, grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import {
  Box,
  Chip as MuiChip,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  StepButton,
  Typography,
  Alert,
  AlertTitle,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { MoreHoriz as MoreHorizIcon, Person } from "@mui/icons-material";

import { IContract } from "../../../../types/contract";
import { ContractExtractionResult } from "../../../../types/aiOutput";
import OwlAvatarIcon from "../../../../icons/OwlAvatarIcon";

const ContactPersonCard = ({
  name,
  email,
  phone,
  role,
}: {
  name: string;
  email: string;
  phone: string;
  role: string;
}) => {
  return (
    <Alert severity="info" icon={<Person />} variant="outlined">
      <Typography variant="body1">{name}</Typography>
      <Typography variant="body1">{email}</Typography>
      <Typography variant="body1">{phone}</Typography>
      <Typography variant="body1">{role}</Typography>
    </Alert>
  );
};

interface IContractContactPersonsProps {
  contract?: IContract;
  aiOutput?: ContractExtractionResult;
  hideTitle?: boolean;
}
function ContractContactPersons({ ...props }: IContractContactPersonsProps) {
  const { contract } = props;
  const { t } = useTranslation();

  return (
    <Box pb={2}>
      {!props.hideTitle && (
        <Typography variant="h6" mb={2}>
          {t("Contact persons")}
        </Typography>
      )}

      {contract?.buyer && contract.buyer.contactPersons?.length > 0 && (
        <Box mb={3}>
          <Grid container spacing={3} pt={2}>
            {contract.buyer.contactPersons.map((person, i) => {
              return (
                <Grid key={`contactPerson-${i}-${person.email}`}>
                  <ContactPersonCard {...person} />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      )}

      {contract?.seller && contract.seller.contactPersons?.length > 0 && (
        <Box mb={3}>
          <Grid container spacing={3} pt={2}>
            {contract.seller.contactPersons.map((person, i) => {
              return (
                <Grid key={`contactPerson-${i}-${person.email}`}>
                  <ContactPersonCard {...person} />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      )}

      {(props.aiOutput?.metadata?.structuralData?.buyer.contactPersons?.length >
        0 ||
        props.aiOutput?.metadata?.structuralData?.seller.contactPersons
          ?.length > 0) && (
        <Grid size={12}>
          <Alert
            sx={{ mt: 2 }}
            severity="info"
            variant="outlined"
            icon={<OwlAvatarIcon />}
          >
            <Box mb={3}>
              <Grid container spacing={3} pt={2}>
                {props.aiOutput?.metadata?.structuralData?.buyer.contactPersons.map(
                  (person: any, i: number) => {
                    return (
                      <Grid key={`contactPerson-${i}-${person.email}`}>
                        <ContactPersonCard {...person} />
                      </Grid>
                    );
                  }
                )}
              </Grid>
            </Box>

            <Box mb={3}>
              <Grid container spacing={3} pt={2}>
                {props.aiOutput?.metadata?.structuralData?.seller.contactPersons.map(
                  (person: any, i: number) => {
                    return (
                      <Grid key={`contactPerson-${i}-${person.email}`}>
                        <ContactPersonCard {...person} />
                      </Grid>
                    );
                  }
                )}
              </Grid>
            </Box>
          </Alert>
        </Grid>
      )}
    </Box>
  );
}

export default ContractContactPersons;
