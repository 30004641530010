import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import {
  Avatar,
  Box,
  Button,
  Fade,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Toolbar,
  Tooltip,
  Typography as MuiTypography,
  CardActions,
  CardMedia as MuiCardMedia,
  Chip as MuiChip,
  CardActionArea,
  useTheme,
  TextField,
  Switch,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridState,
  gridPaginationModelSelector,
  useGridApiRef,
  GridRowSelectionModel,
  GridToolbarQuickFilter,
  GridLogicOperator,
  GridRowHeightParams,
} from "@mui/x-data-grid";
import { SpacingProps, alpha, spacing } from "@mui/system";

import {
  useUpdatePromptMutation,
  useDeletePromptMutation,
  useGetPromptsQuery,
} from "../../redux/slices/indexApiSlice";
import { Delete, LegendToggle } from "@mui/icons-material";
import VendorAvatar from "../avatars/VendorAvatar";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";
import { AvatarGroup as MuiAvatarGroup } from "@mui/material";
import { IKPI, IVendorKPI } from "../../types/kpi";
import { Check, Eye, EyeOff } from "react-feather";
import KPIDialog from "../dialogs/KPIDialog";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import EnhancedDataGridToolbar from "./EnhancedDataGridToolbar";
import { t } from "i18next";
import { DialogMode } from "../../types/dialogmode";
import EnhancedDataGrid from "./EnhancedDataGrid";
import { fetchKpi } from "../../redux/slices/kpiSlice";
import PromptDialog from "../dialogs/PromptDialog";
import { IPrompt } from "../../types/prompt";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

interface TypographyProps extends SpacingProps {
  component?: string;
}
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
`;

const CardMedia = styled(MuiCardMedia)`
  height: 220px;
`;

const Chip = styled(MuiChip)<{ color?: string }>`
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
  margin-bottom: ${(props) => props.theme.spacing(4)};
`;

const AvatarGroup = styled(MuiAvatarGroup)`
  margin-left: ${(props) => props.theme.spacing(2)};
`;

// Type guard to check if kpi is of type IVendorKPI
function isVendorKPI(kpi: IKPI | IVendorKPI): kpi is IVendorKPI {
  return (kpi as IVendorKPI).score !== undefined;
}

type PromptCardProps = {
  image?: string;
  prompt: IPrompt;
  chip: JSX.Element;
  handleCardClick?: (selected: IPrompt) => void;
  selected?: boolean;
};
const PromptCard: React.FC<PromptCardProps> = ({
  image,
  prompt,
  chip,
  selected,
  handleCardClick,
}) => {
  const theme = useTheme();

  const [updatePrompt, { isLoading: updatePromptLoading }] =
    useUpdatePromptMutation();

  const [isDeleting, setIsDeleting] = useState(false);

  const handleOnClick = () => {
    if (handleCardClick) {
      //   setSelected(!isSelected);

      handleCardClick(prompt);
    }
  };

  const backgroundColor = selected
    ? alpha(theme.palette.primary.main, 0.16)
    : null;

  const hidePrompt = async () => {
    const updatedPrompt = { ...prompt, hidden: true };
    await updatePrompt(updatedPrompt);
  };

  const unhidePrompt = async () => {
    const updatedPrompt = { ...prompt, hidden: false };
    await updatePrompt(updatedPrompt);
  };

  return (
    <Card
      sx={{ width: 420, display: "flex", flexDirection: "column" }}
      aria-selected={selected}
      variant="outlined"
    >
      <CardActionArea
        aria-selected={selected}
        sx={{
          backgroundColor: backgroundColor,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
        onClick={handleCardClick ? handleOnClick : undefined}
      >
        <CardContent sx={{ flexGrow: 1, width: "100%" }}>
          {prompt?.title && (
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              noWrap
              textOverflow="ellipsis"
            >
              {t(prompt.title)}
            </Typography>
          )}

          {/* {chip} */}
          <Tooltip title={prompt?.body}>
            <Typography mb={4} color="textSecondary" component="p">
              {prompt?.body}
            </Typography>
          </Tooltip>
        </CardContent>
      </CardActionArea>

      <CardActions>
        <IconButton
          size="small"
          onClick={() => (prompt.hidden ? unhidePrompt() : hidePrompt())}
        >
          {prompt.hidden ? <Eye /> : <EyeOff />}
        </IconButton>

        <PromptDialog
          prompt={prompt}
          mode={DialogMode.Edit}
          iconOnly
          disabled={isDeleting}
        />

        <PromptDialog
          prompt={prompt}
          mode={DialogMode.Delete}
          iconOnly
          disabled={isDeleting}
        />
      </CardActions>
    </Card>
  );
};

interface IDataGridPromptsProps {
  items?: IPrompt[];
  compactHeader?: boolean;
  onSelect?: (selected: IPrompt[]) => void;
  selected?: string[];
  handleCardClick?: (selected: IPrompt) => void;
  view?: "cards" | "table";
}

function DataGridPrompts({ ...props }: IDataGridPromptsProps) {
  const [view, setView] = useState(props.view || "cards");
  const [searchText, setSearchText] = useState<string>("");

  const [deletePrompt, { isLoading: deleting, isSuccess: isDeleted }] =
    useDeletePromptMutation();

  const { data: prompts = [], isLoading, isSuccess } = useGetPromptsQuery();

  const [updatePrompt, { isLoading: updatePromptLoading }] =
    useUpdatePromptMutation();

  const apiRef = useGridApiRef();
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);

  useEffect(() => {
    if (props?.selected) {
      // Set the default selected items
      setRowSelectionModel(props.selected);
    }
  }, []);

  // const paginationModel = gridPaginationModelSelector(apiRef.current.state, apiRef.current.instanceId);

  useEffect(() => {
    // this is the id of the selected row/user
    console.log(rowSelectionModel);

    // get the kpi object from the id
    const selectedPrompts = handleMUIDataGrid().filter(
      (p: IPrompt) => p.id && rowSelectionModel.includes(p.id)
    );

    props?.onSelect && props?.onSelect(selectedPrompts);
  }, [rowSelectionModel]);

  const columns: GridColDef[] = [
    // {
    //   flex: 1,
    //   field: "name",
    //   headerName: t("Name")!,
    // },
    {
      // flex: 1,
      minWidth: 250,
      field: "title",
      headerName: t("Title")!,
    },
    {
      flex: 1,
      field: "body",
      headerName: t("Prompt")!,
      // wrap the text
      cellClassName: "wrap",
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.value}>
            <Typography
              sx={{
                whiteSpace: "wrap",
              }}
            >
              {cellValues.value}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      headerName: t("Action")!,
      width: 250,
      field: "action",
      disableColumnMenu: true,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <>
            <Switch
              checked={!cellValues.row?.hidden}
              onChange={(ev, checked) => {
                if (checked) {
                  unhidePrompt(cellValues.row);
                } else {
                  hidePrompt(cellValues.row);
                }
              }}
              disabled={!cellValues.row?.enabled}
            />

            <PromptDialog
              prompt={cellValues.row}
              mode={DialogMode.Edit}
              iconOnly
              disabled={!cellValues.row?.enabled}
            />

            <PromptDialog
              prompt={cellValues.row}
              mode={DialogMode.Delete}
              iconOnly
              disabled={!cellValues.row?.enabled}
            />
          </>
        );
      },
    },
  ];

  // This function is triggered when the delete button in the toolbar is clicked
  const handleToolbarDeleteClick = (event: any) => {
    console.log(rowSelectionModel);

    rowSelectionModel.forEach((row) => {
      handleDeletePrompt({ id: row } as IPrompt);
    });
  };

  const handleMUIDataGrid = () => {
    if (props.items) {
      return props.items;
    } else if (!isLoading && prompts) {
      return prompts;
    }

    return [];
  };

  const rows = handleMUIDataGrid();

  const handleCardClick = (sel: IPrompt) => {
    if (sel.id && rowSelectionModel.includes(sel?.id)) {
      const newRowSelectionModel = rowSelectionModel.filter(
        (row) => row !== sel?.id
      );
      setRowSelectionModel(newRowSelectionModel);
    } else if (sel.id) {
      const newRowSelectionModel = [...rowSelectionModel, sel?.id];
      setRowSelectionModel(newRowSelectionModel);
    }
  };

  const handleDeletePrompt = async (prompt: IPrompt) => {
    if (prompt?.id) {
      await deletePrompt(prompt.id);

      // remove the deleted item from the rowSelectionModel
      const newRowSelectionModel = rowSelectionModel.filter(
        (row) => row !== prompt?.id
      );

      setRowSelectionModel(newRowSelectionModel);
    }
  };

  const hidePrompt = async (prompt: IPrompt) => {
    const updatedPrompt = { ...prompt, hidden: true };
    await updatePrompt(updatedPrompt);
  };

  const unhidePrompt = async (prompt: IPrompt) => {
    const updatedPrompt = { ...prompt, hidden: false };
    await updatePrompt(updatedPrompt);
  };

  return (
    <Grid>
      {view === "cards" ? (
        <Paper mb={16}>
          <Box
            // height={rows.length > 0 ? "55vh" : 400}
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiToolbar-root": {
                minHeight: "64px",
              },
            }}
          >
            <EnhancedDataGridToolbar
              rowSelectionModel={rowSelectionModel}
              handleDeleteClick={handleToolbarDeleteClick}
              view={view}
              setView={setView}
              title={t("Prompts")!}
              isLoading={isLoading}
              deleting={deleting}
            />
            <Box
              p={8}
              sx={{ background: (props) => props.palette.background.default }}
              height="100%"
            >
              <Grid container spacing={6}>
                {prompts?.map((prompt: IPrompt, i: number) => (
                  <PromptCard
                    prompt={prompt}
                    handleCardClick={handleCardClick}
                    selected={rowSelectionModel.includes(prompt.id || "")}
                    chip={<Chip label="Finished" color="success" />}
                  />
                ))}
              </Grid>
            </Box>
          </Box>
        </Paper>
      ) : (
        <Paper mb={16}>
          <Paper p={4}>
            <Grid size={12}>
              <TextField // Search field
                variant="outlined"
                label={t("Search")}
                fullWidth
                value={searchText}
                onChange={(event) => {
                  setSearchText(event?.target?.value);
                }}
                sx={{ mb: 2 }}
              />
            </Grid>
          </Paper>

          <Box
            // height="55vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
            }}
          >
            {!isLoading && isSuccess && prompts?.length > 0 && (
              <DataGrid
                initialState={{
                  pagination: { paginationModel: { page: 0, pageSize: 10 } },
                }}
                apiRef={apiRef}
                pageSizeOptions={[5, 10, 25]}
                rows={rows}
                columns={columns}
                autoHeight
                checkboxSelection
                loading={isLoading}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setRowSelectionModel(newRowSelectionModel);
                }}
                rowHeight={80}
                rowSelectionModel={rowSelectionModel}
                filterModel={{
                  quickFilterLogicOperator: GridLogicOperator.And,
                  quickFilterValues: searchText.split(" "),
                  items: [],
                }}
                onRowDoubleClick={(row) => {
                  console.log(row.row);
                }}
                isRowSelectable={(params) => {
                  return params.row?.enabled;
                }}
                getRowClassName={(params) => {
                  return !params.row?.enabled ? "disabled" : "";
                }}
                slots={{
                  toolbar: () => (
                    <EnhancedDataGridToolbar
                      rowSelectionModel={rowSelectionModel}
                      handleDeleteClick={handleToolbarDeleteClick}
                      view={view}
                      setView={setView}
                      title={t("Prompts")!}
                      isLoading={isLoading}
                      deleting={deleting}
                    />
                  ),
                  noRowsOverlay: () => (
                    <CustomNoRowsOverlay label={t("No prompts")} />
                  ),
                }} // GridToolbar    GridToolbarQuickFilter
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                  pagination: {
                    showFirstButton: true,
                    showLastButton: true,
                  },
                  // pagination: {
                  //   labelRowsPerPage: t("Rows per page"),
                  //   labelDisplayedRows(paginationInfo) {
                  //     return `${paginationInfo.from}-${paginationInfo.to} ${t("of")} ${paginationInfo.count}`;
                  //   },
                  // },
                }}
                sx={{
                  "& .MuiDataGrid-cell": {
                    alignContent: "center",
                  },
                  "& .disabled": {
                    backgroundColor: (props) => props.palette.grey[100],
                    color: (props) => props.palette.grey[400],
                  },
                }}
                density="comfortable"
              />
            )}
          </Box>
        </Paper>
      )}
    </Grid>
  );
}

export default DataGridPrompts;
