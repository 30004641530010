import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import React, {
  useEffect,
  useState,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  useTheme,
  Typography,
  useMediaQuery,
  Divider as MuiDivider,
  Tabs,
  Tab,
  AppBar,
  Card,
  Paper,
  Stack,
  Icon,
  Accordion,
  AccordionSummary,
  AccordionActions,
  AccordionDetails,
  ListItem,
  ListItemIcon,
  ListItemText,
  Badge,
  BadgeProps,
  AccordionProps,
  List,
  CardHeader,
  CardContent,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SidebarAnalyzeDocument from "./SidebarRiskAnalysis";
import AIContractSigneeManagement from "./AIContractSigneeManagement";
import ContractDocumentInformation from "./ContractDocumentInformation";
import { IDocument } from "../../../../types/document";
import { DialogMode } from "../../../../types/dialogmode";
import { ISignature, ISignee } from "../../../../types/signature";
import { useGetDriveItemQuery } from "../../../../redux/slices/graphApiSlice";
import {
  AIReadingStatus,
  ContractStatus,
  IContract,
} from "../../../../types/contract";

import ContractAttachments from "./ContractAttachments";
import ContractNotes from "./ContractNotes";
import useAuth from "../../../../hooks/useAuth";
import SidebarReadDocument from "./SidebarReadDocument";
import SidebarRiskAnalysis from "./SidebarRiskAnalysis";
import AskMeAnything from "../../../components/AskMeAnything";
import SidebarRoles from "./SidebarRoles";
import OwlIcon from "../../../../icons/OwlIcon";
import {
  Assistant,
  AttachFile,
  Attachment,
  AutoAwesome,
  AutoGraph,
  Cancel,
  CancelPresentation,
  Check,
  Class,
  Close,
  CollectionsBookmark,
  Euro,
  Event,
  EventBusy,
  FolderCopy,
  Folder as FolderIcon,
  Gavel,
  Group,
  Groups,
  HistoryEdu,
  Label,
  LocalOffer,
  MenuBook,
  Money,
  PanTool,
  Person,
  Public,
  QuestionMark,
  SmartButton,
  Store,
  Style,
  Subject,
  Summarize,
  TaskAlt,
  TipsAndUpdates,
  WhereToVote,
} from "@mui/icons-material";
import OwlAvatarIcon from "../../../../icons/OwlAvatarIcon";
import NoteDialog from "../../../../components/dialogs/NoteDialog";
import ContractSummary from "./ContractSummary";
import ContractParties from "./ContractParties";
import ContractContactPersons from "./ContractContactPersons";
import ContractFinancials from "./ContractFinancials";
import { DollarSign } from "react-feather";
import ContractJurisdiction from "./ContractJurisdiction";
import ContractNoticePeriod from "./ContractNoticePeriod";
import ContractDates from "./ContractDates";
import ContractCategory from "./ContractCategory";
import ContractType from "./ContractType";
import ContractSubject from "./ContractSubject";
import {
  useAddHumanDataVerificationMutation,
  useGetAIOutputQuery,
  useGetHumanDataVerificationsQuery,
  useGetPromptsQuery,
  useUpdateContractMutation,
} from "../../../../redux/slices/indexApiSlice";
import ContractPromptResponse from "./ContractPromptResponse";
import { IPrompt } from "../../../../types/prompt";
import ContractFolders from "./ContractFolders";
import { use } from "i18next";
import {
  ContractExtractionResult,
  PromptExtractionResult,
} from "../../../../types/aiOutput";
import AISuggestion from "./AISuggestion";
import VerifiedAISuggestion from "./VerifiedAISuggestion";
import { skipToken } from "@reduxjs/toolkit/dist/query";

const Divider = styled(MuiDivider)(spacing);

// create an enum for the tabs
enum TabIndex {
  AI_ASSISTANT,
  ATTACHMENTS,
  ROLES,
  NOTES,
  AI_READ,
  SIGNEES,
}

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-flexContainer": {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flexStart",
  },
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#635ee7",
  },
});

interface StyledTabProps {
  label: string;
  tabIndex: number;
  icon:
    | string
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined;
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  border: "1px solid gray",
  // color: "rgba(255, 255, 255, 0.7)",

  "&.Mui-selected": {
    // color: "#fff",
    backgroundColor: "background.paper",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 11,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const ContractPromptAccordion = ({
  contract,
  findHighlight,
  prompt,
  isExpanded,
  aiOutput,
  aiOutputResult,
  infoFound,
}: {
  contract: IContract;
  aiOutput?: ContractExtractionResult;
  aiOutputResult?: PromptExtractionResult;
  findHighlight: (e: any, isExpanded: boolean, prompt: IPrompt) => void;
  prompt: IPrompt;
  isExpanded: boolean;
  infoFound: boolean;
}) => {
  const { t } = useTranslation();

  const { data: verifications } = useGetHumanDataVerificationsQuery(contract);

  const isApproved = contract?.prompts?.some(
    (p) => p.id === prompt.id && p.extractionResult
  )
    ? true
    : false;

  const isAiSuggestion = aiOutputResult?.promptID === prompt.id ? true : false;

  const verificationRecord = verifications?.find((v) => v.field === prompt.id);

  const isVerified = verificationRecord?.isCorrect !== undefined;

  return (
    <Accordion
      key={`accordion-${prompt.id}`}
      onChange={(e, expanded) => findHighlight(e, expanded, prompt)}
      expanded={isExpanded}
      id={`panel-${prompt.id}-block`}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${prompt.id}`}
        id={`panel-${prompt.id}-header`}
      >
        <ListItem disablePadding disableGutters>
          <ListItemIcon>
            <StyledBadge
              badgeContent={
                isVerified && !infoFound ? (
                  <Close sx={{ maxWidth: 8, maxHeight: 16 }} />
                ) : isVerified ? (
                  <Check sx={{ maxWidth: 8, maxHeight: 16 }} />
                ) : isAiSuggestion && !isVerified ? (
                  <QuestionMark sx={{ maxWidth: 8, maxHeight: 16 }} />
                ) : (
                  <Close sx={{ maxWidth: 8, maxHeight: 16 }} />
                )
              }
              color={
                isVerified && !infoFound
                  ? "error"
                  : isVerified
                  ? "success"
                  : isAiSuggestion
                  ? "info"
                  : "error"
              }
              showZero
            >
              <SmartButton />
            </StyledBadge>
          </ListItemIcon>
          <ListItemText
            primary={
              prompt?.title
                ? t(prompt.title)
                : aiOutputResult?.promptTitle
                ? t(aiOutputResult.promptTitle)
                : t("Unknown")
            }
          />
        </ListItem>
      </AccordionSummary>
      <AccordionDetails>
        <VerifiedAISuggestion
          isVerified={
            contract.prompts?.some(
              (p) => p.id === prompt.id && p.extractionResult
            ) || verificationRecord?.isCorrect !== undefined
              ? true
              : false
          }
          content={
            contract?.prompts?.some(
              (p) => p.id === prompt.id && p.extractionResult
            ) || verificationRecord?.isCorrect !== undefined ? (
              <ContractPromptResponse
                text={
                  contract?.prompts?.find(
                    (p) => p.id === prompt.id && p.extractionResult
                  )?.text || ""
                }
              />
            ) : undefined
          }
          verificationRecord={verificationRecord}
        />
        {aiOutputResult?.extractionResult.details && (
          <AISuggestion
            contract={contract}
            isAiSuggestion={isAiSuggestion}
            isVerified={
              isVerified || verificationRecord?.isCorrect !== undefined
            }
            field="prompts"
            prompt={prompt}
            value={{
              id: prompt.id,
              text: aiOutputResult.extractionResult.details,
              extractionResult: aiOutputResult,
            }}
            content={
              <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                <Typography variant="caption">
                  {t("Relevant_paragraphs", {
                    count: aiOutputResult.relevantParagraphs.length,
                  })}
                </Typography>

                <ContractPromptResponse
                  text={aiOutputResult.extractionResult.details}
                />

                {/* <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h6">Antwoord - type 1</Typography>

                    <ContractPromptResponse
                      text={
                        aiOutputResult.rawExtractionResult
                          .extractionResultBasedOnCompleteText.details
                      }
                    />
                  </CardContent>
                </Card>

                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h6">Antwoord - type 2</Typography>
                    <ContractPromptResponse
                      text={
                        aiOutputResult.rawExtractionResult
                          .extractionResultBasedOnSuggestedParagraphs.details
                      }
                    />
                  </CardContent>
                </Card> */}
              </div>
            }
            processedAt={aiOutput?.processedAt}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default ContractPromptAccordion;
