import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { blue, grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import {
  Box,
  Chip as MuiChip,
  Icon,
  LinearProgress,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  StepButton,
  Typography,
  useMediaQuery,
  useTheme,
  Menu,
  ListItemSecondaryAction,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  Fade,
  Alert,
  AlertTitle,
  IconButton,
  Button,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  AccordionProps,
  AccordionSummaryProps,
  accordionSummaryClasses,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import { IContract } from "../../../../types/contract";

import OwlAvatarIcon from "../../../../icons/OwlAvatarIcon";
import { NumericFormat } from "react-number-format";
import { ContractExtractionResult } from "../../../../types/aiOutput";
import {
  ArrowDownward,
  ArrowForwardIosSharp,
  Check,
  HowToReg,
  Info,
  SpatialTrackingTwoTone,
  ThumbDown,
  ThumbUp,
  ThumbUpOffAlt,
  Verified,
} from "@mui/icons-material";
import { ThumbsUp } from "react-feather";
import { useUpdateContractMutation } from "../../../../redux/slices/indexApiSlice";
import { HumanDataVerification } from "../../../../types/humanDataVerification";

function VerifiedAISuggestion({
  isVerified,
  content,
  verificationRecord,
}: {
  isVerified: boolean;
  content?: React.ReactNode;
  verifiedBy?: string;
  verifiedDate?: string;
  verificationRecord?: HumanDataVerification;
}) {
  const { t } = useTranslation();

  if ((!isVerified && !verificationRecord) || !content || content === "") {
    return <span />;
  }

  return (
    <Alert
      severity={
        verificationRecord?.isCorrect === false
          ? "error"
          : isVerified
          ? "success"
          : "info"
      }
      variant="outlined"
      icon={
        verificationRecord?.isCorrect === false ? (
          <ThumbDown />
        ) : isVerified ? (
          <HowToReg />
        ) : (
          <Info />
        )
      }
    >
      {content}

      {verificationRecord?.comments && (
        <Typography>
          <i>{verificationRecord.comments}</i>
        </Typography>
      )}

      {isVerified && verificationRecord && (
        <Typography
          variant="caption"
          color="textDisabled"
          alignItems={"center"}
          display={"flex"}
          sx={{ mt: 1 }}
        >
          {/* <HowToReg fontSize="small" sx={{ mr: 1 }} /> */}

          {t("Verified by", {
            name: verificationRecord.verifiedBy,
            date: new Date(verificationRecord.verifiedDate),
          })}
        </Typography>
      )}
    </Alert>
  );
}

export default VerifiedAISuggestion;
