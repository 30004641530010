import React, {
  ReactEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { ContractExtractionResult } from "../../types/aiOutput";
import { Area } from "../../types/area";
import { rgba } from "polished";
import { useTheme as muiTheme, Typography } from "@mui/material";
import { IPrompt } from "../../types/prompt";
import { PromptTooltip } from "./ReactPDFViewer";
import { useTranslation } from "react-i18next";
import { useGetPromptsQuery } from "../../redux/slices/indexApiSlice";

export interface IImageViewerProps {
  arrayBuffer?: any;
  filename?: string;
  aiOutput?: ContractExtractionResult;
  prompt?: IPrompt;
}

function ImageViewer({ ...props }: IImageViewerProps) {
  const { aiOutput, prompt } = props;

  const { t } = useTranslation();
  const colors = muiTheme();

  const { data: prompts = [], isSuccess: promptsLoaded } = useGetPromptsQuery();

  const imageType = props?.filename?.split(".").pop();

  const imageSource = `data:image/${imageType};base64,${Buffer.from(
    props?.arrayBuffer!
  ).toString("base64")}`;

  // get the width of the image
  const image = new Image();
  image.src = imageSource;
  const originalWidth = image.width;
  const originalHeight = image.height;

  return (
    <div
      style={{ position: "relative", display: "inline-block", width: "100%" }}
    >
      <img alt={props?.filename} src={imageSource} width="100%" />

      {/* Render the highlights */}
      {aiOutput?.prompts?.map((result, index) => {
        const prompt = prompts?.find((p) => p.id === result.promptID);

        return result.relevantParagraphs.map((paragraph, index) => {
          return paragraph.boundingRegions.map((region, index) => (
            <svg
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                pointerEvents: "none", // Make sure the overlay doesn't block interactions
              }}
              viewBox={`0 0 ${originalWidth} ${originalHeight}`} // Match the original image dimensions
              preserveAspectRatio="none" // Scale SVG with the image
            >
              <PromptTooltip
                // title={prompt?.title ? t(prompt.title) : area.key}
                title={
                  <div>
                    <Typography>
                      {prompt?.title ? t(prompt.title) : result.promptTitle}
                    </Typography>

                    <p>{result.extractionResult.details}</p>

                    <p>{JSON.stringify(paragraph)}</p>
                  </div>
                }
                placement="left-start"
                arrow
                // open
                color="primary"
              >
                <polygon
                  points={region.polygon.join(",")}
                  fill={rgba(colors.palette.primary.main, 0.05)}
                  stroke={
                    props.prompt?.id == result.promptID
                      ? colors.palette.primary.main
                      : colors.palette.grey[300]
                  }
                  strokeWidth={props.prompt?.id == result.promptID ? 2 : 1}
                  style={{
                    pointerEvents: "auto", // Enable pointer events for the polygon
                    cursor: "pointer", // Optional: shows a pointer cursor
                  }}
                />
              </PromptTooltip>
            </svg>
          ));
        });
      })}
    </div>
  );
}

export default React.memo(ImageViewer);
