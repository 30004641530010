import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { blue, grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Skeleton,
  StepButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import DocumentViewer from "./DocumentViewer";
import { IDocument } from "../../types/document";
import {
  useGetDriveItemVersionsQuery,
  useLazyGetDriveItemContentAsPDFQuery,
  useLazyGetDriveItemContentQuery,
} from "../../redux/slices/graphApiSlice";
import PDFViewer from "./PDFViewer";
import { IDriveItem } from "../../types/driveItem";
import ReactPDFViewer from "./ReactPDFViewer";
import DocumentViewerSkeleton from "./DocumentViewerSkeleton";
import { addFile } from "../../redux/slices/filesSlice";
import { useDispatch } from "react-redux";
import { Area } from "../../types/area";

interface IDriveItemViewerProps {
  document: IDocument;
  onSuccess?: () => void;
  selectedArea?: Area;
  setSelectedArea: (area?: Area) => void;
}
function DriveItemViewer({ ...props }: IDriveItemViewerProps) {
  const { document, onSuccess } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDownLg = useMediaQuery(theme.breakpoints.down("lg"));
  const [documentContent, setDocumentContent] = useState<any>();
  const [selectedVersion, setSelectedVersion] = useState<any>(null);

  const [
    getDriveItemContent,
    {
      data: driveItemContent,
      isLoading: driveItemContentLoading,
      isSuccess: driveItemContentLoaded,
    },
  ] = useLazyGetDriveItemContentQuery();

  const [
    getDriveItemContentAsPDF,
    {
      data: driveItemContentAsPDF,
      isLoading: driveItemContentAsPDFLoading,
      isSuccess: driveItemContentAsPDFIsLoaded,
    },
  ] = useLazyGetDriveItemContentAsPDFQuery();

  const { data: driveItemVersions = [] } = useGetDriveItemVersionsQuery(
    {
      driveId: document?.driveId,
      driveItemId: document?.driveItemId,
    },
    {
      skip: !document.driveId || !document?.driveItemId,
    }
  );

  useEffect(() => {
    // const version = driveItemVersions.find(
    //   (v: any) => v.id === props.selectedVersionId
    // );

    const version = driveItemVersions?.length > 0 ? driveItemVersions[0] : null;
    if (version) {
      setSelectedVersion(version);
    }
  }, [driveItemVersions]);

  useEffect(() => {
    if (
      driveItemContent &&
      !driveItemContentLoading &&
      driveItemContentLoaded
    ) {
      setDocumentContent(driveItemContent);

      dispatch(
        addFile({
          id: document.id || "",
          buffer: driveItemContent,
        })
      );
    }
  }, [driveItemContent, driveItemContentLoading, driveItemContentLoaded]);

  useEffect(() => {
    if (
      driveItemContentAsPDF &&
      !driveItemContentAsPDFLoading &&
      driveItemContentAsPDFIsLoaded
    ) {
      setDocumentContent(driveItemContentAsPDF);
    }
  }, [
    driveItemContentAsPDF,
    driveItemContentAsPDFLoading,
    driveItemContentAsPDFIsLoaded,
  ]);

  useEffect(() => {
    if (document) {
      if (document?.name?.toLowerCase().endsWith(".pdf")) {
        getDriveItemContent({
          driveId: document.driveId,
          driveItemId: document.driveItemId,
        });
      } else {
        getDriveItemContentAsPDF({
          driveId: document.driveId,
          driveItemId: document.driveItemId,
        });
      }
    }
  }, [document]);

  //  <Box textAlign="center">
  //    <CircularProgress />
  //  </Box>;

  return (
    <Box sx={{ height: 900 }}>
      {driveItemContentLoading || driveItemContentAsPDFLoading ? (
        <DocumentViewerSkeleton />
      ) : (
        <div>
          {/* {document?.location === "sharepoint" && (
              <>
                {driveItemVersions.length > 0 && (
                  <>
                    {!selectedVersion?.id ? (
                      <Grid container justifyContent="center" p={2}>
                        <Grid>
                          <Alert severity="warning">
                            <AlertTitle>
                              {t("Version")}: {selectedVersion?.id}
                            </AlertTitle>
                            {t(
                              "You are viewing and older version of this document."
                            )}
                          </Alert>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container justifyContent="center" p={2}>
                        <Grid>
                          <Alert severity="info">
                            <AlertTitle>
                              {t("Version")}: {selectedVersion?.id}
                            </AlertTitle>
                            {t(
                              "You are viewing the latest version of this document."
                            )}

                            {/* <Box pt={2}>
                            {t("Wijzigingen")}:
                            <ul>
                              <li>{t("In development")}</li>
                            </ul>
                          </Box> *
                          </Alert>
                        </Grid>
                        <Grid>
                          <Autocomplete
                            options={[
                              { id: "1", name: "Version 1" },
                              { id: "2", name: "Version 2" },
                              { id: "3", name: "Version 3" },
                            ]}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Version"
                                variant="outlined"
                                sx={{ width: 160 }}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
              </>
            )} */}
          <ReactPDFViewer {...props} fileUrl={documentContent} />
        </div>
      )}
    </Box>
  );
}

export default DriveItemViewer;
