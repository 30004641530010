export const firebaseConfig = {
  apiKey: "AIzaSyCUJ11cU_Cd9ko03qjKXv5jmmJzi4KcFzY", // process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "auth.aanbestedingsjurist.ai", //process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: "aanbestedingsjurist-6986b.appspot.com", // process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: "750615517424", // process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: "1:750615517424:web:8711d9d8ed7bf18507ca35", // process.env.REACT_APP_FIREBASE_APPID,
  measurementId: "G-WDCB26PB8J", //process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const auth0Config = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const cognitoConfig = {
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
};

export const environment = {
  local: {
    REDIRECT_URL: "http://localhost:3000",
    API_URL: "http://localhost:8080",
    GRAPH_BASE_URL: "https://graph.microsoft.com",
    GRAPH_VERSION: "v1.0",
    CLIENT_ID: "0c235339-a8d6-47ba-af64-6d68069f7baf",
    PERMISSION_SCOPES: [
      "profile",
      "user.read",
      "user.readbasic.all",
      "sites.read.all",
      "mail.read", // for the Outlook integration
      "team.readbasic.all", // for the Teams integration
      "teammember.read.all", // for the Teams integration
      "group.read.all", // for the Teams integration
    ],
  },
  dev: {
    REDIRECT_URL: "https://dev.aicontractmanager.nl/auth/sign-in",
    API_URL: "https://gobeyond-be-dev.azurewebsites.net",
    GRAPH_BASE_URL: "https://graph.microsoft.com",
    GRAPH_VERSION: "v1.0",
    CLIENT_ID: "0c235339-a8d6-47ba-af64-6d68069f7baf",
    PERMISSION_SCOPES: [
      "profile",
      "user.read",
      "user.readbasic.all",
      "sites.read.all",
      "mail.read",
      "team.readbasic.all",
      "teammember.read.all",
      "group.read.all",
    ],
  },
  demo: {
    REDIRECT_URL: "https://demo.aicontractmanager.nl/auth/sign-in",
    API_URL: "https://gobeyond-be-demo.azurewebsites.net",
    GRAPH_BASE_URL: "https://graph.microsoft.com",
    GRAPH_VERSION: "v1.0",
    CLIENT_ID: "0c235339-a8d6-47ba-af64-6d68069f7baf",
    PERMISSION_SCOPES: [
      "profile",
      "user.read",
      "user.readbasic.all",
      "sites.read.all",
      "mail.read",
      "team.readbasic.all",
      "teammember.read.all",
      "group.read.all",
    ],
  },
  production: {
    REDIRECT_URL: "https://app.aicontractmanager.nl/auth/sign-in",
    API_URL: "https://gobeyond-be-prd.azurewebsites.net",
    GRAPH_BASE_URL: "https://graph.microsoft.com",
    GRAPH_VERSION: "v1.0",
    CLIENT_ID: "0c235339-a8d6-47ba-af64-6d68069f7baf",
    PERMISSION_SCOPES: [
      "profile",
      "user.read",
      "user.readbasic.all",
      "sites.read.all",
      "mail.read",
      "team.readbasic.all",
      "teammember.read.all",
      "group.read.all",
    ],
  },
};
const env = process.env.REACT_APP_ENVIRONMENT || "local";
// const config = environment[process.env.NODE_ENV] || environment.local;
const config =
  environment[env as keyof typeof environment] || environment.local;
export default config;
