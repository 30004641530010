import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { blue, grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import {
  Box,
  Chip as MuiChip,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  StepButton,
  Typography,
  useMediaQuery,
  useTheme,
  Menu,
  ListItemSecondaryAction,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  Fade,
  Alert,
  AlertTitle,
  Button,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  AutoAwesome,
  CheckCircleOutlined,
  FileDownloadOutlined,
  FileUploadOutlined,
  HowToReg,
  InsertDriveFileOutlined,
  MoreHoriz as MoreHorizIcon,
  ThumbDown,
  ThumbUp,
} from "@mui/icons-material";
import { FileUploader } from "react-drag-drop-files";
import { DialogMode } from "../../../../types/dialogmode";
import { IContract } from "../../../../types/contract";
import { IDocument } from "../../../../types/document";
import CustomNoRowsOverlay from "../../../../components/datagrids/CustomNoRowsOverlay";
import { spacing } from "@mui/system";
import FileAsListItem from "../../../../components/lists/listitems/FileAsListItem";
import {
  useAddDocumentContentMutation,
  useAddDocumentMutation,
  useAddHumanDataVerificationMutation,
  useDeleteDocumentMutation,
  useGetAttachmentsQuery,
  useGetDocumentsQuery,
  useGetHumanDataVerificationsQuery,
  useUpdateContractMutation,
} from "../../../../redux/slices/indexApiSlice";
import { useParams } from "react-router-dom";
import FilesToUploadList from "../../../../components/lists/FilesToUploadList";
import { IVendor } from "../../../../types/vendor";
import { format, set } from "date-fns";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import OwlAvatarIcon from "../../../../icons/OwlAvatarIcon";
import { enGB, nl } from "date-fns/locale";
import { ContractExtractionResult } from "../../../../types/aiOutput";
import AISuggestion from "./AISuggestion";
import VerifiedAISuggestion from "./VerifiedAISuggestion";
import useAuth from "../../../../hooks/useAuth";

const UploadButton = styled(MuiPaper)`
  height: 144px;
  border: 1px dashed ${(props) => props.theme.palette.divider};
  padding: 24px;
  cursor: pointer;
  &:hover {
    border: 1px dashed ${(props) => props.theme.palette.primary.main};
  }
`;

interface IContractDatesProps {
  contract: IContract;
  aiOutput?: ContractExtractionResult;
  hideTitle?: boolean;
}
function ContractDates({ ...props }: IContractDatesProps) {
  const { contract } = props;
  const { t, i18n } = useTranslation();

  const { data: verifications } = useGetHumanDataVerificationsQuery(contract);

  // if the contract object has a budget or explanations.financials, it is verified
  // everything under aiOutput.metadata.structuralData.financials is an AI suggestion
  const isVerified = contract?.startDate || contract?.endDate ? true : false;

  const isAiSuggestion = props.aiOutput?.metadata?.structuralData?.dates
    ? true
    : false;

  const verificationRecord = verifications?.find((v) => v.field === "endDate");
  const verifiedBy = verificationRecord?.verifiedBy;
  const verifiedDate = verificationRecord?.verifiedDate;

  return (
    <Box pb={2}>
      {!props.hideTitle && (
        <Typography variant="h6" mb={2}>
          {t("End date")}
        </Typography>
      )}

      <VerifiedAISuggestion
        isVerified={isVerified}
        content={
          <Box mb={3}>
            <Grid container gap={2}>
              <Grid size={12}>
                <Typography variant="caption">{t("Start date")}</Typography>
                {contract.startDate && (
                  <Typography variant="body1">
                    {format(new Date(contract.startDate), "PP", {
                      locale: i18n.language === "nl" ? nl : enGB,
                    })}
                  </Typography>
                )}
              </Grid>

              <Grid size={12}>
                <Typography variant="caption">{t("End date")}</Typography>
                {contract.endDate && (
                  <Typography variant="body1">
                    {format(new Date(contract.endDate), "PP", {
                      locale: i18n.language === "nl" ? nl : enGB,
                    })}
                  </Typography>
                )}
              </Grid>

              <Grid size={12}>
                <Typography variant="caption">{t("Signature date")}</Typography>
                {contract.signatureDate && (
                  <Typography variant="body1">
                    {format(new Date(contract.signatureDate), "PP", {
                      locale: i18n.language === "nl" ? nl : enGB,
                    })}
                  </Typography>
                )}
              </Grid>

              {contract?.explanations?.dates && (
                <Grid size={12}>
                  <Alert
                    sx={{ mt: 2 }}
                    severity="info"
                    variant="outlined"
                    icon={<OwlAvatarIcon />}
                  >
                    <Typography variant="body1">
                      {contract.explanations.dates}
                    </Typography>
                  </Alert>
                </Grid>
              )}
            </Grid>
          </Box>
        }
        verifiedBy={verifiedBy}
        verifiedDate={verifiedDate}
      />

      <AISuggestion
        isAiSuggestion={isAiSuggestion}
        isVerified={isVerified || verificationRecord?.isCorrect !== undefined}
        field="endDate"
        value={props.aiOutput?.metadata?.structuralData?.dates?.endDate}
        field2="startDate"
        value2={props.aiOutput?.metadata?.structuralData?.dates?.startDate}
        contract={contract}
        content={
          <Box mb={3}>
            <Grid container gap={2}>
              {props.aiOutput?.metadata?.structuralData?.dates.explanation && (
                <Grid size={12}>
                  <Typography variant="body1">
                    {
                      props.aiOutput?.metadata?.structuralData?.dates
                        .explanation
                    }
                  </Typography>
                </Grid>
              )}
              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant="caption">{t("Start date")}</Typography>
                {props.aiOutput?.metadata?.structuralData?.dates.startDate && (
                  <Typography variant="body1">
                    {format(
                      new Date(
                        props.aiOutput?.metadata?.structuralData?.dates.startDate
                      ),
                      "PP",
                      {
                        locale: i18n.language === "nl" ? nl : enGB,
                      }
                    )}
                  </Typography>
                )}
              </Grid>
              {props.aiOutput?.metadata?.structuralData?.dates.endDate && (
                <Grid size={{ xs: 12, md: 6 }}>
                  <Typography variant="caption">{t("End date")}</Typography>
                  {props.aiOutput.metadata.structuralData.dates.endDate && (
                    <Typography variant="body1">
                      {format(
                        new Date(
                          props.aiOutput.metadata.structuralData.dates.endDate
                        ),
                        "PP",
                        {
                          locale: i18n.language === "nl" ? nl : enGB,
                        }
                      )}
                    </Typography>
                  )}
                </Grid>
              )}

              {props.aiOutput?.metadata?.structuralData?.dates
                .signatureDate && (
                <Grid size={{ xs: 12, md: 6 }}>
                  <Typography variant="caption">
                    {t("Signature date")}
                  </Typography>
                  {props.aiOutput.metadata.structuralData.dates
                    .signatureDate && (
                    <Typography variant="body1">
                      {format(
                        new Date(
                          props.aiOutput.metadata.structuralData.dates.signatureDate
                        ),
                        "PP",
                        {
                          locale: i18n.language === "nl" ? nl : enGB,
                        }
                      )}
                    </Typography>
                  )}
                </Grid>
              )}
            </Grid>
          </Box>
        }
      />
    </Box>
  );
}

export default ContractDates;
