import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import React, {
  useEffect,
  useState,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  useTheme,
  Typography,
  useMediaQuery,
  Divider as MuiDivider,
  Tabs,
  Tab,
  AppBar,
  Card,
  Paper,
  Stack,
  Icon,
  Accordion,
  AccordionSummary,
  AccordionActions,
  AccordionDetails,
  ListItem,
  ListItemIcon,
  ListItemText,
  Badge,
  BadgeProps,
  AccordionProps,
  List,
  CardHeader,
  CardContent,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SidebarAnalyzeDocument from "./SidebarRiskAnalysis";
import AIContractSigneeManagement from "./AIContractSigneeManagement";
import ContractDocumentInformation from "./ContractDocumentInformation";
import { IDocument } from "../../../../types/document";
import { DialogMode } from "../../../../types/dialogmode";
import { ISignature, ISignee } from "../../../../types/signature";
import { useGetDriveItemQuery } from "../../../../redux/slices/graphApiSlice";
import {
  AIReadingStatus,
  ContractStatus,
  IContract,
} from "../../../../types/contract";

import ContractAttachments from "./ContractAttachments";
import ContractNotes from "./ContractNotes";
import useAuth from "../../../../hooks/useAuth";
import SidebarReadDocument from "./SidebarReadDocument";
import SidebarRiskAnalysis from "./SidebarRiskAnalysis";
import AskMeAnything from "../../../components/AskMeAnything";
import SidebarRoles from "./SidebarRoles";
import OwlIcon from "../../../../icons/OwlIcon";
import {
  Assistant,
  AttachFile,
  Attachment,
  AutoAwesome,
  AutoGraph,
  Cancel,
  CancelPresentation,
  Check,
  Class,
  Close,
  CollectionsBookmark,
  Euro,
  Event,
  EventBusy,
  FolderCopy,
  Folder as FolderIcon,
  Gavel,
  Group,
  Groups,
  HistoryEdu,
  Label,
  LocalOffer,
  MenuBook,
  Money,
  PanTool,
  Person,
  Public,
  QuestionMark,
  SmartButton,
  Store,
  Style,
  Subject,
  Summarize,
  TaskAlt,
  TipsAndUpdates,
  WhereToVote,
} from "@mui/icons-material";
import OwlAvatarIcon from "../../../../icons/OwlAvatarIcon";
import NoteDialog from "../../../../components/dialogs/NoteDialog";
import ContractSummary from "./ContractSummary";
import ContractParties from "./ContractParties";
import ContractContactPersons from "./ContractContactPersons";
import ContractFinancials from "./ContractFinancials";
import { DollarSign } from "react-feather";
import ContractJurisdiction from "./ContractJurisdiction";
import ContractNoticePeriod from "./ContractNoticePeriod";
import ContractDates from "./ContractDates";
import ContractCategory from "./ContractCategory";
import ContractType from "./ContractType";
import ContractSubject from "./ContractSubject";
import {
  useAddHumanDataVerificationMutation,
  useGetAIOutputQuery,
  useGetHumanDataVerificationsQuery,
  useGetPromptsQuery,
} from "../../../../redux/slices/indexApiSlice";
import ContractPromptResponse from "./ContractPromptResponse";
import { IPrompt } from "../../../../types/prompt";
import ContractFolders from "./ContractFolders";
import { use } from "i18next";
import {
  ContractExtractionResult,
  Paragraph,
  PromptExtractionResult,
} from "../../../../types/aiOutput";
import AISuggestion from "./AISuggestion";
import ContractPromptAccordion from "./ContractPromptAccordion";

const Divider = styled(MuiDivider)(spacing);

// create an enum for the tabs
enum TabIndex {
  AI_ASSISTANT,
  ATTACHMENTS,
  ROLES,
  NOTES,
  AI_READ,
  SIGNEES,
}

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-flexContainer": {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flexStart",
  },
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#635ee7",
  },
});

interface StyledTabProps {
  label: string;
  tabIndex: number;
  icon:
    | string
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined;
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  border: "1px solid gray",
  // color: "rgba(255, 255, 255, 0.7)",

  "&.Mui-selected": {
    // color: "#fff",
    backgroundColor: "background.paper",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 11,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

type AIContractDetailSidebarProps = {
  mode: DialogMode;
  signer: ISignee | undefined;
  signees: ISignee[];
  contract: IContract;
  document: IDocument;
  analysisStatus: AIReadingStatus;
  onAnalysis: () => void;
  setSignees: React.Dispatch<React.SetStateAction<Array<ISignee>>>;
  setSignatures: React.Dispatch<React.SetStateAction<Array<ISignature>>>;
  signatures: ISignature[];
  resetSignatures?: React.Dispatch<React.SetStateAction<boolean>>;
  refetchContract?: () => void;
  setSearchForText?: (text: string) => void;
  promptIdToExpand?: string;
  handlePromptClick: (prompt?: IPrompt) => void;
};

const AIContractDetailSidebar: React.FC<AIContractDetailSidebarProps> = ({
  mode,
  signer,
  signees,
  contract,
  document,
  analysisStatus,
  onAnalysis,
  setSignees,
  setSignatures,
  signatures,
  resetSignatures,
  refetchContract,
  setSearchForText,
  handlePromptClick,
  promptIdToExpand,
}) => {
  const { t } = useTranslation();
  const { id, status } = useParams();
  const theme = useTheme();

  const [tab, setTab] = useState(TabIndex.AI_ASSISTANT);
  const [badges, setBadges] = useState<{ [key: string]: number }>({});
  const [aiOutput, setAiOutput] = useState<ContractExtractionResult>();

  const [addVerification] = useAddHumanDataVerificationMutation();

  const { data: prompts = [], isSuccess: promptsLoaded } = useGetPromptsQuery();
  const { data: aiOutputs = [] } = useGetAIOutputQuery(document, {
    skip: !document,
  });

  const isDownLg = useMediaQuery(theme.breakpoints.down("lg"));
  const [expanded, setExpanded] = React.useState<string>();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const setBadge = (tabIndex: string, num: number) => {
    setBadges((prev) => ({ ...prev, [tabIndex]: num }));
  };

  useEffect(() => {
    if (aiOutputs.length > 0) {
      // select the latest AI output
      setAiOutput(aiOutputs[0]);
    } else {
      setAiOutput(undefined);
    }
  }, [aiOutputs]);

  const findHighlight = (e: any, isExpanded: boolean, prompt: IPrompt) => {
    setExpanded(isExpanded ? prompt.id : undefined);

    if (isExpanded) {
      handlePromptClick(prompt);
    } else {
      handlePromptClick(undefined);
    }
  };

  useEffect(() => {
    if (promptIdToExpand) {
      // findHighlight(null, true, promptFind);
      setExpanded(promptIdToExpand);
    }
  }, [promptIdToExpand]);

  const promptWasFound = (p: IPrompt) => {
    if (!aiOutput) return false;

    return aiOutput.prompts?.some(
      (r) => r.promptID === p.id && r.extractionResult?.infoFound
    );
  };

  const getBadgeContent = (isVerified: boolean, hasAiSuggestion: boolean) => {
    return isVerified ? (
      <Check sx={{ maxWidth: 8, maxHeight: 16 }} />
    ) : hasAiSuggestion ? (
      <QuestionMark sx={{ maxWidth: 8, maxHeight: 16 }} />
    ) : (
      <Close sx={{ maxWidth: 8, maxHeight: 16 }} />
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        mb: 6,
        py: 3,
        px: 3,
        backgroundColor: "background.default",
        height: "100%",
        minHeight: 500,
        // overflow: "auto",
      }}
    >
      <Stack display="flex" flexDirection="column" gap={3} height="100%">
        <Box ml={-3}>
          <AskMeAnything
            showLabel
            placeholder={"Ask a question about this contract"}
          />
        </Box>

        <Stack overflow="auto" gap={3}>
          <Stack>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {t("Contract management")}
            </Typography>

            <Accordion disabled>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-airead"
                id="panel-airead-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <AutoAwesome />
                  </ListItemIcon>
                  <ListItemText primary={t("AI_Analysis")} />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <SidebarRiskAnalysis
                  contract={contract}
                  document={document}
                  isDownLg={isDownLg}
                  analyzing={analysisStatus === AIReadingStatus.ANALYZING}
                  onAnalysis={onAnalysis}
                  isAnalyzed={!!contract?.analyzeStatus}
                  hideTitle
                />
              </AccordionDetails>
              {/* <AccordionActions>
              <Button>Cancel</Button>
              <Button>Agree</Button>
            </AccordionActions> */}
            </Accordion>

            <Accordion disabled>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-attachments"
                id="panel-attachments-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <StyledBadge
                      badgeContent={badges.attachments}
                      color="info"
                      showZero
                    >
                      <AttachFile />
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText primary={t("Attachments")} />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractAttachments
                  mode={DialogMode.Edit}
                  contract={contract}
                  hideTitle
                  setBadgeCount={(num: number) => setBadge("attachments", num)}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion disabled>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-signees"
                id="panel-signees-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <HistoryEdu />
                  </ListItemIcon>
                  <ListItemText primary={t("Signees")} />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <AIContractSigneeManagement
                  mode={
                    status === ContractStatus.SIGNED ||
                    status === ContractStatus.ACTIVE ||
                    status === ContractStatus.ARCHIVED
                      ? DialogMode.ViewOnly
                      : DialogMode.Edit
                  }
                  signer={signer}
                  readOnly={false} // {mode === DialogMode.ViewOnly}
                  contract={contract}
                  signable={false} // signees?.find((s) => s.email === user?.email) !== undefined
                  signees={signees}
                  setSignees={setSignees}
                  setSignatures={setSignatures}
                  signatures={signatures}
                  resetSignatures={resetSignatures}
                  document={document}
                  hideTitle
                />
              </AccordionDetails>
              {/* <AccordionActions>
              <Button>Cancel</Button>
              <Button>Agree</Button>
            </AccordionActions> */}
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-roles"
                id="panel-roles-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <Groups />
                  </ListItemIcon>
                  <ListItemText primary={t("Roles")} />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <SidebarRoles
                  contract={contract}
                  refetch={refetchContract}
                  hideTitle
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-tasks"
                id="panel-tasks-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <StyledBadge
                      badgeContent={badges.tasks}
                      color="info"
                      showZero
                    >
                      <TaskAlt />
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText primary={t("Notes")} />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractNotes
                  contract={contract}
                  hideTitle
                  setBadgeCount={(num: number) => setBadge("tasks", num)}
                />
              </AccordionDetails>
              {/* <AccordionActions>
              <Button>Cancel</Button>
              <Button>Agree</Button>
            </AccordionActions> */}
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-folders"
                id="panel-folders-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <FolderCopy />
                  </ListItemIcon>
                  <ListItemText primary={t("Folders")} />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractFolders contract={contract} hideTitle />
              </AccordionDetails>
            </Accordion>
          </Stack>

          <Stack>
            {/* {contract?.extraContext && (
            <Box>
              {contract.extraContext.map((context) => (
                <Box py={3}>
                  <Typography>{context.property}</Typography>
                  <Box>{context.details}</Box>
                  <Box>{context.locationInText}</Box>
                </Box>
              ))}
            </Box>
          )} */}

            <Typography variant="h6" sx={{ mb: 2 }}>
              {t("Basic details")}
            </Typography>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-category"
                id="panel-category-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <StyledBadge
                      badgeContent={getBadgeContent(
                        !!contract.category,
                        !!aiOutput?.metadata?.structuralData?.procurement
                          ?.category
                      )}
                      color={
                        contract?.category
                          ? "success"
                          : aiOutput?.metadata?.structuralData?.procurement
                              ?.category
                          ? "info"
                          : "error"
                      }
                      showZero
                    >
                      <Style />
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText primary={t("Category")} />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractCategory
                  contract={contract}
                  aiOutput={aiOutput}
                  hideTitle
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-contactpersons"
                id="panel-contactpersons-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <StyledBadge
                      badgeContent={getBadgeContent(
                        contract.buyer?.contactPersons?.length > 0 ||
                          contract.seller?.contactPersons?.length > 0,
                        aiOutput?.metadata?.structuralData?.buyer
                          ?.contactPersons?.length > 0 ||
                          aiOutput?.metadata?.structuralData?.seller
                            ?.contactPersons?.length > 0
                      )}
                      color={
                        contract.buyer?.contactPersons?.length > 0 ||
                        contract.seller?.contactPersons?.length > 0
                          ? "success"
                          : aiOutput?.metadata?.structuralData?.buyer
                              ?.contactPersons?.length > 0 ||
                            aiOutput?.metadata?.structuralData?.seller
                              ?.contactPersons?.length > 0
                          ? "info"
                          : "error"
                      }
                      showZero
                    >
                      <Person />
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText primary={t("Contact persons")} />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractContactPersons
                  contract={aiOutput?.metadata?.structuralData}
                  aiOutput={aiOutput}
                  hideTitle
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-enddate"
                id="panel-enddate-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <StyledBadge
                      badgeContent={getBadgeContent(
                        !!contract.endDate,
                        !!aiOutput?.metadata?.structuralData?.dates?.endDate
                      )}
                      color={
                        contract.endDate
                          ? "success"
                          : aiOutput?.metadata?.structuralData?.dates?.endDate
                          ? "info"
                          : "error"
                      }
                      showZero
                    >
                      <Event />
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText primary={t("End date")} />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractDates
                  contract={contract}
                  aiOutput={aiOutput}
                  hideTitle
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-financials"
                id="panel-financials-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <StyledBadge
                      badgeContent={getBadgeContent(
                        !!contract.budget?.amount,
                        !!aiOutput?.metadata?.structuralData?.financials
                          ?.totalValue
                      )}
                      color={
                        contract?.budget?.amount
                          ? "success"
                          : aiOutput?.metadata?.structuralData?.financials
                              ?.totalValue
                          ? "info"
                          : "error"
                      }
                      showZero
                    >
                      {aiOutput?.metadata?.structuralData?.budget?.currency ===
                      "EUR" ? (
                        <Euro />
                      ) : aiOutput?.metadata?.structuralData?.budget
                          ?.currency === "USD" ? (
                        <DollarSign />
                      ) : (
                        <Money />
                      )}
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText primary={t("Financials")} />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractFinancials
                  contract={contract}
                  aiOutput={aiOutput}
                  hideTitle
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-jurisdiction"
                id="panel-jurisdiction-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <StyledBadge
                      badgeContent={getBadgeContent(
                        !!contract.countryOfJurisdiction,
                        !!aiOutput?.metadata?.structuralData
                          ?.countryOfJurisdiction
                      )}
                      color={
                        contract.countryOfJurisdiction
                          ? "success"
                          : aiOutput?.metadata?.structuralData
                              ?.countryOfJurisdiction
                          ? "info"
                          : "error"
                      }
                      showZero
                    >
                      <Public />
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText primary={t("Country of jurisdiction")} />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractJurisdiction
                  contract={aiOutput?.metadata?.structuralData}
                  aiOutput={aiOutput}
                  hideTitle
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-subject"
                id="panel-subject-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <StyledBadge
                      badgeContent={getBadgeContent(
                        !!contract.subject,
                        !!aiOutput?.metadata?.structuralData?.subject
                      )}
                      color={
                        contract?.subject
                          ? "success"
                          : aiOutput?.metadata?.structuralData?.subject
                          ? "info"
                          : "error"
                      }
                      showZero
                    >
                      <Subject />
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText primary={t("Subject")} />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractSubject
                  contract={contract}
                  aiOutput={aiOutput}
                  hideTitle
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-noticeperiod"
                id="panel-noticeperiod-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <StyledBadge
                      badgeContent={getBadgeContent(
                        contract.noticePeriod?.unit &&
                          contract.noticePeriod?.value
                          ? true
                          : false,
                        aiOutput?.metadata?.structuralData?.dates?.noticePeriod
                          ?.unit ||
                          aiOutput?.metadata?.structuralData?.dates
                            ?.noticePeriod?.value
                          ? true
                          : false
                      )}
                      color={
                        contract.noticePeriod?.unit &&
                        contract.noticePeriod?.value
                          ? "success"
                          : aiOutput?.metadata?.structuralData?.dates
                              ?.noticePeriod?.unit ||
                            aiOutput?.metadata?.structuralData?.dates
                              ?.noticePeriod?.value
                          ? "info"
                          : "error"
                      }
                      showZero
                    >
                      <PanTool />
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText primary={t("Notice period")} />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractNoticePeriod
                  contract={contract}
                  aiOutput={aiOutput}
                  hideTitle
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-parties"
                id="panel-parties-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <StyledBadge
                      badgeContent={getBadgeContent(
                        contract?.buyer ||
                          contract?.seller ||
                          contract?.vendorName ||
                          contract?.vendorId
                          ? true
                          : false,
                        aiOutput?.metadata?.structuralData?.vendorId ||
                          aiOutput?.metadata?.structuralData?.vendorName
                          ? true
                          : false
                      )}
                      color={
                        contract?.buyer ||
                        contract?.seller ||
                        contract?.vendorName ||
                        contract?.vendorId
                          ? "success"
                          : aiOutput?.metadata?.structuralData?.vendorId ||
                            aiOutput?.metadata?.structuralData?.vendorName
                          ? "info"
                          : "error"
                      }
                      showZero
                    >
                      <Store />
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText primary={t("Parties")} />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractParties
                  contract={contract}
                  aiOutput={aiOutput}
                  hideTitle
                  setBadgeCount={(num: number) => setBadge("parties", num)}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-summary"
                id="panel-summary-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <StyledBadge
                      badgeContent={getBadgeContent(
                        !!contract.summary,
                        !!aiOutput?.metadata?.structuralData?.summary
                      )}
                      color={
                        contract?.summary
                          ? "success"
                          : aiOutput?.metadata?.structuralData?.summary
                          ? "info"
                          : "error"
                      }
                      showZero
                    >
                      <MenuBook />
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText primary={t("Summary")} />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractSummary
                  contract={contract}
                  aiOutput={aiOutput}
                  hideTitle
                  setBadgeCount={(num: number) => setBadge("summary", num)}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-type"
                id="panel-type-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <StyledBadge
                      badgeContent={getBadgeContent(
                        !!contract.type,
                        !!aiOutput?.metadata?.structuralData?.type
                      )}
                      color={
                        contract?.type
                          ? "success"
                          : aiOutput?.metadata?.structuralData?.type
                          ? "info"
                          : "error"
                      }
                      showZero
                    >
                      <Class />
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText primary={t("Type")} />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractType
                  contract={contract}
                  aiOutput={aiOutput}
                  hideTitle
                />
              </AccordionDetails>
            </Accordion>
          </Stack>

          <Stack>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {t("Fields found")}
            </Typography>

            {/* Only include prompts that are in the extraContext */}
            {prompts
              ?.filter((p) => p.enabled && !p.hidden && promptWasFound(p))
              .sort((a, b) => {
                // sort on the translated title
                const aCategory = a.title ? t(a.title)?.toLowerCase() : "";
                const bCategory = b.title ? t(b.title)?.toLowerCase() : "";

                if (aCategory < bCategory) {
                  return -1;
                }
                if (aCategory > bCategory) {
                  return 1;
                }
                return 0;
              })
              .map((prompt) => {
                // look up the prompt in the AI output
                const aiOutputResult = aiOutput?.prompts?.find(
                  (r) => r.promptID === prompt.id
                );

                return (
                  <ContractPromptAccordion
                    key={`contractPromptAccordion-${prompt.id}`}
                    prompt={prompt}
                    contract={contract}
                    aiOutput={aiOutput}
                    aiOutputResult={aiOutputResult}
                    findHighlight={findHighlight}
                    isExpanded={expanded === prompt.id}
                    infoFound={true}
                  />
                );
              })}
          </Stack>

          <Stack>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {t("Fields not found")}
            </Typography>

            <List disablePadding>
              {prompts
                ?.filter((p) => p.enabled && !p.hidden && !promptWasFound(p))
                .sort((a, b) => {
                  // sort on the translated category
                  const aCategory = a.title ? t(a.title)?.toLowerCase() : "";
                  const bCategory = b.title ? t(b.title)?.toLowerCase() : "";

                  if (aCategory < bCategory) {
                    return -1;
                  }
                  if (aCategory > bCategory) {
                    return 1;
                  }
                  return 0;
                })
                .map((prompt) => {
                  // look up the prompt in the AI output
                  const aiOutputResult = aiOutput?.prompts?.find(
                    (r) => r.promptID === prompt.id
                  );

                  return (
                    <ContractPromptAccordion
                      key={`contractPromptAccordion-${prompt.id}`}
                      prompt={prompt}
                      contract={contract}
                      aiOutputResult={aiOutputResult}
                      findHighlight={findHighlight}
                      isExpanded={expanded === prompt.id}
                      infoFound={false}
                    />
                  );
                })}
              {/*  .map((prompt) =>
                   prompt?.title ? t(prompt.title)?.trim() : t("Unknown")
                 )
                 .join(", ")} */}
            </List>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default AIContractDetailSidebar;
