import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Alert as MuiAlert,
  AvatarGroup,
  Box,
  Button as MuiButton,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Typography,
  Tooltip,
  Avatar,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { spacing } from "@mui/system";
import { useUpdateContractMutation } from "../../redux/slices/indexApiSlice";
import {
  AutoFixHigh,
  Delete,
  Description,
  LegendToggle,
  Visibility,
  Edit as EditIcon,
  Cancel,
  PersonAdd,
} from "@mui/icons-material";

import { IContract } from "../../types/contract";
import { DialogMode } from "../../types/dialogmode";
import DataGridUsers from "../datagrids/DataGridUsers";
import { set } from "date-fns";
import { useTranslation } from "react-i18next";

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);

type IContractRoleDialogProps = {
  mode: DialogMode;
  iconOnly?: boolean;
  inlineConfirmation?: boolean;
  contract: IContract;
  refetch?: () => void;
  showMenuIcon?: boolean;
  field: "adminIds" | "managerIds" | "ownerIds";
};

function ContractRoleDialog({ ...props }: IContractRoleDialogProps) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [selectedUserIds, setSelectedUserIds] = useState(
    props.contract?.[props.field]
  );

  const [
    updateContract,
    { data: updatedContract, isSuccess: isUpdated, reset: resetUpdate },
  ] = useUpdateContractMutation();

  useEffect(() => {
    if (props.field) {
      setSelectedUserIds(props.contract[props.field]);
    }
  }, []);

  useEffect(() => {
    if (props.field) {
      setSelectedUserIds(props.contract[props.field]);
    }
  }, [props.contract[props.field]]);

  useEffect(() => {
    if (isUpdated) {
      if (props.refetch) {
        props.refetch();
      }

      resetUpdate();

      setOpen(false);
    }
  }, [isUpdated]);

  const resetAndClose = () => {
    // set back to original
    setSelectedUserIds(props.contract[props.field]);

    setOpen(false);
  };

  const handleSaveClick = async () => {
    if (!props.field) {
      return;
    }

    console.log(`save ${props.field}`, selectedUserIds);

    const updatedContract: IContract = {
      ...props.contract,
      [props.field]: selectedUserIds, // only 1 owner is supported at the moment
    };

    await updateContract(updatedContract);
  };

  const addLabel =
    props.field === "adminIds"
      ? t("Assign administrator")
      : props.field === "managerIds"
      ? t("Assign manager")
      : props.field === "ownerIds"
      ? t("Assign owner")
      : props.field === "readerIds"
      ? t("Assign reader")
      : "";

  const editLabel =
    props.field === "adminIds"
      ? t("Edit administrator")
      : props.field === "managerIds"
      ? t("Edit manager")
      : props.field === "ownerIds"
      ? t("Edit owner")
      : props.field === "readerIds"
      ? t("Edit reader")
      : "";

  return (
    <>
      {props.mode === DialogMode.Edit && (
        <Box sx={{ visibility: props.showMenuIcon ? "visible" : "hidden" }}>
          {props.iconOnly ? (
            <Tooltip title={editLabel} enterDelay={500}>
              <IconButton
                color="primary"
                size="medium"
                onClick={() => setOpen(true)}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          ) : (
            <Button
              startIcon={<EditIcon />}
              variant="text"
              onClick={() => setOpen(true)}
            >
              {editLabel}
            </Button>
          )}
        </Box>
      )}

      {props.mode === DialogMode.Add && (
        <Grid
          container
          direction="row"
          alignItems="center"
          gap={2}
          flexWrap="nowrap"
          sx={{ visibility: props.showMenuIcon ? "visible" : "hidden" }}
        >
          <Grid display="flex">
            {props.iconOnly ? (
              <Tooltip title={addLabel} enterDelay={500}>
                <IconButton
                  color="primary"
                  onClick={() => setOpen(true)}
                  sx={{ m: -2 }}
                >
                  <Avatar sx={{ width: 32, height: 32 }}>
                    <PersonAdd />
                  </Avatar>
                </IconButton>
              </Tooltip>
            ) : (
              <Button
                startIcon={<PersonAdd />}
                variant="text"
                onClick={() => setOpen(true)}
              >
                {addLabel}
              </Button>
            )}
          </Grid>
        </Grid>
      )}

      <Dialog
        open={open}
        maxWidth={"md"}
        fullWidth
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{editLabel}</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>

          <DataGridUsers
            selected={selectedUserIds}
            onSelect={setSelectedUserIds}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => resetAndClose()} color="primary">
            {t("Cancel")}
          </Button>
          <Button
            variant="contained"
            onClick={() => handleSaveClick()}
            color="primary"
          >
            {t("Save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ContractRoleDialog;
