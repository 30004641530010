import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { IConnectedDrive } from "../../types/connecteddrive";
import {
  useGetDriveItemsQuery,
  useGetDriveQuery,
  useGetSiteQuery,
  useLazyGetDriveItemChildrenByPathQuery,
  useLazyGetDriveItemChildrenQuery,
  useLazyGetDriveItemsQuery,
} from "../../redux/slices/graphApiSlice";
import {
  Alert,
  AlertTitle,
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import SharePointIcon from "../../icons/SharePointIcon";
import {
  OpenInNew,
  Sync,
  MoreVert as MoreVertIcon,
  DoDisturbOn,
  DoDisturb,
  FolderRounded,
} from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import ConnectedSharePointDriveDialog from "../dialogs/ConnectedSharePointDriveDialog";
import { DialogMode } from "../../types/dialogmode";
import { IDriveItem } from "../../types/driveItem";
import {
  useGetContractsQuery,
  useSyncConnectedDriveMutation,
} from "../../redux/slices/indexApiSlice";
import { useTranslation } from "react-i18next";
import SharePointFilesDialog from "../dialogs/SharePointFilesDialog";
import StyledMenu from "../../theme/StyledMenu";
import useContract from "../../hooks/useContract";
import { AnalyzeStatus } from "../../../../server/types/contract";
import { AIReadingStatus, ContractStatus } from "../../types/contract";
import { set } from "date-fns";
import { skipToken } from "@reduxjs/toolkit/dist/query";

type ConnectedDriveCardProps = {
  connectedDrive: IConnectedDrive;
};

const ConnectedDriveCard = ({ ...props }: ConnectedDriveCardProps) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { requestDocumentAnalysis } = useContract();

  const { data: site } = useGetSiteQuery(props.connectedDrive?.siteId);
  const { data: contracts } = useGetContractsQuery(ContractStatus.ALL);
  const [driveItems, setDriveItems] = useState<IDriveItem[]>([]);

  const {
    data: drive,
    isLoading,
    isSuccess,
    isError,
    error: driveError,
  } = useGetDriveQuery(props.connectedDrive?.driveId);

  const { data: driveItemChildren } = useGetDriveItemsQuery(
    drive?.id ?? skipToken
  );

  const [getDriveItemChildrenByPath] = useLazyGetDriveItemChildrenByPathQuery();

  const [
    sync,
    { data: syncedDocuments, isLoading: syncing, isSuccess: synced },
  ] = useSyncConnectedDriveMutation();

  useEffect(() => {
    if (driveItemChildren) {
      setDriveItems(driveItemChildren.filter((d) => d.file));

      const rootFolders = driveItemChildren.filter((d) => d.folder);

      getAllDriveItems(rootFolders);
    }
  }, [driveItemChildren]);

  const getAllDriveItems = async (foldersToCheck: IDriveItem[]) => {
    foldersToCheck?.forEach((folder) => {
      if (folder?.name) {
        getDriveItemChildrenByPath({
          driveId: folder.parentReference.driveId,
          path: `${folder.parentReference.path}/${folder.name}`,
        })
          .unwrap()
          .then((children) => {
            const folders = children.filter((item) => item.folder);
            const files = children.filter((item) => item.file);

            setDriveItems((prev) => [...prev, ...files]);

            if (folders.length > 0) {
              getAllDriveItems(folders);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const startSync = async () => {
    // send the drive items to the backend so they can be synced
    sync({
      driveItems: driveItems,
      connectedDrive: drive,
    });
  };

  useEffect(() => {
    if (synced) {
      // request an analysis for every document that was added
      console.log(syncedDocuments);

      syncedDocuments?.forEach((document) => {
        // only request analysis for documents that are not already being analyzed
        const contract = contracts?.find((c) => c.id === document.contractId);

        if (!contract?.analyzeStatus) {
          requestDocumentAnalysis(document);
        }
      });
    }
  }, [synced, syncing]);

  return (
    <Card
      key={drive?.id}
      // onClick={() => handleDriveClick(drive)}
      sx={{ flexGrow: 1, width: "100%" }}
    >
      {isError && (
        <>
          <CardHeader
            // avatar={<SharePointIcon />}
            avatar={<DoDisturb />}
            title={
              (driveError as any)?.data &&
              (driveError as any)?.data?.error?.message
            }
          />
        </>
      )}

      {isSuccess && (
        <>
          <CardHeader
            // avatar={<SharePointIcon />}
            avatar={
              site && (
                <Avatar
                  variant="square"
                  src={`${site?.webUrl}/_api/siteiconmanager/getsitelogo?type='1'`}
                  imgProps={{
                    loading: "lazy",
                    style: { objectFit: "contain" },
                  }}
                />
              )
            }
            action={
              drive?.webUrl && (
                <IconButton
                  aria-label="open in new window"
                  href={drive.webUrl}
                  target="_blank"
                  sx={{ marginLeft: 6 }}
                >
                  <OpenInNew />
                </IconButton>
              )
            }
            title={drive?.name}
            subheader={site?.displayName}
          />

          <CardContent>
            {driveItems && isSuccess && (
              <SharePointFilesDialog driveItems={driveItems} />
            )}
            {synced && (
              <Alert severity="success">
                {t("Successfully synchronized", {
                  count: syncedDocuments?.length,
                })}
              </Alert>
            )}
          </CardContent>
          <CardActions disableSpacing sx={{ justifyContent: "space-between" }}>
            <LoadingButton
              loading={isLoading || syncing}
              onClick={() => startSync()}
              variant="contained"
              startIcon={<Sync />}
            >
              {t("Sync now")}
            </LoadingButton>

            <IconButton
              aria-label="more"
              id={`drive-menubutton-${drive?.id}`}
              aria-controls={open ? `drive-menu-${drive?.id}` : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              // sx={{ visibility: props.showMenuIcon ? "visible" : "hidden" }}
            >
              <MoreVertIcon />
            </IconButton>
            <StyledMenu
              id={`drive-menu-${drive?.id}`}
              MenuListProps={{
                "aria-labelledby": `drive-menubutton-${drive?.id}`,
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {/* <Divider sx={{ my: 0.5 }} /> */}

              <ConnectedSharePointDriveDialog
                mode={DialogMode.Delete}
                refetch={() => {}}
                connectedDrive={props.connectedDrive}
                asMenuItem
                // iconOnly
              />
            </StyledMenu>
          </CardActions>
        </>
      )}
    </Card>
  );
};

export default ConnectedDriveCard;
