// ** React Imports
import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf";

// https://www.npmjs.com/package/react-pdf
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

// ** MUI Imports
import {
  AppBar,
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  InputBase,
  LinearProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Pagination as MuiPagination,
  Paper,
  Stack,
  Toolbar,
  styled,
  useMediaQuery,
  useTheme,
  useScrollTrigger,
  Skeleton,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { alpha, spacing } from "@mui/system";

import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";
import { saveAs } from "file-saver";
import { useTranslation } from "react-i18next";

import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import FluentUIFileTypeIcon from "../../icons/FluentUIFileTypeIcon";
import {
  addBase64File,
  addFile,
  fileBase64Exists,
  fileExists,
  selectBase64File,
  selectFile,
} from "../../redux/slices/filesSlice";
import {
  useGetAIOutputQuery,
  useLazyGetDocumentContentQuery,
} from "../../redux/slices/indexApiSlice";
import { RootState } from "../../redux/store";
import { IDocument } from "../../types/document";
import { IDriveItem } from "../../types/driveItem";
import { IOutlookMessageAttachment } from "../../types/outlookattachment";
import { ILocationInText, IPrompt } from "../../types/prompt";
import { ISignature, ISignee } from "../../types/signature";
import DriveItemViewer from "./DriveItemViewer";
import OutlookAttachmentViewer from "./OutlookAttachmentViewer";
import ImageViewer from "./ImageViewer";
import ReactPDFViewer from "./ReactPDFViewer";
import DocumentViewerSkeleton from "./DocumentViewerSkeleton";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { ContractExtractionResult, Paragraph } from "../../types/aiOutput";
import { Area } from "../../types/area";

const Pagination = styled(MuiPagination)(spacing);

function WordViewer({ ...props }) {
  const googleDocsViewerUrl = `https://docs.google.com/gview?url=${encodeURIComponent(
    props.fileUrl
  )}&embedded=true`;

  return (
    <div>
      <iframe
        className="word-document"
        title="Word Document Preview"
        width="100%"
        height="600"
        frameBorder="0"
        src={googleDocsViewerUrl}
      ></iframe>
    </div>
  );
}

const MemoizedDocument = React.memo(Document);
const MemoizedPage = React.memo(Page);

const elevation2 =
  "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12) !important";
const elevation3 =
  "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12) !important";
const elevation4 =
  "rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px !important";

const StyledGridContainer = styled(Grid)`
  // background-color: ${(props) => (props.theme as any).sidebar.background};
`;

type MenuItemOption = {
  key: string;
  onClick?: () => void;
  icon: JSX.Element;
  label: string;
};

type IMenuComponentProps = {
  label: string;
  options: MenuItemOption[];
};

const MenuComponent = (props: IMenuComponentProps) => {
  const { label, options } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOnClick = (e: any, option: MenuItemOption) => {
    e.stopPropagation();

    if (option.onClick) {
      option.onClick();
    }

    handleCloseMenu();
  };

  return (
    <div>
      <Button
        onClick={handleOpenMenu}
        endIcon={<KeyboardArrowDownIcon />}
        variant="text"
        sx={{
          ":hover": {
            background: "transparent",
          },
        }}
      >
        {label}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {/* {options.map((option: JSX.Element, index: number) => {
          return option;
        })} */}
        {options.map((option) => {
          return (
            <MenuItem
              key={option.key}
              onClick={(e: any) => {
                handleOnClick(e, option);
              }}
              disableRipple
            >
              <ListItemIcon>{option.icon}</ListItemIcon>
              <ListItemText>{option.label}</ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

function ElevationScroll(props: any) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export interface DocumentViewerProps {
  document?: IDocument;
  driveItem?: IDriveItem;
  outlookAttachment?: IOutlookMessageAttachment;
  file?: File;
  fileUrl?: string;
  // arrayBuffer?: ArrayBuffer;
  height?: string;
  deleteDocument?: () => void;
  setAIResponse?: (aiResponse: any) => void;
  setIsProcessing?: (isProcessing: boolean) => void;
  hideFileMenu?: boolean;
  selectedVersionId?: string;
  hasMoreVersions?: boolean;
  startAI?: boolean;
  viewMode?: "withMenu" | "pageOnly";
  signable?: boolean;
  signer?: ISignee;
  signatures?: ISignature[];
  onSaveSignature?: () => any;
  setSignatures?: React.Dispatch<React.SetStateAction<ISignature[]>>;
  setSignatureSizePercentValue?: React.Dispatch<
    React.SetStateAction<{ width: number; height: number }>
  >;
  toggleSignaturePad?: boolean;
  onSuccess?: () => void;
  paging?: boolean;
  disableAppBar?: boolean;
  disableScroller?: boolean;
  searchForText?: string;
  prompt?: IPrompt;
  locationInText?: ILocationInText[];
  expandPromptInSidebar?: (promptId: string) => void;
}

// Use the DocumentViewer to view files that are stored in Azure Blob Storage
// Digital Signatures are supported
function DocumentViewer({ ...props }: DocumentViewerProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  const {
    driveItem,
    outlookAttachment,
    file,
    fileUrl,
    height,
    onSuccess,
    expandPromptInSidebar,
  } = props;

  const [ready, setReady] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [searchResultIndex, setSearchResultIndex] = useState(0);
  const [searchResults, setSearchResults] = useState<number>(0);
  const [aiOutput, setAIOutput] = useState<ContractExtractionResult>();
  const [selectedParagraph, setSelectedParagraph] = useState<Paragraph>();
  const [selectedArea, setSelectedArea] = useState<Area>();

  const [
    getDocumentContent,
    { isLoading, isFetching, status, currentData, data },
  ] = useLazyGetDocumentContentQuery();

  const { data: aiOutputs = [] } = useGetAIOutputQuery(
    props.document ?? skipToken
  );

  // check if the file is already in the store
  const fileContentExists = useSelector<RootState, boolean | undefined>(
    (state) =>
      props.document ? fileExists(props.document.id || "")(state) : undefined
  );

  const fileBuffer = useSelector<RootState, ArrayBuffer | undefined>((state) =>
    props.document ? selectFile(props.document.id || "")(state) : undefined
  );

  // const fileContentExists = useSelector<RootState, boolean | undefined>(
  //   (state) =>
  //     props.document
  //       ? fileBase64Exists(props.document.id || "")(state)
  //       : undefined
  // );

  // const fileBuffer = useSelector<RootState, string | undefined>((state) =>
  //   props.document
  //     ? selectBase64File(props.document.id || "")(state)
  //     : undefined
  // );

  useEffect(() => {
    if (window.location.hash) {
      // on first load, select the first area that has the paragraph id from the URL
      const parts = window.location.hash.split("#");
      const paragraphId = parts[1];

      if (!props.prompt?.id) {
        // no prompt is selected, therefore select the first prompt where the paragraph is found
        const firstExtractionResult = aiOutput?.prompts.find((p) =>
          p.relevantParagraphs?.find((p) => p.id === paragraphId)
        );

        if (firstExtractionResult) {
          // handleParagraphClick(paragraphId, firstExtractionResult.promptID);
          setSelectedArea({
            paragraphID: paragraphId,
            promptID: firstExtractionResult.promptID,
            infoFound: true,
            pageIndex: 0,
            top: 0,
            left: 0,
            height: 0,
            width: 0,
          });
        }
      }
    }
  }, []);

  // if there is a #leveringsvoorwaarden in the URL, set the tab to 1
  useEffect(() => {
    if (window.location.hash) {
      const parts = window.location.hash.split("#");
      const paragraphId = parts[1];

      if (!props.prompt?.id) {
        // no prompt is selected, therefore select the first prompt where the paragraph is found
        // const firstExtractionResult = aiOutput?.prompts.find((p) =>
        //   p.relevantParagraphs?.find((p) => p.id === paragraphId)
        // );
        // if (firstExtractionResult) {
        //   // handleParagraphClick(paragraphId, firstExtractionResult.promptID);
        //   setSelectedArea({
        //     paragraphID: paragraphId,
        //     promptID: firstExtractionResult.promptID,
        //     infoFound: true,
        //     pageIndex: 0,
        //     top: 0,
        //     left: 0,
        //     height: 0,
        //     width: 0,
        //   });
        // }
      } else {
        // handleParagraphClick(paragraphId, props.prompt.id);

        setSelectedArea({
          paragraphID: paragraphId,
          promptID: props.prompt.id,
          infoFound: true,
          pageIndex: 0,
          top: 0,
          left: 0,
          height: 0,
          width: 0,
        });
      }
    }
  }, [window.location.hash]);

  useEffect(() => {
    if (!props.prompt && selectedArea) {
      setSelectedArea(undefined);
    }
  }, [props.prompt]);

  useEffect(() => {
    if (selectedParagraph && window.location.hash !== selectedParagraph.id) {
      // update the URL
      window.location.hash = selectedParagraph.id;
    }
  }, [selectedParagraph]);

  useEffect(() => {
    if (selectedArea) {
      // a new area was selected, expand the prompt in the sidebar
      if (expandPromptInSidebar) {
        handleParagraphClick(selectedArea.paragraphID, selectedArea.promptID);
      }

      // scroll to the selected paragraph
      handleParagraphClick(selectedArea.paragraphID, selectedArea.promptID);
    }
  }, [selectedArea]);

  useEffect(() => {
    if (!props.prompt) {
      // clear the selected paragraph
      setSelectedParagraph(undefined);

      // remove the hash from the URL
      window.location.hash = "";
    } else if (props.prompt && props.prompt?.id) {
      // select the first paragraph in the prompt
      const relevantParagraphs = aiOutput?.prompts.find(
        (p) => p.promptID === props.prompt?.id
      )?.relevantParagraphs;

      if (relevantParagraphs && relevantParagraphs?.length > 0) {
        handleParagraphClick(relevantParagraphs[0].id, props.prompt.id);
      }
    }
  }, [props.prompt]);

  useEffect(() => {
    if (props.document?.id) {
      if (props.document.location !== "sharepoint") {
        // the id changed, clear the array buffer
        // setArrayBuffer(null);

        if (!fileContentExists) {
          // add the file content to the store
          getDocumentContent(props.document).then((res) => {
            if (res.isSuccess) {
              const buffer = res.data as any;
              const id = res.originalArgs.id;
              // setArrayBuffer(buffer);
              if (id) {
                dispatch(addFile({ id, buffer }));
              }
            }
          });
        }
      }
    }
  }, [props.document?.id]);

  useEffect(() => {
    if (aiOutputs && aiOutputs.length > 0) {
      // look up the prompt in the AI output
      setAIOutput(aiOutputs[0]);
    }
  }, [aiOutputs]);

  useEffect(() => {
    setReady(false);
  }, [props.document?.id]);

  useEffect(() => {
    if (props.searchForText) {
      // goToSection(props.searchForText);
      setSearchText(props.searchForText);
      console.log("searching for text", props.searchForText);
    }
  }, [props.searchForText]);

  const mimeType = "application/pdf"; // MIME type of the file
  const fileName =
    props.document?.name || props.driveItem?.name || props.file?.name;

  const extension = fileName
    ? fileName?.substring(fileName?.lastIndexOf(".") + 1)
    : props.fileUrl
    ? props.fileUrl?.substring(props.fileUrl?.lastIndexOf(".") + 1)
    : null;

  const downloadDocument = () => {
    if (props.file) {
      const blob = new Blob([props.file], { type: mimeType });
      saveAs(blob, props.file.name);
    } else if (props.document?.id && fileBuffer) {
      // convert the arraybuffer to a blob and download it
      const blob = new Blob([fileBuffer], { type: mimeType });
      saveAs(blob, fileName || "download.pdf");
    }
  };

  const handleParagraphClick = (paragraphId: string, promptId: string) => {
    const relevantParagraphs = aiOutput?.prompts.find(
      (p) => p.promptID === promptId
    )?.relevantParagraphs;
    const paragraph = relevantParagraphs?.find((p) => p.id === paragraphId);

    if (paragraph) {
      setSelectedParagraph(paragraph);

      // scroll to the selected paragraph
      const elements = document.querySelectorAll(
        '[id^="highlight-ref-' + paragraphId + '"]'
      );

      if (elements.length > 0) {
        elements[0].scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }

      // expand the prompt in the sidebar
      if (expandPromptInSidebar) {
        expandPromptInSidebar(promptId);

        setTimeout(() => {
          const panelElement = window.document.getElementById(
            `panel-${promptId}-block`
          );

          panelElement?.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }, 600);
      }
    }
  };

  const done = () => {
    setReady(true);

    onSuccess && onSuccess();
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const prevSearchResult = () => {
    // find the next search result
    console.log("searching for", searchText);

    if (searchResultIndex > 0) {
      setSearchResultIndex(searchResultIndex - 1);
    }
  };

  const nextSearchResult = () => {
    // find the next search result
    console.log("searching for", searchText);

    setSearchResultIndex(searchResultIndex + 1);
  };

  const isUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const isUpSm = window.innerHeight > 600;

  return (
    <Box
      component={props.disableAppBar ? Paper : Card}
      variant={props.disableAppBar ? "elevation" : "outlined"}
      sx={{
        boxShadow: "none",
        // borderTopLeftRadius: 16,
        // borderTopRightRadius: 16,
        height: "100%",
        // overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {!props.disableAppBar && (
        <AppBar
          position="relative"
          color="primary"
          sx={{
            // backgroundColor: (theme) => lighten(theme.palette.primary.main, 0.3),
            zIndex: (theme) => theme.zIndex.appBar,
            boxShadow: elevation4,
            // zIndex: (theme) => theme.zIndex.modal - 1,
            // borderTopLeftRadius: 32,
            // borderTopRightRadius: 32,
            display: "flex",
          }}
        >
          <Toolbar color="transparent">
            <Stack justifyContent="space-between" direction="row" width="100%">
              <Stack>
                <ListItem
                // secondaryAction={
                //   <IconButton
                //     color="inherit"
                //     onClick={downloadDocument}
                //     title={t("Download")!}
                //   >
                //     <Download />
                //   </IconButton>
                // }
                >
                  <ListItemIcon>
                    <FluentUIFileTypeIcon filename={fileName || ""} />
                  </ListItemIcon>
                  <ListItemText sx={{ pr: 3 }}>{fileName}</ListItemText>
                </ListItem>
              </Stack>

              <Stack justifyContent="center" pr={12}>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Stack>
                    <Search>
                      <SearchIconWrapper>
                        <SearchIcon />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder={t("Search")!}
                        inputProps={{ "aria-label": "search" }}
                        // onChange={(e: any) => {
                        //   // if enter is pressed
                        //   if (e.key === "Enter") {
                        //     setSearchText(e.target.value);
                        //   }
                        // }}
                        onKeyDown={(e) => {
                          // if enter is pressed
                          if (
                            e.key === "Enter" &&
                            e.currentTarget.value?.length >= 3
                          ) {
                            if (searchText === e.currentTarget.value) {
                              // Enter was pressed again, go to the next result
                              nextSearchResult();
                            } else {
                              setSearchResultIndex(0);
                              setSearchText(e.currentTarget.value);
                            }
                          }
                        }}
                      />
                    </Search>
                  </Stack>
                  {/* 
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography variant="body2" px={2}>
                      {searchResultIndex + 1}
                      of {searchResults}
                    </Typography>

                    <IconButton
                      color="inherit"
                      onClick={prevSearchResult}
                      size="small"
                    >
                      <KeyboardArrowLeft />
                    </IconButton>

                    <IconButton
                      color="inherit"
                      onClick={nextSearchResult}
                      size="small"
                    >
                      <KeyboardArrowRight />
                    </IconButton>
                  </Stack> */}
                </Stack>
              </Stack>
            </Stack>
          </Toolbar>

          <LinearProgress variant="determinate" value={loadingProgress * 100} />
        </AppBar>
      )}
      <Container disableGutters sx={{ overflow: "hidden" }}>
        <Box
          sx={{
            // maxHeight: props.disableScroller ? "100%" : "60vh",
            overflow: isUpSm ? "auto" : "show",
            height: isUpSm ? "100%" : "60vh",
          }}
        >
          {/* {props.viewMode !== "pageOnly" && (
        <Grid>
          <StyledGridContainer container justifyContent="space-between">
            <Grid></Grid>
            <Grid>
              <Grid container gap={0} justifyContent="space-between">
                {/* <Grid>
                <IconButton type="button" color="primary" onClick={prevPage} disabled={pageNumber <= 1}>
                  <ArrowCircleUp />
                </IconButton>
              </Grid>
              <Grid>
                <IconButton type="button" color="primary" onClick={nextPage} disabled={pageNumber >= numPages}>
                  <ArrowCircleDown />
                </IconButton>
              </Grid> */}
          {/* <Grid>
                  <Tooltip title={`Zoom out`}>
                    <StyledIconButton
                      type="button"
                      onClick={decreaseScale}
                      disabled={scale <= minScale}
                    >
                      <ZoomOut />
                    </StyledIconButton>
                  </Tooltip>
                </Grid>
                <Grid>
                  <Tooltip title={`Zoom in`}>
                    <StyledIconButton
                      type="button"
                      onClick={increaseScale}
                      disabled={scale >= maxScale}
                    >
                      <ZoomIn />
                    </StyledIconButton>
                  </Tooltip>
                </Grid> 

                <Grid>
                  <IconButton
                    aria-label="more"
                    id={`document-viewer-menubutton-view-more`}
                    aria-controls={open ? `document-viewer-menu` : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    sx={{
                      visibility: "visible",
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <StyledMenu
                    id={`document-viewer-menu`}
                    MenuListProps={{
                      "aria-labelledby": `document-viewer-menubutton-view-more`,
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem
                      key="menu-item-download-document"
                      onClick={downloadDocument}
                    >
                      <Download style={{ marginRight: 2 }} /> {t("Download")}
                    </MenuItem>
                  </StyledMenu>
                </Grid>
              </Grid>
            </Grid>
          </StyledGridContainer>
        </Grid>
      )}

      {props.viewMode !== "pageOnly" && (
        <Grid>
          <Divider />
        </Grid>
      )} */}

          {props.document?.id &&
          !fileContentExists &&
          props.document?.location !== "sharepoint" ? (
            <DocumentViewerSkeleton />
          ) : (
            <Box>
              {outlookAttachment ? (
                <OutlookAttachmentViewer
                  outlookAttachment={outlookAttachment}
                />
              ) : props.document?.location === "sharepoint" ||
                (!props.document?.id && props.document?.driveItemId) ? ( // for drive items that have not been imported yet
                <DriveItemViewer
                  {...props}
                  document={props.document}
                  onSuccess={done}
                  selectedArea={selectedArea}
                  setSelectedArea={setSelectedArea}
                />
              ) : extension === "pdf" ||
                props.document?.origin === "sharepoint" ||
                props.document?.origin === "onedrive" ||
                props.document?.origin === "teams" ||
                props.document?.origin === "outlook" ||
                driveItem?.parentReference.driveId ? ( // drive items can be converted to pdf, so use the pdf viewer
                <ReactPDFViewer // ReactPDFViewer
                  {...props}
                  aiOutput={aiOutput}
                  // arrayBuffer={arrayBuffer}
                  onSuccess={done}
                  selectedArea={selectedArea}
                  setSelectedArea={setSelectedArea}
                />
              ) : extension === "docx" ? (
                <WordViewer
                  {...props}
                  arrayBuffer={undefined}
                  onSuccess={done}
                />
              ) : extension === "png" || extension === "jpeg" ? (
                <ImageViewer
                  {...props}
                  filename={fileName}
                  arrayBuffer={fileBuffer}
                  aiOutput={aiOutput}
                />
              ) : null}
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
}

export default DocumentViewer;
