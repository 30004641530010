// https://redux-toolkit.js.org/rtk-query/usage/examples

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import config from "../../config";
import { IContract } from "../../types/contract";
import { IDocument } from "../../types/document";
import { ISignature } from "../../types/signature";
import i18next from "i18next";

export const DigitalSigningApi: any = createApi({
  reducerPath: "DigitalSigningApi",

  baseQuery: fetchBaseQuery({
    baseUrl: config.API_URL,
    prepareHeaders: (headers: any, { getState, endpoint }: any) => {
      const accessToken = getState().auth.accessToken; // Retrieve the access token

      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      } else {
        if (
          endpoint != "getContractAISignature" &&
          endpoint != "addContractAISignature" &&
          endpoint != "getSignatureDocumentContent" &&
          endpoint != "addSignatureDocumentContent" &&
          endpoint != "getSigningUrl" &&
          endpoint != "getDocumentContent"
        ) {
          throw new Error("No access token available");
        }
      }

      return headers;
    },
  }),

  endpoints: (builder) => ({
    getContractAISignature: builder.query<
      {
        metadata: IContract;
        dataDecrypted: string;
        document: IDocument;
        signatures: ISignature[];
        documentContent: any;
      },
      any
    >({
      query: ({ code, hex }) => ({
        url: `/signature/contract/code/${code}/hex/${hex}`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        console.log(response);

        return response?.data;
      },
    }),
    addContractAISignature: builder.mutation<any, any>({
      query: ({ contractId, signatureId, code, hex, isApproved, reason }) => ({
        url: `/signature/contracts/${contractId}/signatures/${signatureId}/sign`,
        method: "POST",
        body: {
          code,
          hex,
          language: i18next.language,
          isApproved: isApproved ? true : false,
          reason,
        },
      }),
      transformResponse: (response: any): IContract => {
        return response?.data?.contractSignature;
      },
    }),
    addSignatureDocumentContent: builder.mutation({
      query: ({ contractId, documentId, file, type }) => ({
        url: `/signature/contracts/${contractId}/documents/${documentId}/upload/${type}`,
        method: "PUT",
        body: file,
      }),
    }),
    getSigningUrl: builder.query<{ code: string; hex: string }, ISignature>({
      //only start if enabled is true
      query: ({ id, contractId }) => ({
        url: `/signature/contracts/${contractId}/signatures/${id}/signing-url`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        return response?.data;
      },
    }),
    deleteSignature: builder.mutation<string, ISignature>({
      query: ({ id, contractId }) => ({
        url: `/signature/contracts/${contractId}/signatures/${id}`,
        method: "DELETE",
      }),
      transformResponse: (response: any) => {
        return response?.data;
      },
    }),
  }),
});

export const {
  useGetContractAISignatureQuery,
  useAddContractAISignatureMutation,
  useAddSignatureDocumentContentMutation,
  useGetSigningUrlQuery,
  useLazyGetSigningUrlQuery,
  useDeleteSignatureMutation,
} = DigitalSigningApi;
