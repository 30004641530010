import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { blue, grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import {
  Box,
  Chip as MuiChip,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  StepButton,
  Typography,
  Alert,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import { IContract } from "../../../../types/contract";
import { ContractExtractionResult } from "../../../../types/aiOutput";
import OwlAvatarIcon from "../../../../icons/OwlAvatarIcon";
import {
  useAddHumanDataVerificationMutation,
  useGetHumanDataVerificationsQuery,
  useUpdateContractMutation,
} from "../../../../redux/slices/indexApiSlice";
import AISuggestion from "./AISuggestion";
import VerifiedAISuggestion from "./VerifiedAISuggestion";
import useAuth from "../../../../hooks/useAuth";
import Markdown from "react-markdown";

const UploadButton = styled(MuiPaper)`
  height: 144px;
  border: 1px dashed ${(props) => props.theme.palette.divider};
  padding: 24px;
  cursor: pointer;
  &:hover {
    border: 1px dashed ${(props) => props.theme.palette.primary.main};
  }
`;

interface IContractSummaryProps {
  contract: IContract;
  aiOutput?: ContractExtractionResult;
  hideTitle?: boolean;
  setBadgeCount?: (count: number) => void;
}
function ContractSummary({ ...props }: IContractSummaryProps) {
  const { contract } = props;
  const { t } = useTranslation();

  const { data: verifications } = useGetHumanDataVerificationsQuery(contract);

  const isVerified = contract?.summary ? true : false;

  const isAiSuggestion = props.aiOutput?.metadata?.structuralData?.summary
    ? true
    : false;

  const verificationRecord = verifications?.find((v) => v.field === "summary");

  return (
    <Box pb={2}>
      {!props.hideTitle && (
        <Typography variant="h6" mb={2}>
          {t("Summary")}
        </Typography>
      )}

      <VerifiedAISuggestion
        isVerified={isVerified}
        content={
          <Typography variant="body2">
            <Markdown
              components={{
                p: ({ node, ...props }) => (
                  <p
                    style={{
                      marginBlockStart: "0.5rem",
                      marginBlockEnd: "0.5rem",
                    }}
                    {...props}
                  />
                ),
              }}
            >
              {contract?.summary}
            </Markdown>
          </Typography>
        }
        verificationRecord={verificationRecord}
      />

      {props.aiOutput?.metadata?.structuralData?.summary && (
        <AISuggestion
          contract={contract}
          isAiSuggestion={isAiSuggestion}
          isVerified={isVerified || verificationRecord?.isCorrect !== undefined}
          field="summary"
          value={props.aiOutput?.metadata?.structuralData?.summary}
          content={
            <Typography variant="body2">
              <Markdown
                components={{
                  p: ({ node, ...props }) => (
                    <p
                      style={{
                        marginBlockStart: "0.5rem",
                        marginBlockEnd: "0.5rem",
                      }}
                      {...props}
                    />
                  ),
                }}
              >
                {props.aiOutput?.metadata?.structuralData?.summary}
              </Markdown>
            </Typography>
          }
          processedAt={props.aiOutput?.processedAt}
        />
      )}
    </Box>
  );
}

export default ContractSummary;
