import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import {
  Alert as MuiAlert,
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  IconButton,
  Fade,
  Box,
  Typography,
  MenuItem,
  Alert,
  AlertTitle,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { spacing } from "@mui/system";

import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Cancel,
  Check,
  Close,
  AlarmAddOutlined,
  QueryBuilder,
  QueryStats,
  QuestionAnswer,
  AddCircle,
  AddCircleOutlined,
  AddCircleOutline,
  Message,
  AddComment,
} from "@mui/icons-material";

import { IPrompt } from "../../types/prompt";
import useAuth from "../../hooks/useAuth";
import {
  useAddPromptMutation,
  useDeletePromptMutation,
  useUpdatePromptMutation,
} from "../../redux/slices/indexApiSlice";
import { useTranslation } from "react-i18next";
import { DialogMode } from "../../types/dialogmode";
import { MessageSquare } from "react-feather";
import PromptForm from "../forms/PromptForm";

const Button = styled(MuiButton)(spacing);

interface IPromptDialogProps {
  mode: DialogMode;
  open?: boolean;
  handleClose?: () => void;
  prompt?: IPrompt;
  disabled?: boolean;
  compact?: boolean;
  inlineConfirmation?: boolean;
  iconOnly?: boolean;
  asMenuItem?: boolean; // renders the button as a menu item
  buttonVariant?: "text" | "contained" | "outlined";
}

function PromptDialog({ ...props }: IPromptDialogProps) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [open, setOpen] = useState(props.open || false);
  const { mode, asMenuItem } = props;

  const [success, setSuccess] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [
    addPrompt,
    {
      data: addedPrompt,
      isLoading: isAdding,
      isSuccess: isAdded,
      reset: resetAdd,
      isError: addPromptError,
      error: addPromptErrorObject,
    },
  ] = useAddPromptMutation();

  const [
    updatePrompt,
    {
      data: updatedPrompt,
      isSuccess: isUpdated,
      reset: resetUpdate,
      isError: updatePromptError,
      error: updatePromptErrorObject,
    },
  ] = useUpdatePromptMutation();

  const [
    deletePrompt,
    {
      data: deletedPrompt,
      isLoading: isDeleting,
      isSuccess: isDeleted,
      reset: resetDelete,
      isError: deletePromptError,
      error: deletePromptErrorObject,
    },
  ] = useDeletePromptMutation();

  useEffect(() => {
    if (isAdded || isUpdated || isDeleted) {
      if (isAdded && addedPrompt) {
        resetAdd();
      }

      if (isUpdated && updatedPrompt) {
        resetUpdate();
      }

      if (isDeleted && deletedPrompt) {
        resetDelete();
      }

      setSuccess(true);

      resetAndClose();
    }
  }, [
    isAdded,
    isUpdated,
    isDeleted,
    addedPrompt,
    updatedPrompt,
    deletedPrompt,
  ]);

  const resetAndClose = () => {
    setOpen(false);

    if (props.handleClose) {
      props.handleClose();
    }

    // wait for the dialog to close
    setTimeout(() => {
      setSuccess(false);
    }, 500);
  };

  const handleAddPrompt = async (prompt: IPrompt) => {
    const promptToAdd: IPrompt = { ...prompt, author: user?.id };

    await addPrompt(promptToAdd);
  };

  const handleUpdatePrompt = async (prompt: IPrompt) => {
    const promptToUpdate: IPrompt = { ...prompt };

    await updatePrompt(promptToUpdate);
  };

  const handleConfirmDelete = async (e: any) => {
    e.stopPropagation();

    // Call the delete function to delete the item
    if (props.prompt?.id) {
      await deletePrompt(props.prompt.id);
    }

    // Hide the confirmation
    setShowConfirmation(false);
  };

  const handleCancelDelete = (e: any) => {
    e.stopPropagation();

    // Hide the confirmation
    setShowConfirmation(false);
  };

  const handleDeleteClick = (e: any) => {
    e.stopPropagation();

    if (props.inlineConfirmation) {
      setShowConfirmation(true);
    } else {
      setOpen(true);
    }
  };

  const handleEditClick = (e: any) => {
    e.stopPropagation();
    setOpen(true);
  };

  return (
    <>
      {mode === DialogMode.Delete && (
        <>
          {!showConfirmation && (
            <Tooltip title={t("Delete prompt")}>
              {props.iconOnly ? (
                <IconButton
                  size="small"
                  onClick={handleDeleteClick}
                  disabled={props.disabled}
                >
                  <DeleteIcon />
                </IconButton>
              ) : (
                <Button
                  color="primary"
                  onClick={() => setOpen(true)}
                  disabled={props.disabled}
                >
                  {t("Delete prompt")}
                </Button>
              )}
            </Tooltip>
          )}

          {props.inlineConfirmation && showConfirmation && (
            <Fade in={showConfirmation}>
              <Box>
                <Typography component="span">{t("Are you sure?")}</Typography>
                <IconButton
                  aria-label="Cancel"
                  size="small"
                  onClick={handleCancelDelete}
                  disabled={props.disabled}
                >
                  <Cancel />
                </IconButton>
                <IconButton
                  aria-label="Confirm"
                  size="small"
                  onClick={handleConfirmDelete}
                  disabled={props.disabled}
                >
                  <Check />
                </IconButton>
              </Box>
            </Fade>
          )}
        </>
      )}

      {props.mode === DialogMode.Add && (
        <>
          {props.iconOnly ? (
            <IconButton
              size="large"
              color="inherit"
              onClick={() => setOpen(true)}
            >
              <Message />
            </IconButton>
          ) : (
            <Button
              variant={props.buttonVariant || "text"}
              onClick={() => setOpen(true)}
              disabled={props.disabled}
            >
              <AddCircleOutline sx={{ mr: 2 }} /> {t("Add prompt")}
            </Button>
          )}
        </>
      )}

      {mode === DialogMode.Edit && (
        <Tooltip title={t("Edit prompt")}>
          {props.iconOnly ? (
            <IconButton
              size="small"
              onClick={handleEditClick}
              disabled={props.disabled}
            >
              <EditIcon />
            </IconButton>
          ) : (
            <Button
              color="primary"
              onClick={handleEditClick}
              disabled={props.disabled}
            >
              {t("Edit")}
            </Button>
          )}
        </Tooltip>
      )}

      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        // onClose={() => setOpen(false)}
        aria-labelledby="prompt-dialog-title"
        id="prompt-dialog"
      >
        <DialogTitle id="prompt-dialog-title">
          <Grid container justifyContent="space-between">
            <Grid>
              {mode === DialogMode.Add
                ? t("Add prompt")
                : mode === DialogMode.Edit
                ? t("Edit prompt")
                : mode === DialogMode.Delete
                ? t("Are you sure you want to delete this prompt?")
                : ""}
            </Grid>
            <Grid>
              <IconButton size="small" onClick={() => setOpen(false)}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        {mode !== DialogMode.Delete && (
          <DialogContent>
            {(updatePromptError || addPromptError || deletePromptError) && (
              <Alert severity="error" sx={{ mb: 6 }}>
                <AlertTitle>{t("An error occurred!")}</AlertTitle>

                {JSON.stringify(addPromptErrorObject)}

                {JSON.stringify(updatePromptErrorObject)}

                {JSON.stringify(deletePromptErrorObject)}
              </Alert>
            )}

            <PromptForm
              mode={props.mode}
              addPrompt={handleAddPrompt}
              updatePrompt={handleUpdatePrompt}
              prompt={props.prompt}
            />
          </DialogContent>
        )}
        <DialogActions>
          {success ? (
            <Button
              variant="contained"
              onClick={() => resetAndClose()}
              color="primary"
            >
              {t("Close")}
            </Button>
          ) : (
            <>
              {!success && (
                <Button onClick={() => resetAndClose()} color="primary">
                  {t("Cancel")}
                </Button>
              )}

              {(mode === DialogMode.Edit || mode === DialogMode.Add) && (
                <Button
                  type="submit"
                  form="prompt-form"
                  color="primary"
                  disabled={isAdding}
                  variant="contained"
                >
                  {t("Save")}
                </Button>
              )}

              {mode === DialogMode.Delete && (
                <Button
                  variant="contained"
                  onClick={handleConfirmDelete}
                  color="primary"
                >
                  {t("Delete")}
                </Button>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PromptDialog;
