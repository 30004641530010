import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { blue, grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import {
  Box,
  Chip as MuiChip,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  StepButton,
  Typography,
  Alert,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { IContract } from "../../../../types/contract";
import { ContractExtractionResult } from "../../../../types/aiOutput";
import OwlAvatarIcon from "../../../../icons/OwlAvatarIcon";
import VerifiedAISuggestion from "./VerifiedAISuggestion";
import AISuggestion from "./AISuggestion";
import {
  useAddHumanDataVerificationMutation,
  useGetHumanDataVerificationsQuery,
  useUpdateContractMutation,
} from "../../../../redux/slices/indexApiSlice";
import useAuth from "../../../../hooks/useAuth";

interface IContractSubjectProps {
  contract: IContract;
  aiOutput?: ContractExtractionResult;
  hideTitle?: boolean;
}
function ContractSubject({ ...props }: IContractSubjectProps) {
  const { contract } = props;
  const { t } = useTranslation();

  const { data: verifications } = useGetHumanDataVerificationsQuery(contract);

  const isVerified = contract?.subject ? true : false;

  const isAiSuggestion = props.aiOutput?.metadata?.structuralData?.subject
    ? true
    : false;

  const verificationRecord = verifications?.find((v) => v.field === "subject");
  const verifiedBy = verificationRecord?.verifiedBy;
  const verifiedDate = verificationRecord?.verifiedDate;

  return (
    <Box pb={2}>
      {!props.hideTitle && (
        <Typography variant="h6" mb={2}>
          {t("Subject")}
        </Typography>
      )}

      <VerifiedAISuggestion
        isVerified={isVerified}
        content={<Typography variant="body2">{contract?.subject}</Typography>}
        verificationRecord={verificationRecord}
      />

      {props.aiOutput?.metadata?.structuralData?.subject && (
        <AISuggestion
          contract={contract}
          isAiSuggestion={isAiSuggestion}
          isVerified={isVerified || verificationRecord?.isCorrect !== undefined}
          field="subject"
          value={props.aiOutput?.metadata?.structuralData?.subject}
          content={
            <Typography variant="body2">
              {props.aiOutput?.metadata?.structuralData?.subject}
            </Typography>
          }
          processedAt={props.aiOutput?.processedAt}
        />
      )}
    </Box>
  );
}

export default ContractSubject;
