import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Alert as MuiAlert,
  AvatarGroup,
  Box,
  Button as MuiButton,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Typography,
  Tooltip,
  AlertTitle,
  Divider,
  CssBaseline,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { borderLeft, spacing } from "@mui/system";
import {
  useAddContractMutation,
  useAddDocumentContentFromDownloadUrlMutation,
  useAddDocumentContentMutation,
  useAddDocumentMutation,
  useDeleteContractMutation,
  useGetSubscriptionInfoQuery,
  useUpdateContractMutation,
} from "../../redux/slices/indexApiSlice";
import {
  Edit as EditIcon,
  Cancel,
  FileDownloadOutlined,
  FileUploadOutlined,
  Block,
} from "@mui/icons-material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { ContractStatus, IContract } from "../../types/contract";
import { useTranslation } from "react-i18next";
import { DialogMode } from "../../types/dialogmode";
import { Check } from "react-feather";
import useAuth from "../../hooks/useAuth";
import { FileUploader } from "react-drag-drop-files";
import FilesToUploadList from "../lists/FilesToUploadList";
import { IDocument } from "../../types/document";
import ImportDialog from "./ImportDialog";
import {
  useGetOutlookMessageAttachmentContentQuery,
  useLazyGetDriveItemContentAsPDFQuery,
  useLazyGetDriveItemContentQuery,
  useLazyGetOutlookMessageAttachmentContentQuery,
} from "../../redux/slices/graphApiSlice";

import { useAnalyzeFileMutation } from "../../redux/slices/aiSlice";
import LoadingButton from "@mui/lab/LoadingButton";
import NoFilesFoundIcon from "../../icons/NoFilesFoundIcon";
import useBotChat from "../../hooks/useBotChat";
import ConnectDriveDialog from "./ConnectDriveDialog";
import { IConnectedDrive } from "../../types/connecteddrive";
import useContract from "../../hooks/useContract";
import { IOutlookMessageAttachment } from "../../types/outlookattachment";

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);

const UploadButton = styled(MuiPaper)`
  height: 144px;
  border: 1px dashed ${(props) => props.theme.palette.divider};
  padding: 12px;
  cursor: pointer;

  &:hover {
    border: 1px dashed ${(props) => props.theme.palette.primary.main};
  }
`;

const DisabledUploadButton = styled(MuiPaper)`
  height: 144px;
  border: 1px dashed ${(props) => props.theme.palette.divider};
  padding: 12px;
`;

type IInitiateDocumentsDialogProps = {
  mode: DialogMode;
  newAIinfo?: boolean;
  iconOnly?: boolean;
  inlineConfirmation?: boolean;
  contract?: IContract;
  refetch?: () => void;
  initialValues?: any;
};

function InitiateDocumentsDialog(props: IInitiateDocumentsDialogProps) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState(props.mode || DialogMode.Add);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [errorType, setErrorType] = useState<any>("");
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [documentsToImport, setDocumentsToImport] = useState<IDocument[]>([]);
  const [allFiles, setAllFiles] = useState<(File | IDocument)[]>([]);
  const [disableNewUploads, setDisableNewUploads] = useState<boolean>(false);
  const [disableImport, setDisableImport] = useState<boolean>(false);
  const [disableConnectDrive, setDisableConnectDrive] = useState<boolean>(true);

  const maxUploadFiles = 10;
  const [maxFilesReached, setMaxFilesReached] = useState<boolean>(false);

  const [uploadsProgress, setUploadsProgress] = useState<
    {
      filename: string;
      progress: number;
    }[]
  >([]);
  const [importDialogOpen, setImportDialogOpen] = useState<boolean>(false);
  const [connectDriveDialogOpen, setConnectDriveDialogOpen] =
    useState<boolean>(false);

  // since we can handle multiple contracts uploads at once we keep an array with the created contracts
  const [addedContracts, setAddedContracts] = useState<IContract[]>([]);

  // since we can handle multiple documents uploads at once we keep an array with the created documents
  const [addedDocuments, setAddedDocuments] = useState<IDocument[]>([]);
  const [openDialog, setOpenDialog] = useState(false);

  const { requestDocumentAnalysis } = useContract();

  const onTypeError = (error: any) => {
    setErrorType(error);
    console.log(error);
  };

  const { data: subscriptionInfo } = useGetSubscriptionInfoQuery();

  const [
    deleteContract,
    {
      data: deletedContract,
      isLoading: isDeleting,
      isSuccess: isDeleted,
      reset: resetDelete,
    },
  ] = useDeleteContractMutation();

  const [
    addContract,
    {
      isLoading: addingContract,
      isSuccess: contractAdded,
      data: addedContract,
      reset: resetContractAdded,
      isError: errorWhileAddingContract,
      error: addedContractError,
    },
  ] = useAddContractMutation();

  const [
    addDocument,
    {
      isLoading: addingDocument,
      isSuccess: documentAdded,
      data: addedDocument,
      reset: resetDocumentAdd,
      isError: errorWhileAddingDocument,
      error: addedDocumentError,
    },
  ] = useAddDocumentMutation();

  const [
    uploadDocumentContent,
    {
      isLoading: isUploading,
      isSuccess: documentContentStored,
      reset: resetContent,
      isError: errorWhileUploading,
      error: uploadError,
    },
  ] = useAddDocumentContentMutation();

  const [getDriveItemContent] = useLazyGetDriveItemContentQuery();

  const [getDriveItemContentAsPDF] = useLazyGetDriveItemContentAsPDFQuery();

  const [getOutlookAttachmentContent] =
    useLazyGetOutlookMessageAttachmentContentQuery();

  const [
    uploadDocumentContentFromDownloadUrl,
    {
      isError: errorWhileUploadingFromDownloadUrl,
      error: uploadErrorFromDownloadUrl,
    },
  ] = useAddDocumentContentFromDownloadUrlMutation();

  useEffect(() => {
    if (!open) {
      setFilesToUpload([]);
      setDocumentsToImport([]);
      setUploadsProgress([]);
      setAddedContracts([]);
      setAddedDocuments([]);
    }
  }, [open]);

  const resetAndClose = () => {
    setOpenDialog(false);
    setOpen(false);
  };

  useEffect(() => {
    if (
      (subscriptionInfo?.contractsRemaining &&
        subscriptionInfo.contractsRemaining - allFiles?.length < 0) ||
      (subscriptionInfo?.contractsRemaining === 0 && allFiles.length === 0)
    ) {
      setDisableNewUploads(true);
    } else {
      setDisableNewUploads(false);
    }
  }, [subscriptionInfo, allFiles]);

  useEffect(() => {
    if (allFiles?.length >= maxUploadFiles) {
      setMaxFilesReached(true);
    } else {
      setMaxFilesReached(false);
    }
  }, [allFiles]);

  useEffect(() => {
    // console.log(filesToUpload);
    // console.log(documentsToImport);

    const newAllFiles = [...filesToUpload, ...documentsToImport];

    if (newAllFiles?.length > maxUploadFiles) {
      const filesToRemove = newAllFiles.slice(maxUploadFiles);

      // remove these files from the filesToUpload or documentsToImport
      const newFilesToUpload = filesToUpload.filter(
        (f) => !filesToRemove.includes(f)
      );

      const newDocumentsToImport = documentsToImport.filter(
        (d) => !filesToRemove.includes(d)
      );

      setFilesToUpload(newFilesToUpload);
      setDocumentsToImport(newDocumentsToImport);
    } else {
      setAllFiles(newAllFiles);
    }

    setAllFiles(newAllFiles);
  }, [filesToUpload, documentsToImport]);

  useEffect(() => {
    if (isDeleted) {
      // redirect to the contracts page
      navigate(`/contracts/${ContractStatus.DRAFT}`, {
        state: { reload: true },
      });

      resetDelete();
    }
  }, [isDeleted]);

  useEffect(() => {
    if (
      uploadsProgress.every((u) => u.progress === 100) &&
      addedDocuments.length === uploadsProgress.length
    ) {
      // handleComplete();
      // handleCloseAfterSuccess();
    }
  }, [uploadsProgress, addedDocuments]);

  // useEffect(() => {
  //   if (isAdded || isUpdated || isDeleted) {
  //     if (isAdded && addedContract) {
  //       resetAdd();
  //     }

  //     if (isUpdated && updatedContract) {
  //       resetUpdate();
  //     }

  //     if (isDeleted && deletedContract) {
  //       resetDelete();
  //     }

  //     setSuccess(true);

  //     if (props.refetch) {
  //       // re-get the message items if the add or delete mutation was successful
  //       props.refetch();
  //     }
  //   }
  // }, [
  //   isAdded,
  //   isUpdated,
  //   isDeleted,
  //   addedContract,
  //   updatedContract,
  //   deletedContract,
  // ]);

  const handleDeleteFileToUpload = (index: number) => {
    const fileToDelete = allFiles[index];

    if (fileToDelete instanceof File) {
      deleteFileToUpload(fileToDelete as File);
    } else {
      deleteDocumentToImport(fileToDelete);
    }
  };

  const deleteFileToUpload = (fileToDelete: File) => {
    // remove the files from filesToUpload
    const newFilesToUpload = filesToUpload.filter(
      (f) => f.name !== fileToDelete.name && f.size !== fileToDelete.size
    );

    setFilesToUpload([...newFilesToUpload]);
    // the file will be automatically removed from the allFiles by the useEffect
  };

  const deleteDocumentToImport = (document: IDocument) => {
    const newDocuments = documentsToImport.filter(
      (d) => d.driveItemId !== document.driveItemId
    );

    console.log("newDocuments", newDocuments);
    setDocumentsToImport([...newDocuments]);

    // the document will be automatically removed from the allFiles by the useEffect
  };

  const handleUploads = async (moreFiles: any) => {
    if (!moreFiles[0].origin || moreFiles[0].origin === "upload") {
      setFilesToUpload([...filesToUpload, ...moreFiles]);
    } else {
      setDocumentsToImport([...documentsToImport, ...moreFiles]);
    }
  };

  const addDocumentToImport = (doc: IDocument) => {
    // add the doc to documentsToImport
    setDocumentsToImport((prev) => [...prev, doc]);
  };

  const handleImport = async (docs: Omit<IDocument, "id">[]) => {
    docs.forEach((doc) => {
      addDocumentToImport(doc);
    });
  };

  const handleConnectDrive = async (newDrive: IConnectedDrive) => {
    //addDocumentToImport(newDrive);
  };

  const handleConfirmDelete = async (e: any) => {
    e.stopPropagation();

    if (props.contract) {
      // Call the delete function to delete the item
      await deleteContract(props.contract.id);
    }

    // Hide the confirmation
    setShowConfirmation(false);
  };

  const handleCancelDelete = (e: any) => {
    e.stopPropagation();

    // Hide the confirmation
    setShowConfirmation(false);
  };

  const handleEditClick = (e: any) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleDeleteClick = (e: any) => {
    e.stopPropagation();

    if (props.inlineConfirmation) {
      setShowConfirmation(true);
    } else {
      setOpen(true);
    }
  };

  const fileTypes = ["PDF", "JPG", "JPEG", "PNG", "BMP"]; //  "DOC", "DOCX",

  const handleImportDialogClose = () => {
    setImportDialogOpen(false);
  };

  const handleImportDialogOpen = () => {
    setImportDialogOpen(true);
  };

  const handleConnectDriveDialogOpen = () => {
    setConnectDriveDialogOpen(true);
  };

  const handleConnectDriveDialogClose = () => {
    setConnectDriveDialogOpen(false);
  };

  const storeUsers = (): [] => {
    let userStore: any = [];

    // // add the add users to a new contract, so send notification and mail of expired contact.
    // if (analyzedContract && method != "Google" && users && users.length) {
    //   // If method is Google then we stored this user by login step
    //   let userIds: string[] =
    //     (analyzedContract && analyzedContract.ownerIds) ||
    //     (analyzedContract && analyzedContract.managerIds)
    //       ? [...analyzedContract?.ownerIds, ...analyzedContract?.managerIds]
    //       : [];

    //   const userIdsUnique = userIds?.filter((value, index, self) => {
    //     return self.indexOf(value) === index;
    //   });
    //   userStore = users
    //     .filter((u: any) => userIdsUnique.some((id: any) => id == u.id))
    //     .map((item: any) => {
    //       item = { ...item, email: item.mail || item.userPrincipalName };
    //       return item;
    //     });
    // }

    return userStore;
  };

  const handleProgress = (
    filename: string,
    progress: number,
    index: number // pass the index in case there are documents with the same filename
  ) => {
    setUploadsProgress((prev: any) => {
      if (
        !prev.some((p: any) => p.filename === filename && p.index === index)
      ) {
        // initiate the progress
        return [...prev, { filename, progress, index }];
      } else {
        // update the progress
        const updatedProgress = prev.map((p: any) => {
          if (p.filename === filename && p.index === index) {
            p.progress = progress;
          }
          return p;
        });

        return updatedProgress;
      }
    });
  };

  const uploadFile = (f: File, index: number): PromiseLike<boolean> => {
    return new Promise((resolve, reject) => {
      handleProgress(f.name, 10, index);

      // create a contract for the file
      const newContract: any = {
        status: ContractStatus.PUBLISHED, // an imported contract is always published, drafts are new files that are created by the user or generated by AI
        name: f.name,
        ownerIds: [user.id],
        managerIds: [user.id],
        users: storeUsers(),
      };

      addContract(newContract)
        .unwrap()
        .then((contract_payload) => {
          setAddedContracts((prev: any) => {
            return [...prev, contract_payload];
          });

          handleProgress(f.name, 40, index);

          // create a document for the file
          const newDocument: IDocument = {
            name: f.name,
            type: "contract",
            origin: "upload",
            location: "blobstorage",
            size: f.size,
            contractId: contract_payload.id,
          };

          addDocument(newDocument)
            .unwrap()
            .then((document_payload) => {
              setAddedDocuments((prev: any) => {
                return [...prev, document_payload];
              });

              handleProgress(f.name, 50, index);

              // upload the file content
              uploadLocalFileContentToAzureBlobStorage(
                f,
                document_payload,
                contract_payload
              )
                .then(() => {
                  handleProgress(f.name, 100, index);

                  requestDocumentAnalysis(document_payload);

                  resolve(true);
                })
                .catch((error: any) => {
                  reject(error);
                });
            })
            .catch((error: any) => {
              reject(error);
            });
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };

  const uploadDocument = (
    d: Omit<IDocument, "contractId">,
    index: number
  ): PromiseLike<boolean> => {
    return new Promise((resolve, reject) => {
      handleProgress(d.name, 10, index);

      // create a contract for the document
      const newContract: any = {
        // Partial<IContract>
        status: ContractStatus.DRAFT,
        name: d.name,
        adminIds: [user.id],
        ownerIds: [user.id],
        managerIds: [user.id],
        users: storeUsers(),
      };

      addContract(newContract)
        .unwrap()
        .then((contract_payload) => {
          setAddedContracts((prev: any) => {
            return [...prev, contract_payload];
          });

          handleProgress(d.name, 40, index);

          // add the contract id to the document
          const newDocument: IDocument = {
            origin: d.origin,
            attachmentId: d.attachmentId,
            messageId: d.messageId,
            driveId: d.driveId,
            previewUrl: d.previewUrl,
            name: d.name,
            downloadUrl: d.downloadUrl,
            type: "contract",
            contractId: contract_payload.id,
            driveItemId: d.driveItemId,
            location: "blobstorage",
            size: d.size,
          };

          addDocument(newDocument)
            .unwrap()
            .then((document_payload) => {
              setAddedDocuments((prev: any) => {
                return [...prev, document_payload];
              });

              handleProgress(d.name, 50, index);

              // upload the document content
              uploadDocumentContentToAzureBlobStorage(
                document_payload,
                contract_payload
              )
                .then(() => {
                  handleProgress(d.name, 100, index);

                  requestDocumentAnalysis(document_payload);

                  resolve(true);
                })
                .catch((error: any) => {
                  reject(error);
                });
            })
            .catch((error: any) => {
              reject(error);
            });
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };

  const handleSubmit = async () => {
    if (mode === DialogMode.Add) {
      await uploadFiles();
    }
  };

  const uploadFiles = async () => {
    const promises: PromiseLike<boolean>[] = [];
    filesToUpload?.forEach((f: File, i: number) => {
      promises.push(uploadFile(f, i));
    });

    documentsToImport?.forEach((d: IDocument, i: number) => {
      promises.push(uploadDocument(d, i));
    });

    await Promise.all(promises)
      .then(() => {
        // all done, refetch the contracts list
        // if (props.refetch) {
        //   props.refetch();
        // }
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const uploadLocalFileContentToAzureBlobStorage = async (
    fileToUpload: File,
    document: IDocument,
    contract: IContract
  ) => {
    if (document?.origin === "upload") {
      const formData = new FormData();
      formData.append("file", fileToUpload);
      await uploadDocumentContent({
        contractId: contract.id,
        documentId: document.id,
        file: formData,
      });
    }
  };

  const uploadDocumentContentToAzureBlobStorage = async (
    document: IDocument,
    contract: IContract
  ) => {
    // upload the document content

    return new Promise(async (resolve, reject) => {
      if (
        document?.origin === "sharepoint" ||
        document?.origin === "onedrive" ||
        document?.origin === "teams"
      ) {
        let driveItemContentUrl;

        if (document?.name?.toLowerCase().endsWith(".pdf")) {
          // handle the fetched content

          const res = await getDriveItemContent(document);

          if (res.isError) {
            reject(res.error);
            return;
          }

          if (!res.isLoading && !res.isUninitialized && res.data) {
            driveItemContentUrl = res.data;
          }
        } else {
          // get the drive item download url
          driveItemContentUrl = await getDriveItemContentAsPDF(document)
            .unwrap()
            .catch((error: any) => {
              reject(error);
              return;
            });
        }

        if (!driveItemContentUrl) {
          reject("No content found");
          return;
        }

        uploadDocumentContentFromDownloadUrl({
          contractId: contract.id,
          documentId: document.id,
          downloadUrl: driveItemContentUrl,
        })
          .unwrap()
          .then(() => {
            resolve(true);
          })
          .catch((error: any) => {
            reject(error);
          });
      } else if (
        document?.origin === "outlook" &&
        document?.messageId &&
        document?.attachmentId
      ) {
        const res = await getOutlookAttachmentContent({
          messageId: document.messageId,
          attachmentId: document.attachmentId,
        });

        if (res.isError) {
          reject(res.error);
          return;
        }

        const formData = new FormData();
        formData.append("file", res.data);
        await uploadDocumentContent({
          contractId: contract.id,
          documentId: document.id,
          file: formData,
        })
          .then((res: any) => {
            resolve(true);
          })
          .catch((error: any) => {
            reject(error);
          });
      }
    });
  };

  return (
    <>
      {mode === DialogMode.Delete && (
        <>
          {!showConfirmation && (
            <Tooltip title={t("Delete")}>
              {props.iconOnly ? (
                <IconButton size="small" onClick={handleDeleteClick}>
                  <DeleteIcon />
                </IconButton>
              ) : (
                <Button color="primary" onClick={() => setOpen(true)}>
                  {t("Delete contract")}
                </Button>
              )}
            </Tooltip>
          )}

          {props.inlineConfirmation && showConfirmation && (
            <Fade in={showConfirmation}>
              <Box>
                <Typography component="span">{t("Are you sure?")}</Typography>
                <IconButton
                  aria-label="Cancel"
                  size="small"
                  onClick={handleCancelDelete}
                >
                  <Cancel />
                </IconButton>
                <IconButton
                  aria-label="Confirm"
                  size="small"
                  onClick={handleConfirmDelete}
                >
                  <Check />
                </IconButton>
              </Box>
            </Fade>
          )}
        </>
      )}

      {mode === DialogMode.Add && (
        <>
          {props.iconOnly ? (
            <Tooltip title={t("Upload or import a contract")}>
              <IconButton
                onClick={() => setOpen(true)}
                color="inherit"
                size="large"
              >
                <FileUploadOutlined />
              </IconButton>
            </Tooltip>
          ) : (
            <LoadingButton
              variant="contained"
              sx={{ alignSelf: "flex-end" }}
              onClick={() => setOpen(true)}
            >
              <FileUploadOutlined
                style={{ marginRight: "8px" }}
              ></FileUploadOutlined>{" "}
              {t("Upload or import a contract")}
            </LoadingButton>
          )}
        </>
      )}

      {mode === DialogMode.Edit &&
        (props.iconOnly ? (
          <IconButton size="small" onClick={handleEditClick}>
            <EditIcon />
          </IconButton>
        ) : (
          <Button color="primary" onClick={handleEditClick}>
            {t("Quick edit")}
          </Button>
        ))}

      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
        // fullScreen
        sx={{ padding: 10, marginX: 30, marginY: 15 }}
      >
        <DialogTitle id="form-dialog-title">
          {mode === DialogMode.Add
            ? t("New contract")
            : mode === DialogMode.Edit
            ? `${t("Edit")} ${props.contract?.name}`
            : mode === DialogMode.Delete
            ? t("Are you sure you want to delete this contract?")
            : ""}
        </DialogTitle>
        <DialogContent>
          {mode !== DialogMode.Delete && (
            <>
              <Grid
                container
                spacing={6}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  size={{ xs: 12, md: 4 }}
                  sx={
                    mode === DialogMode.Edit
                      ? {
                          pointerEvents: "none",
                          opacity: 0.5,
                        }
                      : null
                  }
                >
                  <FileUploader
                    multiple={true}
                    handleChange={handleUploads}
                    name="file"
                    types={fileTypes}
                    onTypeError={onTypeError}
                    disabled={disableNewUploads}
                  >
                    {maxFilesReached || disableNewUploads ? (
                      <DisabledUploadButton>
                        <Grid
                          container
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          textAlign="center"
                        >
                          <Grid>
                            <Block fontSize="large" color="primary" />
                          </Grid>
                          <Grid>
                            <Typography
                              mt={2}
                              mb={1}
                              variant="subtitle2"
                              color="error"
                            >
                              {disableNewUploads
                                ? t("Subscription_limit_reached")
                                : t("Maximum_files_reached")}
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                              {disableNewUploads
                                ? t("Subscription_limit_reached_description", {
                                    count: subscriptionInfo?.contractsRemaining,
                                  })
                                : t("Maximum_files_reached_description", {
                                    count: maxUploadFiles,
                                  })}
                            </Typography>
                          </Grid>
                        </Grid>
                      </DisabledUploadButton>
                    ) : (
                      <UploadButton
                        onClick={() => console.log("upload button clicked")}
                        sx={{ cursor: "pointer" }}
                      >
                        <Grid
                          container
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          textAlign="center"
                        >
                          <Grid>
                            <FileUploadOutlined
                              fontSize="large"
                              color="primary"
                            />
                          </Grid>
                          <Grid>
                            <Typography mt={2} mb={1} variant="subtitle2">
                              <Link underline="none">
                                {t("Click_to_upload")}
                              </Link>{" "}
                              {t("or_drag_and_drop")}
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                              {t("Maximum_file_size", { size: 30 })}
                            </Typography>
                          </Grid>
                        </Grid>
                      </UploadButton>
                    )}
                  </FileUploader>
                </Grid>
                <Grid
                  size={{ xs: 12, md: 4 }}
                  sx={
                    mode === DialogMode.Edit
                      ? {
                          pointerEvents: "none",
                          opacity: 0.5,
                        }
                      : null
                  }
                >
                  {disableNewUploads || maxFilesReached ? (
                    <DisabledUploadButton>
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        textAlign="center"
                      >
                        <Grid>
                          <Block fontSize="large" color="primary" />
                        </Grid>
                        <Grid>
                          <Typography
                            mt={2}
                            mb={1}
                            variant="subtitle2"
                            color="error"
                          >
                            {disableNewUploads
                              ? t("Subscription_limit_reached")
                              : t("Maximum_files_reached")}
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            {disableNewUploads
                              ? t("Subscription_limit_reached_description", {
                                  count: subscriptionInfo?.contractsRemaining,
                                })
                              : t("Maximum_files_reached_description", {
                                  count: maxUploadFiles,
                                })}
                          </Typography>
                        </Grid>
                      </Grid>
                    </DisabledUploadButton>
                  ) : disableImport ? (
                    <DisabledUploadButton>
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        textAlign="center"
                      >
                        <Grid>
                          <Block fontSize="large" color="primary" />
                        </Grid>
                        <Grid>
                          <Typography
                            mt={2}
                            mb={1}
                            variant="subtitle2"
                            color="error"
                          >
                            {t("Import_from_Microsoft_resources")}
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            {t("Coming soon")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </DisabledUploadButton>
                  ) : (
                    <UploadButton
                      variant="outlined"
                      onClick={() => handleImportDialogOpen()}
                      sx={{ cursor: "pointer" }}
                    >
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        textAlign="center"
                      >
                        <Box>
                          <FileDownloadOutlined
                            fontSize="large"
                            color="primary"
                          />
                        </Box>
                        <Typography mt={2} mb={1} variant="subtitle2">
                          {t("Import_from_Microsoft_resources")}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          {t("Import_from_Microsoft_resources_subtitle")}
                        </Typography>
                      </Grid>
                    </UploadButton>
                  )}
                </Grid>

                {/* <Divider orientation="vertical" flexItem sx={{ mx: 6 }} /> */}

                <Grid
                  size={{ xs: 12, md: 4 }}
                  sx={
                    mode === DialogMode.Edit
                      ? {
                          pointerEvents: "none",
                          opacity: 0.5,
                        }
                      : null
                  }
                >
                  {disableConnectDrive ? (
                    <DisabledUploadButton>
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        textAlign="center"
                      >
                        <Grid>
                          <Block fontSize="large" color="primary" />
                        </Grid>
                        <Grid>
                          <Typography
                            mt={2}
                            mb={1}
                            variant="subtitle2"
                            color="error"
                          >
                            {t("New connected drive")}
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            {t("Coming soon")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </DisabledUploadButton>
                  ) : (
                    <UploadButton
                      variant="outlined"
                      onClick={() => handleConnectDriveDialogOpen()}
                      sx={{ cursor: "pointer" }}
                    >
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        textAlign="center"
                      >
                        <Box>
                          <FileDownloadOutlined
                            fontSize="large"
                            color="primary"
                          />
                        </Box>
                        <Typography mt={2} mb={1} variant="subtitle2">
                          {t("New connected drive")}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          {t("Connect your own cloud hosting service")}
                        </Typography>
                      </Grid>
                    </UploadButton>
                  )}
                </Grid>
              </Grid>
              {errorType ? (
                <Typography mt={2} mb={1} variant="subtitle1" color="error">
                  {errorType}
                </Typography>
              ) : (
                <></>
              )}
              {errorWhileAddingContract && (
                <Alert severity="error">
                  <AlertTitle>{t("An error occurred!")}</AlertTitle>
                  {JSON.stringify(addedContractError)}
                </Alert>
              )}
              {errorWhileAddingDocument && (
                <Alert severity="error">
                  <AlertTitle>{t("An error occurred!")}</AlertTitle>
                  {JSON.stringify(addedDocumentError)}
                </Alert>
              )}
              {errorWhileUploading && (
                <Alert severity="error">
                  <AlertTitle>{t("An error occurred!")}</AlertTitle>
                  {JSON.stringify(uploadError)}
                </Alert>
              )}
              {errorWhileUploadingFromDownloadUrl && (
                <Alert severity="error">
                  <AlertTitle>{t("An error occurred!")}</AlertTitle>
                  {JSON.stringify(uploadErrorFromDownloadUrl)}
                </Alert>
              )}

              {mode === DialogMode.Add && allFiles?.length > 0 && (
                <FilesToUploadList
                  mode={mode}
                  filesWithProgress={
                    allFiles
                      .filter((f) => f instanceof File)
                      .map((f: File | IDocument, i: number) => {
                        if (f instanceof File) {
                          const progress = uploadsProgress.find(
                            (p) => p.filename === f.name
                          );
                          return { file: f, progress: progress?.progress || 0 };
                        }
                      }) as any
                  }
                  handleDeleteFileToUpload={handleDeleteFileToUpload}
                  documents={
                    allFiles
                      .filter((f) => !(f instanceof File))
                      .map((f: File | IDocument, i: number) => {
                        if (!(f instanceof File)) {
                          const progress = uploadsProgress.find(
                            (p) => p.filename === f.name
                          );
                          return f;
                        }
                      }) as any
                  }
                />
              )}
              {/* {allFiles?.length === 0 && (
                <Box mt={12} textAlign="center">
                  <NoFilesFoundIcon
                    sx={{
                      width: 65,
                      height: "auto",
                    }}
                  ></NoFilesFoundIcon>
                  <Typography mt={2} mb={1} variant="h6">
                    {t("No_files_found")}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {t("Upload_or_import_to_see_here")}
                  </Typography>
                </Box>
              )} */}
            </>
          )}
        </DialogContent>
        <DialogActions>
          {(allFiles.length === 0 || uploadsProgress.length == 0) && (
            <Button onClick={() => resetAndClose()} color="primary" autoFocus>
              {t("Cancel")}
            </Button>
          )}

          {(mode === DialogMode.Edit || mode === DialogMode.Add) && (
            <>
              {allFiles.length > 0 && (
                <>
                  {uploadsProgress.length > 0 &&
                  uploadsProgress.every((u) => u.progress === 100) ? (
                    <Button
                      onClick={() => resetAndClose()}
                      variant="contained"
                      color="primary"
                    >
                      {t("Close")}
                    </Button>
                  ) : (
                    <Button
                      onClick={handleSubmit}
                      variant="contained"
                      color="primary"
                      disabled={
                        (uploadsProgress.length > 0 &&
                          !uploadsProgress.every((u) => u.progress === 100)) ||
                        disableNewUploads ||
                        maxFilesReached
                      }
                    >
                      {allFiles.length === 1
                        ? t("Import1Contract")
                        : t("ImportXContracts", {
                            count: allFiles.length,
                          })}
                    </Button>
                  )}
                </>
              )}
            </>
          )}

          {mode === DialogMode.Delete && (
            <Button onClick={handleConfirmDelete} color="primary">
              {t("Delete")}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <ImportDialog
        open={importDialogOpen}
        onClose={handleImportDialogClose}
        onSuccess={handleImport}
      />

      <ConnectDriveDialog
        open={connectDriveDialogOpen}
        onClose={handleConnectDriveDialogClose}
        onSuccess={handleConnectDrive}
      />
    </>
  );
}

export default InitiateDocumentsDialog;
