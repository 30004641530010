import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { blue, grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import {
  Box,
  Chip as MuiChip,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  StepButton,
  Typography,
  useMediaQuery,
  useTheme,
  Menu,
  ListItemSecondaryAction,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  Fade,
  Alert,
  AlertTitle,
} from "@mui/material";
import {
  AutoAwesome,
  CheckCircleOutlined,
  FileDownloadOutlined,
  FileUploadOutlined,
  InsertDriveFileOutlined,
  MoreHoriz as MoreHorizIcon,
} from "@mui/icons-material";
import { FileUploader } from "react-drag-drop-files";
import { DialogMode } from "../../../../types/dialogmode";
import { IContract } from "../../../../types/contract";
import { IDocument } from "../../../../types/document";
import CustomNoRowsOverlay from "../../../../components/datagrids/CustomNoRowsOverlay";
import { spacing } from "@mui/system";
import FileAsListItem from "../../../../components/lists/listitems/FileAsListItem";
import {
  useAddDocumentContentMutation,
  useAddDocumentMutation,
  useDeleteDocumentMutation,
  useGetAttachmentsQuery,
  useGetDocumentsQuery,
  useGetHumanDataVerificationsQuery,
} from "../../../../redux/slices/indexApiSlice";
import { useParams } from "react-router-dom";
import FilesToUploadList from "../../../../components/lists/FilesToUploadList";
import { IVendor } from "../../../../types/vendor";
import { set } from "date-fns";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { ContractExtractionResult } from "../../../../types/aiOutput";
import VerifiedAISuggestion from "./VerifiedAISuggestion";
import AISuggestion from "./AISuggestion";

const UploadButton = styled(MuiPaper)`
  height: 144px;
  border: 1px dashed ${(props) => props.theme.palette.divider};
  padding: 24px;
  cursor: pointer;
  &:hover {
    border: 1px dashed ${(props) => props.theme.palette.primary.main};
  }
`;

interface IContractJurisdictionProps {
  contract: IContract;
  aiOutput?: ContractExtractionResult;
  hideTitle?: boolean;
}
function ContractJurisdiction({ ...props }: IContractJurisdictionProps) {
  const { contract } = props;
  const { t } = useTranslation();

  const { data: verifications } = useGetHumanDataVerificationsQuery(contract);

  const isVerified = contract?.countryOfJurisdiction ? true : false;
  const isAiSuggestion = props.aiOutput?.metadata?.structuralData?.procurement
    ?.countryOfJurisdiction
    ? true
    : false;

  const verificationRecord = verifications?.find(
    (v) => v.field === "countryOfJurisdiction"
  );

  return (
    <Box pb={2}>
      {!props.hideTitle && (
        <Typography variant="h6" mb={2}>
          {t("Country of jurisdiction")}
        </Typography>
      )}

      <VerifiedAISuggestion
        isVerified={isVerified}
        content={
          <Typography variant="body2">
            {contract?.countryOfJurisdiction}
          </Typography>
        }
        verificationRecord={verificationRecord}
      />

      {props.aiOutput?.metadata?.structuralData?.countryOfJurisdiction && (
        <AISuggestion
          contract={contract}
          isAiSuggestion={isAiSuggestion}
          isVerified={isVerified || verificationRecord?.isCorrect !== undefined}
          field="countryOfJurisdiction"
          value={
            props.aiOutput?.metadata?.structuralData?.countryOfJurisdiction
          }
          content={
            <Typography variant="body2">
              {props.aiOutput?.metadata?.structuralData?.countryOfJurisdiction}
            </Typography>
          }
          processedAt={props.aiOutput?.processedAt}
        />
      )}
    </Box>
  );
}

export default ContractJurisdiction;
