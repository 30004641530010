import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { blue, grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import {
  Box,
  Chip as MuiChip,
  Icon,
  LinearProgress,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  StepButton,
  Typography,
  useMediaQuery,
  useTheme,
  Menu,
  ListItemSecondaryAction,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  Fade,
  Alert,
  AlertTitle,
  IconButton,
  Button,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  AccordionProps,
  AccordionSummaryProps,
  accordionSummaryClasses,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import { IContract } from "../../../../types/contract";

import OwlAvatarIcon from "../../../../icons/OwlAvatarIcon";
import { NumericFormat } from "react-number-format";
import { ContractExtractionResult } from "../../../../types/aiOutput";
import {
  ArrowDownward,
  ArrowForwardIosSharp,
  Check,
  HowToReg,
  ThumbDown,
  ThumbUp,
  ThumbUpOffAlt,
  Verified,
} from "@mui/icons-material";
import { ThumbsUp } from "react-feather";
import {
  useGetHumanDataVerificationsQuery,
  useUpdateContractMutation,
} from "../../../../redux/slices/indexApiSlice";
import AISuggestion from "./AISuggestion";
import VerifiedAISuggestion from "./VerifiedAISuggestion";

interface IContractFinancialsProps {
  contract: IContract;
  aiOutput?: ContractExtractionResult;
  hideTitle?: boolean;
}

const FinancialsCard = ({
  currency,
  amount,
  paymentTerms,
  explanation,
}: {
  currency?: string;
  amount?: number;
  paymentTerms?: string;
  explanation?: string;
}) => {
  const { t } = useTranslation();

  return (
    <Grid>
      <Grid container alignContent="center">
        {currency && amount ? (
          new Intl.NumberFormat(navigator.language, {
            style: "currency",
            currency: currency,
            compactDisplay: "long",
            maximumFractionDigits: 0,
          }).format(amount)
        ) : (
          <NumericFormat
            value={amount}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"? "}
          />
        )}
      </Grid>

      {paymentTerms && (
        <Typography pt={1} variant="body1">
          {paymentTerms}
        </Typography>
      )}

      {explanation && (
        <Alert
          sx={{ mt: 2 }}
          severity="info"
          variant="outlined"
          icon={<OwlAvatarIcon />}
        >
          <Typography pt={1} variant="body1">
            {explanation}
          </Typography>
        </Alert>
      )}
    </Grid>
  );
};

function ContractFinancials({ ...props }: IContractFinancialsProps) {
  const { contract } = props;
  const { t } = useTranslation();

  const { data: verifications } = useGetHumanDataVerificationsQuery(contract);

  const verificationRecord = verifications?.find((v) => v.field === "budget");

  // if the contract object has a budget or explanations.financials, it is verified
  // everything under aiOutput.metadata.structuralData?.financials is an AI suggestion
  const isVerified =
    contract?.budget || contract?.explanations?.financials ? true : false;

  const isAiSuggestion = props.aiOutput?.metadata?.structuralData?.financials
    ? true
    : false;

  return (
    <Grid container pb={2} gap={4}>
      {!props.hideTitle && (
        <Typography variant="h6" mb={2}>
          {t("Value")}
        </Typography>
      )}

      <VerifiedAISuggestion
        isVerified={isVerified}
        verificationRecord={verificationRecord}
        content={
          <FinancialsCard
            currency={contract?.budget?.currency}
            amount={contract?.budget?.amount}
            paymentTerms={contract?.budget?.paymentTerms}
            explanation={contract?.explanations?.financials}
          />
        }
      />

      <AISuggestion
        contract={contract}
        isAiSuggestion={isAiSuggestion}
        isVerified={isVerified || verificationRecord?.isCorrect !== undefined}
        field="budget"
        value={{
          amount:
            props.aiOutput?.metadata?.structuralData?.financials?.totalValue,
          currency:
            props.aiOutput?.metadata?.structuralData?.financials?.currency,
          paymentTerms:
            props.aiOutput?.metadata?.structuralData?.financials?.paymentTerms,
        }}
        content={
          <FinancialsCard
            currency={
              props.aiOutput?.metadata?.structuralData?.financials?.currency
            }
            amount={
              props.aiOutput?.metadata?.structuralData?.financials?.totalValue
            }
            paymentTerms={
              props.aiOutput?.metadata?.structuralData?.financials?.paymentTerms
            }
            explanation={
              props.aiOutput?.metadata?.structuralData?.financials?.explanation
            }
          />
        }
      />
    </Grid>
  );
}

export default ContractFinancials;
