import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Avatar,
  Box,
  Button,
  Fade,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Toolbar,
  Tooltip,
  Typography as MuiTypography,
  CardActions,
  CardMedia as MuiCardMedia,
  Chip as MuiChip,
  CardActionArea,
  useTheme,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  Description,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Delete as DeleteIcon,
  Cancel,
  GridView,
  TableView,
  PropaneSharp,
} from "@mui/icons-material";

import { SpacingProps, alpha, spacing } from "@mui/system";

import { AvatarGroup as MuiAvatarGroup } from "@mui/material";
import { Check } from "react-feather";

import { useTranslation } from "react-i18next";
import { GridToolbarExport } from "@mui/x-data-grid";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

interface TypographyProps extends SpacingProps {
  component?: string;
}
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
`;

const CardMedia = styled(MuiCardMedia)`
  height: 220px;
`;

const Chip = styled(MuiChip)<{ color?: string }>`
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
  margin-bottom: ${(props) => props.theme.spacing(4)};
`;

const AvatarGroup = styled(MuiAvatarGroup)`
  margin-left: ${(props) => props.theme.spacing(2)};
`;

type EnhancedDataGridToolbarProps = {
  rowSelectionModel?: any;
  handleDeleteClick?: any;
  view: "cards" | "table";
  setView?: (view: "cards" | "table") => void;
  title: string;
  deleting?: boolean;
  isLoading: boolean;
  customActions?: React.ReactNode[];
};

const EnhancedDataGridToolbar = (props: EnhancedDataGridToolbarProps) => {
  const { t } = useTranslation();

  const { rowSelectionModel } = props;

  const numSelected = rowSelectionModel?.length;

  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleConfirmDelete = async () => {
    // Call the delete function to delete the item
    await props.handleDeleteClick();

    // Hide the confirmation
    setShowConfirmation(false);
  };

  const handleCancelDelete = () => {
    // Hide the confirmation
    setShowConfirmation(false);
  };

  return (
    <Toolbar>
      <ToolbarTitle style={{ width: "fit-content" }}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="body1" noWrap>
            {t("selected", {
              count: numSelected,
            })}
          </Typography>
        ) : (
          <Typography variant="body1" id="tableTitle" noWrap overflow="visible">
            {props.title}
          </Typography>
        )}
      </ToolbarTitle>

      <Grid container justifyContent="space-between">
        <Grid>{props.customActions?.map((action) => action)}</Grid>

        <Grid>
          <Spacer />
        </Grid>

        <Grid>
          <Grid container>
            <Grid>
              {numSelected > 0 && (
                <>
                  {showConfirmation ? (
                    <Fade in={showConfirmation}>
                      <Box>
                        <Typography component="span" variant="body1">
                          {t("Are you sure?")}
                        </Typography>
                        <IconButton
                          aria-label="Cancel"
                          size="small"
                          onClick={handleCancelDelete}
                        >
                          <Cancel />
                        </IconButton>
                        <IconButton
                          aria-label="Confirm"
                          size="small"
                          onClick={handleConfirmDelete}
                        >
                          <Check />
                        </IconButton>
                      </Box>
                    </Fade>
                  ) : (
                    props.handleDeleteClick && (
                      <Tooltip title="Delete">
                        <Button
                          aria-label="Delete"
                          size="medium"
                          startIcon={<DeleteIcon />}
                          onClick={() => setShowConfirmation(true)}
                          disabled={props.deleting || props.isLoading}
                        >
                          {t("DeleteSingular")} {numSelected}{" "}
                          {numSelected === 1 ? t("item") : t("items")}
                        </Button>
                      </Tooltip>
                    )
                  )}
                </>
              )}
            </Grid>

            {props.setView && (
              <Grid>
                <Tooltip title={t("Switch to card view")}>
                  <IconButton
                    onClick={() => {
                      if (props.setView) {
                        props.setView("cards");
                      }
                    }}
                    color={props.view === "cards" ? "primary" : "default"}
                  >
                    <GridView />
                  </IconButton>
                </Tooltip>

                <Tooltip title={t("Switch to table view")}>
                  <IconButton
                    onClick={() => {
                      if (props.setView) {
                        props.setView("table");
                      }
                    }}
                    color={props.view === "table" ? "primary" : "default"}
                  >
                    <TableView />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <GridToolbarExport />
    </Toolbar>
  );
};

export default EnhancedDataGridToolbar;
