import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { blue, grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import {
  Box,
  Chip as MuiChip,
  Icon,
  LinearProgress,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  StepButton,
  Typography,
  useMediaQuery,
  useTheme,
  Menu,
  ListItemSecondaryAction,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  Fade,
  Alert,
  AlertTitle,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  AutoAwesome,
  CheckCircleOutlined,
  FileDownloadOutlined,
  FileUploadOutlined,
  InsertDriveFileOutlined,
  MoreHoriz as MoreHorizIcon,
} from "@mui/icons-material";
import { FileUploader } from "react-drag-drop-files";
import { DialogMode } from "../../../../types/dialogmode";
import { IContract } from "../../../../types/contract";
import { IDocument } from "../../../../types/document";
import CustomNoRowsOverlay from "../../../../components/datagrids/CustomNoRowsOverlay";
import { spacing } from "@mui/system";
import FileAsListItem from "../../../../components/lists/listitems/FileAsListItem";
import {
  useAddDocumentContentMutation,
  useAddDocumentMutation,
  useAddHumanDataVerificationMutation,
  useDeleteDocumentMutation,
  useGetAttachmentsQuery,
  useGetDocumentsQuery,
  useGetHumanDataVerificationsQuery,
  useUpdateContractMutation,
} from "../../../../redux/slices/indexApiSlice";
import { useParams } from "react-router-dom";
import FilesToUploadList from "../../../../components/lists/FilesToUploadList";
import { IVendor } from "../../../../types/vendor";
import { set } from "date-fns";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import OwlAvatarIcon from "../../../../icons/OwlAvatarIcon";
import { ContractExtractionResult } from "../../../../types/aiOutput";
import VerifiedAISuggestion from "./VerifiedAISuggestion";
import AISuggestion from "./AISuggestion";
import useAuth from "../../../../hooks/useAuth";

const UploadButton = styled(MuiPaper)`
  height: 144px;
  border: 1px dashed ${(props) => props.theme.palette.divider};
  padding: 24px;
  cursor: pointer;
  &:hover {
    border: 1px dashed ${(props) => props.theme.palette.primary.main};
  }
`;

interface IContractNoticePeriodProps {
  contract: IContract;
  aiOutput?: ContractExtractionResult;
  hideTitle?: boolean;
}
function ContractNoticePeriod({ ...props }: IContractNoticePeriodProps) {
  const { contract } = props;
  const { t } = useTranslation();
  const { user } = useAuth();

  const [updateContract] = useUpdateContractMutation();
  const [addVerification] = useAddHumanDataVerificationMutation();
  const { data: verifications } = useGetHumanDataVerificationsQuery(contract);

  const confirmAiSuggestion = async () => {
    if (!contract) return;

    const updatedContract: IContract = {
      ...contract,
      noticePeriod:
        props.aiOutput?.metadata?.structuralData?.dates.noticePeriod,
    };

    addVerification({
      contractId: contract.id,
      field: "noticePeriod",
      verifiedBy: user.displayName,
      verifiedDate: new Date().toUTCString(),
    });

    await updateContract(updatedContract);
  };

  const isVerified =
    contract?.noticePeriod?.unit && contract?.noticePeriod?.value
      ? true
      : false;

  const isAiSuggestion = props.aiOutput?.metadata?.structuralData?.dates
    ?.noticePeriod
    ? true
    : false;

  const verificationRecord = verifications?.find(
    (v) => v.field === "noticePeriod"
  );

  return (
    <Box pb={2}>
      {!props.hideTitle && (
        <Typography variant="h6" mb={2}>
          {t("Notice period")}
        </Typography>
      )}

      <VerifiedAISuggestion
        isVerified={isVerified}
        content={
          <Typography variant="body1">
            {contract.noticePeriod
              ? `${contract.noticePeriod?.value} ${t(
                  contract.noticePeriod?.unit
                )}`
              : ""}
          </Typography>
        }
        verificationRecord={verificationRecord}
      />

      {props.aiOutput?.metadata?.structuralData?.noticePeriod && (
        <AISuggestion
          contract={contract}
          isAiSuggestion={isAiSuggestion}
          isVerified={isVerified}
          field="noticePeriod"
          value={props.aiOutput?.metadata?.structuralData?.noticePeriod}
          content={
            <Grid size={12}>
              {props.aiOutput?.metadata?.structuralData.noticePeriod && (
                <Typography variant="body1">
                  {props.aiOutput?.metadata?.structuralData.noticePeriod?.value}{" "}
                  {t(
                    props.aiOutput?.metadata?.structuralData.noticePeriod?.unit
                  )}
                </Typography>
              )}

              <Typography pt={1} variant="body1">
                {
                  props.aiOutput?.metadata?.structuralData?.noticePeriod
                    ?.explanation
                }
              </Typography>
            </Grid>
          }
          processedAt={props.aiOutput?.processedAt}
        />
      )}

      {props.aiOutput?.metadata?.structuralData?.dates?.noticePeriod && (
        <AISuggestion
          contract={contract}
          isAiSuggestion={isAiSuggestion}
          isVerified={isVerified || verificationRecord?.isCorrect !== undefined}
          field="noticePeriod"
          value={props.aiOutput?.metadata?.structuralData?.dates?.noticePeriod}
          content={
            <Grid size={12}>
              {props.aiOutput?.metadata?.structuralData.dates?.noticePeriod && (
                <Typography variant="body1">
                  {
                    props.aiOutput?.metadata?.structuralData.dates?.noticePeriod
                      ?.value
                  }{" "}
                  {t(
                    props.aiOutput?.metadata?.structuralData.dates?.noticePeriod
                      ?.unit
                  )}
                </Typography>
              )}

              <Typography pt={1} variant="body1">
                {
                  props.aiOutput?.metadata?.structuralData?.dates?.noticePeriod
                    ?.explanation
                }
              </Typography>
            </Grid>
          }
          processedAt={props.aiOutput?.processedAt}
        />
      )}
    </Box>
  );
}

export default ContractNoticePeriod;
