import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { blue, grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import {
  Box,
  Chip as MuiChip,
  Icon,
  LinearProgress,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  StepButton,
  Typography,
  useMediaQuery,
  useTheme,
  Menu,
  ListItemSecondaryAction,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  Fade,
  Alert,
  AlertTitle,
  IconButton,
  Button,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  AccordionProps,
  AccordionSummaryProps,
  accordionSummaryClasses,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import { IContract } from "../../../../types/contract";

import OwlAvatarIcon from "../../../../icons/OwlAvatarIcon";
import { NumericFormat } from "react-number-format";
import { ContractExtractionResult } from "../../../../types/aiOutput";
import {
  ArrowDownward,
  ArrowForwardIosSharp,
  Check,
  HowToReg,
  ThumbDown,
  ThumbUp,
  ThumbUpOffAlt,
  Verified,
} from "@mui/icons-material";
import { ThumbsUp } from "react-feather";
import {
  useAddHumanDataVerificationMutation,
  useUpdateContractMutation,
} from "../../../../redux/slices/indexApiSlice";
import useAuth from "../../../../hooks/useAuth";
import { IPrompt } from "../../../../types/prompt";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  // border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  // backgroundColor: "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
    {
      transform: "rotate(90deg)",
    },
  [`& .${accordionSummaryClasses.content}`]: {
    marginLeft: theme.spacing(1),
  },
  ...theme.applyStyles("dark", {
    backgroundColor: "rgba(255, 255, 255, .05)",
  }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
  // borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function ProvideCommentsDialog({
  open,
  onClose,
  onConfirm,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: (comments: string) => void;
}) {
  const { t } = useTranslation();

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        component: "form",
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries((formData as any).entries());

          onConfirm(formJson.comments);

          onClose();
        },
      }}
    >
      <DialogTitle>{t("AI is incorrect")}</DialogTitle>

      <DialogContent>
        <TextField
          autoFocus
          required
          id="comments"
          name="comments"
          label={t("Comments")}
          multiline
          fullWidth
          rows={8}
          sx={{ my: 2 }}
        />
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => onClose()}
          color="primary"
          autoFocus
          variant="outlined"
        >
          {t("Cancel")}
        </Button>

        <Button type="submit" variant="contained" color="primary">
          {t("Save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function AISuggestion({
  contract,
  isVerified,
  isAiSuggestion,
  content,
  processedAt,
  field,
  value,
  field2,
  value2,
  prompt,
}: {
  contract: IContract;
  isVerified: boolean;
  isAiSuggestion: boolean;
  content: React.ReactNode;
  processedAt?: string;
  field: string;

  value: any;
  field2?: string;
  value2?: any;
  prompt?: IPrompt;
}) {
  const { t } = useTranslation();
  const { user } = useAuth();

  const [openCommentsDialog, setOpenCommentsDialog] = useState(false);

  const [updateContract] = useUpdateContractMutation();
  const [addVerification] = useAddHumanDataVerificationMutation();

  const confirmAiSuggestion = async () => {
    if (!contract) return;

    if (field === "prompts") {
      const newPrompts = contract.prompts ? [...contract.prompts] : [];
      newPrompts.push(value);

      const updatedContract: IContract = {
        ...contract,
        prompts: newPrompts,
      };

      await updateContract(updatedContract);

      await addVerification({
        contractId: contract.id,
        field: prompt?.id || "prompts",
        isCorrect: true,
        verifiedBy: user.displayName,
        verifiedDate: new Date().toUTCString(),
      });
    } else {
      const updatedContract: IContract = {
        ...contract,
        [field]: value,
      };

      if (field2) {
        (updatedContract as any)[field2] = value2;
      }

      await updateContract(updatedContract);

      await addVerification({
        contractId: contract.id,
        field: field,
        isCorrect: true,
        verifiedBy: user.displayName,
        verifiedDate: new Date().toUTCString(),
      });
    }
  };

  const declineAiSuggestion = async (comments: string) => {
    if (field === "prompts") {
      await addVerification({
        contractId: contract.id,
        field: prompt?.id || "prompts",
        comments: comments,
        isCorrect: false,
        verifiedBy: user.displayName,
        verifiedDate: new Date().toUTCString(),
      });
    } else {
      addVerification({
        contractId: contract.id,
        field: field,
        comments: comments,
        isCorrect: false,
        verifiedBy: user.displayName,
        verifiedDate: new Date().toUTCString(),
      });
    }
  };

  return (
    <>
      {isVerified ? (
        <Grid size={12}>
          <Accordion>
            <AccordionSummary>
              <Typography variant="body1">{t("Show AI suggestion")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Alert
                sx={{ mt: 2 }}
                severity={isVerified && !isAiSuggestion ? "success" : "info"}
                variant="outlined"
                icon={<OwlAvatarIcon />}
              >
                {content}

                {processedAt && (
                  <Typography variant="caption" color="textDisabled">
                    {t("Processed at", {
                      date: new Date(processedAt),
                    })}
                  </Typography>
                )}
              </Alert>
            </AccordionDetails>
          </Accordion>
        </Grid>
      ) : (
        <Grid size={12}>
          <Alert
            sx={{ mt: 2 }}
            severity={isVerified && !isAiSuggestion ? "success" : "info"}
            variant="outlined"
            icon={<OwlAvatarIcon />}
          >
            {content}

            {isAiSuggestion && !isVerified && (
              <Grid
                container
                pt={2}
                direction="row"
                justifyContent="center"
                alignContent={"center"}
                gap={2}
              >
                <Button startIcon={<ThumbUp />} onClick={confirmAiSuggestion}>
                  {t("This is correct")}
                </Button>
                <Button
                  startIcon={<ThumbDown />}
                  onClick={() => setOpenCommentsDialog(true)}
                >
                  {t("This is incorrect")}
                </Button>
              </Grid>
            )}
          </Alert>
        </Grid>
      )}

      <ProvideCommentsDialog
        open={openCommentsDialog}
        onClose={() => setOpenCommentsDialog(false)}
        onConfirm={(comments) => {
          setOpenCommentsDialog(false);
          declineAiSuggestion(comments);
        }}
      />
    </>
  );
}

export default AISuggestion;
