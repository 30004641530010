export const dutch = {
  all_user: "Alle gebruikers",
  date: {
    relative: "{{ date, relative }}",
    dateOnly: "{{ date, PPPP }}", // dateOnly
    dateTime: "{{ date, PPPPp }}", // dateTime
    dateTimeAndTimeZone: "{{ date, PPPPp zzzz }}", // dateTimeAndTimeZone
    ago: "{{ date, ago }}", // ago
  },
  expires_on: "verloopt op",
  notification_period_date_text:
    "De opzegtermijn bedraagt ​​{{value}} {{unit}}, dus onderneem actie vóór {{beforeDate}}.",
  notification_no_period_date_text: "Onderneem actie vóór {{endDate}}.",
  go_to_contract: "Ga naar contract",
  Search: "Zoeken…",
  "Welcome back": "Welkom terug.",
  "We've missed you": "We hebben je gemist",
  "Sign in to your account to continue": "Log in om door te gaan",
  reminder_date_text_me: "Je ontvangt een herinnering op {{ date, PPPP }}.",
  reminder_date_text_single:
    "{{displayNames}} ontvangt een herinnering op {{ date, PPPP }}.",
  reminder_date_text_multi:
    "{{displayNames}} ontvangen een herinnering op {{ date, PPPP }}.",
  reminder_date_text_past_due_me: "Je herinnering was op {{ date, PPPP }}.",
  reminder_date_text_past_due_single:
    "De herinnering voor {{displayNames}} was op {{ date, PPPP }}.",
  reminder_date_text_past_due_multi:
    "De herinnering voor {{displayNames}} was op {{ date, PPPP }}.",
  and: "en",
  You: "Jij",
  you: "jou",
  pages: "Pagina {{current}} van {{total}}",

  Reminders: "Taken",
  "Add reminder": "Toevoegen taak",
  "Edit reminder": "Taak bewerken",
  "Delete reminder": "Taak verwijderen",

  "Go back": "Ga terug",
  "Sign in": "Inloggen",
  "Sign out": "Uitloggen",
  "Forgot password": "Wachtwoord vergeten",
  "Email Address": "E-mailadres",
  Password: "Wachtwoord",
  "Email is required": "E-mailadres is verplicht",
  "Password is required": "Wachtwoord is verplicht",
  "Remember me": "Onthoud mij",
  "Continue with Microsoft 365 Account": "Doorgaan met Microsoft 365",
  "Continue with Google Account": "Doorgaan met Google",
  "Continue with email": "Doorgaan met e-mail",
  "Don't have an account?": "Heb je geen account?",
  "Sign up": "Aanmelden",

  New: "Nieuw",
  "Coming soon": "Binnenkort beschikbaar",

  "Home page": "Startpagina",
  Dashboard: "Dashboard",
  "Connecting to server": "Verbinden maken met server...",

  "Switch to dark mode": "Schakel over naar donkere modus",
  "Switch to light mode": "Schakel over naar lichte modus",

  // DataGrid
  selected: "{{count}} geselecteerd",
  "Switch to card view": "Toon kaartweergave",
  "Switch to table view": "Toon tabelweergave",
  of: "van",
  "Are you sure?": "Weet je het zeker?",
  DeleteSingular: "Verwijder",
  item: "item",
  items: "items",

  // Timeline
  Timeline: "Tijdlijn (in ontwikkeling)",

  "Analyzing document using GoBeyondProcurement AI":
    "Document wordt geanalyseerd door GoBeyondProcurement",
  Analyzing: "Analyseren",
  Success: "Gelukt!",
  "Document was analyzed successfully!": "Document is succesvol geanalyseerd!",

  "Reading document": "Document wordt ingelezen",
  Reading: "Inlezen ({{percentage}}%)",
  "Reading success": "Document is succesvol ingelezen!",
  "Reading done": "Inlezen voltooid",
  "Reading not done": "Inlezen niet voltooid",
  Stop: "Stop",
  Stopped: "Gestopt",
  "Analysis stopped": "Analyse gestopt.",
  "Reading failed": "Inlezen mislukt.",
  Read: "Ingelezen",

  // Forms
  "Your data has been submitted successfully!":
    "Je gegevens zijn succesvol opgeslagen!",
  "An error occurred!": "Er is een fout opgetreden!",
  "Select People": "Selecteer personen",
  Submit: "Verzenden",
  "Required field": "Verplicht veld",
  Required: "Dit is een verplicht veld",
  Add: "Toevoegen",
  "Notice period must be a positive number":
    "Opzegtermijn moet een positief getal zijn",
  "Price must be a positive number": "Prijs moet een positief getal zijn",
  "End date must be after the start date":
    "Einddatum moet na de startdatum zijn",

  Contracts: "Contracten",
  "1 contract needs your attention": "1 contract heeft uw aandacht nodig",
  "X contracts need your attention":
    "{{count}} contracten hebben uw aandacht nodig",
  "1 contract includes this clause":
    "1 contract zegt iets m.b.t. deze bepaling",
  "X contracts include this clause":
    "{{count}} contract(en) zeggen iets m.b.t. deze bepaling",
  "Contract Information": "Contractgegevens",
  "Managed Contracts": "Contractregisseur",
  "Use AI to create a new contract":
    "Gebruik AI om een nieuw contract op te stellen",
  "New contract": "Nieuw contract",
  Create: "Creëer",
  "a new contract": "een nieuw contract",
  Start: "Start",
  "with existing contract": "met bestaand contract",
  "Upload or import a contract": "Upload of importeer bestaande contracten",
  "Create contract with AI": "Creëer een nieuw contract met AI",
  "Edit contract": "Bewerk contract",
  "Delete contract": "Verwijder contract",
  "No contracts": "Geen contracten",
  "Contract Owner": "Eigenaar",
  Summary: "Samenvatting",
  remaining: "resterend",
  "Are you sure you want to delete this contract?":
    "Weet je zeker dat je dit contract wilt verwijderen?",
  "Show more": "Toon meer",
  "Show less": "Toon minder",
  "assigned the contract to": "heeft het contract toegewezen aan",
  "Contract updated by": "Contract bijgewerkt door",
  "updated the contract": "heeft het contract bijgewerkt",
  "was updated to": "is bijgewerkt naar",
  Organization: "Organisatie",
  "Notice period": "Opzegtermijn",
  "Latest termination date": "Uiterlijke opzegdatum",
  Renew: "Verleng",
  "before latest termination date is reached": "voor de uiterlijke opzegdatum",
  Terminate: "Beëindig",
  "Contract will be securely saved": "Het contract wordt veilig opgeslagen",
  "No end date": "Geen einddatum",
  "No notice period": "Geen opzegtermijn",
  "When does the contract expire?": "Wanneer verloopt het contract?",
  Total: "Totaal",
  Percentage: "Percentage",
  "Country of jurisdiction": "Land van jurisdictie",

  Documents: "Documenten",
  "New document": "Nieuw document",
  "Delete document": "Verwijder document",
  "Upload a new document": "Upload een nieuw document.",
  "a new document": "een nieuw document",
  "from Microsoft 365": "uit Microsoft 365",
  "Select a file": "Selecteer een bestand",
  "Select an image": "Selecteer een afbeelding",
  "Artificial Intelligence": "Kunstmatige intelligentie (AI)",
  File: "Bestand",
  Versions: "Versies",
  Version: "Versie",
  "Document information": "Documentinformatie",
  Origin: "Oorsprong",
  Location: "Locatie",
  "Stored in Azure": "Opgeslagen in Azure",
  "Stored in SharePoint": "Opgeslagen in SharePoint",
  Size: "Bestandsgrootte",
  "Document was uploaded manually": "Document is handmatig geüpload",
  "File name": "Bestandsnaam",
  Modified: "Gewijzigd",
  "View in SharePoint": "Bekijk in SharePoint",

  "You are viewing and older version of this document.":
    "Je bekijkt een oudere versie van dit document.",
  "You are viewing the latest version of this document.":
    "Je bekijkt de laatste versie van dit document.",
  Changes: "Wijzigingen",
  "The AI service has found the following information":
    "De AI dienst heeft de volgende informatie gevonden.",
  "Does this information look correct?": "Is deze informatie correct?",
  "Yes, save": "Ja, opslaan",
  "No, remove response": "Nee, antwoord verwijderen",
  "When requesting a change we will kindly ask you to provide more details about the contract so we can send this to the AI.":
    "Wanneer je een wijziging aanvraagt vragen we je vriendelijk om meer details over het contract te verstrekken, zodat we dit naar de AI kunnen sturen.",

  // Assignees
  Assignees: "Toegewezen aan",
  "Contract has not been assigned": "Contract is nog niet toegewezen",
  "Edit Assignee(s)": "Bewerk toewijzing contract",
  "Select the assignee(s) of the contract. Assignees will receive an email notification.":
    "Selecteer de toegewezen personen van het contract. Toegewezen personen ontvangen een emailnotificatie.",
  "Contract has no owner": "Contract heeft nog geen eigenaar",

  Name: "Naam",
  Vendor: "Leverancier",
  Purchaser: "Inkoper",
  Customer: "Klant",
  Category: "Categorie",
  "Start date": "Startdatum",
  "End date": "Einddatum",
  "Signature date": "Ondertekeningsdatum",
  Value: "Contractwaarde",
  Value_placeholder: "Waarde in EUR. Vul een bedrag in",
  Administrator: "Beheerder",
  Owner: "Eigenaar",
  Manager: "Manager",
  Action: "Actie",
  Subject: "Onderwerp",
  Parties: "Partijen",
  Financials: "Financiën",
  "Received from": "Ontvangen van",

  "sales agreement": "Verkoopovereenkomst",
  "service agreement": "Serviceovereenkomst",
  "employment contract": "Arbeidscontract",
  "rental agreement": "Huurovereenkomst",
  "loan agreement": "Leenovereenkomst",
  other: "Anders",

  Roles: "Rollen",
  Users: "Gebruikers",
  Reader: "Lezer",
  "Edit administrator": "Beheerder wijzigen",
  "Edit owner": "Eigenaar wijzigen",
  "Edit manager": "Manager wijzigen",
  "Edit reader": "Lezer wijzigen",
  "Assign administrator": "Beheerder toewijzen",
  "Assign owner": "Eigenaar toewijzen",
  "Assign manager": "Manager toewijzen",
  "Assign reader": "Lezer toewijzen",
  "First name": "Voornaam",
  "Last name": "Achternaam",
  "Job title": "Functie",
  Email: "E-mail",

  "Add user": "Persoon toevoegen",
  "Contact persons": "Contactpersonen",

  Vendors: "Leveranciers",
  "Vendor Information": "Leveranciersgegevens",
  "New vendor": "Nieuwe leverancier",
  "Edit vendor": "Bewerk leverancier",
  "Delete vendor": "Verwijder leverancier",
  "No vendors": "Geen leveranciers",
  "No vendor": "Geen leverancier",
  "Select Vendor": "Selecteer leverancier",
  "Select the vendor of the contract":
    "Selecteer de leverancier van het contract.",
  "Select the owner of the contract. The owner is the person who is responsible for the contract":
    "Selecteer de eigenaar van het contract. De eigenaar is de persoon die verantwoordelijk is voor het contract.",
  "Potential Savings": "Potentiële besparing",
  "Active Contracts": "Actieve contracten",
  "Total Contracts Value": "Totale contractwaarde",
  Logo: "Logo",
  Address: "Adres",
  Street: "Straat",
  "House number": "Huisnummer",
  "Postal code": "Postcode",
  City: "Plaats",
  Country: "Land",
  Phone: "Telefoonnummer",
  Website: "Website",
  "Import from Excel": "Importeren vanuit Excel",
  "Import vendors from XLSX or CSV file":
    "Leveranciers importeren vanuit XLSX of CSV bestand",
  "Download template": "Download sjabloon",
  record: "regel",
  records: "regels",
  "Synchronize company details with KVK":
    "Bedrijfsgegevens synchroniseren met KVK",
  "Are you sure you want to delete this vendor?":
    "Weet je zeker dat je deze leverancier wilt verwijderen?",
  Create1Vendor: "1 leverancier toevoegen",
  CreateXVendors: "{{count}} leveranciers toevoegen",

  Messages: "Berichten",
  Message: "Bericht",
  "New message": "Nieuw bericht",
  "Edit message": "Bewerk bericht",
  "Delete message": "Verwijder bericht",
  "No messages": "Geen berichten",
  "Messages are visible to everyone": "Berichten zijn voor iedereen zichtbaar",
  "Are you sure you want to delete this message?":
    "Weet je zeker dat je dit bericht wilt verwijderen?",

  Notes: "Taken",
  Note: "Taak",
  "New note": "Nieuwe taak",
  "Add note": "Taak toevoegen",
  "Edit note": "Bewerk taak",
  "Delete note": "Verwijder taak",
  "No notes": "Geen taken",
  "Notes are visible to everyone": "Taken zijn voor iedereen zichtbaar",
  "Are you sure you want to delete this note?":
    "Weet je zeker dat je deze taak wilt verwijderen?",
  "Are you sure you want to delete this reminder?":
    "Weet je zeker dat je deze herinnering wilt verwijderen?",
  "Due date": "Einddatum",

  // KPI's
  "KPI's": "KPI's",
  "New KPI": "Nieuwe KPI",
  "Edit KPI": "Bewerk KPI",
  "Delete KPI": "Verwijder KPI",
  "No KPI's": "Geen KPI's",
  Score: "Score",
  Comments: "Opmerkingen",
  "Choose from library": "Kies uit bibliotheek",

  // Prompts
  "Add prompt": "Prompt toevoegen",
  "Edit prompt": "Bewerk prompt",
  "Delete prompt": "Verwijder prompt",
  Prompts: "Prompts",
  "Are you sure you want to delete this prompt?":
    "Weet je zeker dat je deze prompt wilt verwijderen?",
  "Search in prompt library": "Zoek in bibliotheek",
  "Unique key": "Unieke sleutel",
  Title: "Titel",

  // Feedback
  Risks: "Risico's",
  "New risk": "Nieuw risico",
  "No risks": "Geen risico's",
  Description: "Omschrijving",
  Categories: "Categorieën",
  "Select a category": "Selecteer een categorie",
  Risk: "Risico",
  Goal: "Doel",
  Legal: "Juridisch",
  Recommendation: "Aanbeveling",
  Layout: "Vormgeving",
  Recommendations: "Aanbevelingen",

  // Connections
  "New connection": "Nieuwe verbinding",
  "Are you sure you want to delete this connection?":
    "Weet je zeker dat je deze verbinding wilt verwijderen?",
  "Select Site": "Selecteer site",
  "Select Drive": "Selecteer locatie",
  "View files": "Bekijk {{count}} bestand(en)",
  Import: "Importeer",
  Import_applications: "Teams, SharePoint, OneDrive en Outlook",
  "View all": "Bekijk alle",
  "messages with attachments found":
    "{{count}} berichten met bijlagen gevonden.",

  // Calendar
  Calendar: "Kalender",
  expires: "verloopt",
  expired: "verlopen",
  "Already expired": "Reeds verlopen",
  "expires in": "verloopt over",
  day: "day",
  days: "dagen",
  days_single: "dag",
  days_multiple: "dagen",
  Days: "Dagen",
  week: "week",
  weeks: "weken",
  Weeks: "Weken",
  month: "maand",
  months: "maanden",
  Months: "Maanden",
  quarter: "kwartaal",
  year: "jaar",
  Units: "Eenheden",
  on: "op",

  Pages: "Pagina's",
  Libraries: "Bibliotheken",
  Configuration: "Configuratie",
  Connections: "Verbindingen",

  Back: "Terug",
  Edit: "Bewerken",
  Cancel: "Annuleren",
  Canceled: "Geannuleerd",
  Save: "Opslaan",
  Close: "Sluiten",
  Delete: "Verwijderen",
  Previous: "Vorige",
  Next: "Volgende",
  Clear: "Opnieuw",
  "Next year": "Volgend jaar",
  "Previous year": "Vorig jaar",
  Year: "Jaar",

  // AI
  "AI functions": "AI functies",
  "Analyse document": "Analyseer document",
  "The AI service has found new information!":
    "De AI dienst heeft nieuwe informatie gevonden!",
  "Show AI suggestion": "Toon AI suggestie",

  // Notifications
  Notifications: "Meldingen",
  Notification: "Melding",
  "New notification": "Nieuwe melding",
  "New notifications": "Nieuwe meldingen",
  "No new notifications": "Geen nieuwe meldingen",
  "Show all notifications": "Toon alle meldingen",
  "Contract Expiry": "Contract verloopt",
  "Most Recent": "Meest recente",
  "A notification will be sent when the contract is about to expire":
    "Er wordt een melding verzonden wanneer het contract bijna verloopt",

  // Settings
  Settings: "Instellingen",
  General: "Algemeen",
  "Choose default language": "Kies standaardtaal",
  "Notification period": "Notificatieperiode",
  "Notification period description":
    "Geef aan hoe lang van tevoren je een notificatie wilt ontvangen voordat een contract verloopt.",
  Language: "Taal",

  // Dashboard
  "Latest contracts": "Recente contracten",
  "Upcoming expirations": "Aflopende contracten",
  "Top vendors": "Top leveranciers",
  Unknown: "Onbekend",
  Others: "Overig",
  "Artificial intelligence recommendations":
    "Kunstmatige intelligentie aanbevelingen",
  Today: "Vandaag",
  Tomorrow: "Morgen",
  this_week: "deze week",
  this_month: "deze maand",
  upcoming_months: "komende maanden",
  this_year: "dit jaar",

  upcoming_months_detailed: "komende maanden (gedetailleerd)",
  "Potential analysis": "Potentiële analyse",
  "Needs attention": "Aandacht nodig",
  "Missing contract value": "Ontbrekende contractwaarde",
  "Unmanaged contracts": "Onbeheerde contracten",
  "not analyzed by AI": "Niet geanalyseerd door AI",
  "contracts expiring within 3 months": "Contracten verlopen binnen 3 maanden",
  "Contracts expiring in the next 12 months":
    "Contracten die verlopen binnen 12 maanden",
  "How many contracts are expiring soon?":
    "Hoeveel contracten verlopen binnenkort?",

  "contracts have an unknown value": "Contracten met een onbekende waarde",
  "contracts without owner": "Contracten zonder eigenaar",
  "This page is only visible by authenticated users.":
    "Deze pagina is alleen zichtbaar voor geauthenticeerde gebruikers.",
  "Ask a question about your data":
    "Ik ben je AI Assistent. Stel een vraag over je data...",
  "Ask a question about your contracts":
    "Ik ben je AI Assistent. Stel een vraag over je contracten...",
  "Ask a question about your vendors":
    "Ik ben je AI Assistent. Stel een vraag over je leveranciers...",
  "Ask a question about your events":
    "Ik ben je AI Assistent. Stel een vraag over je gebeurtenissen...",
  "Ask a question about this contract":
    "Ik ben je AI Assistent. Stel een vraag over dit contract...",
  "Ask a question about this vendor":
    "Ik ben je AI Assistent. Stel een vraag over deze leverancier...",
  "Ask a question about prompting":
    "Ik ben je AI Assistent. Stel een vraag over prompten...",
  "Ask a question": "Stel je vraag...",
  "Need help?": "Hulp nodig?",
  "Drag me": "Sleep om te verplaatsen",
  "I don't have an answer to that yet": "Ik heb daar nog geen antwoord op.",
  "Start with a prompt": "Begin met een prompt",
  "Chat with": "Chat met {{botName}}",
  Conversations: "Gesprekken",
  "Current conversations": "Alle gesprekken",
  "Recent conversations": "Recente gesprekken",
  "Contracts without": "Contracten zonder",

  "Risk analysis": "AI Risicoanalyse",
  Analyze: "Analyseer",
  Analyzed: "Geanalyseerd",
  "contract with Artificial Intelligence":
    "contract met Kunstmatige Intelligentie",
  "Start a risk analysis for this contract":
    "Start een risicoanalyse voor dit contract",
  "Contract has not been analyzed": "Contract is nog niet geanalyseerd",
  "The AI service will analyze the contract and extract the following information":
    "De AI dienst zal het contract analyseren en de volgende informatie ophalen",
  "Start AI analysis": "Start AI analyse",
  "Continue without AI analysis": "Doorgaan zonder AI analyse",

  // Admin
  Admin: "Beheer",
  "Trusted Partners": "Vertrouwde partners",
  "Partner ID": "Partner ID",
  "Enter the tenant id of the trusted partner":
    "Vul het tenant id van de vertrouwde partner in",
  "New trusted partner": "Nieuwe vertrouwde partner",
  "Name of the organization requesting contract management support":
    "Naam van de organisatie die contractbeheerondersteuning aanvraagt",
  "Name of the Trusted Partner": "Naam van de vertrouwde partner",
  "Tenant ID of the Trusted Partner": "Tenant ID van de vertrouwde partner",
  "App ID": "Applicatie ID",
  "App Secret": "Applicatie geheim",
  "App ID of your app registration with Sites.Read.All permission":
    "App ID van je app registratie met Sites.Read.All permissie",
  "App Secret of your app registration with Sites.Read.All permission":
    "Applicatie geheim van je app registratie met Sites.Read.All permissie",
  "Invitation sent to": "Uitnodiging verzonden naar {{name}}",
  "Invitation sent on":
    "Uitnodiging verzonden door {{name}} op {{date, PPPPp}}",
  Revoke: "Intrekken",
  "Invitation received from": "Uitnodiging ontvangen van {{name}}",
  "Invitation received on":
    "Uitnodiging ontvangen van {{name}} op {{date, PPPPp}}",
  Accept: "Accepteren",
  Decline: "Afwijzen",
  accepted: "Geaccepteerd",
  declined: "Geweigerd",

  // Data Location
  "Data Location": "Opslaglocatie",
  Default: "Standaard",

  // Connected Drives
  "Connected Drives": "Schijf verbinden",
  "New connected drive": "Nieuwe schijf koppelen",
  "Connect your own cloud hosting service":
    "Verbind je eigen cloudopslagdienst",
  "Are you sure you want to delete this connected drive?":
    "Weet je zeker dat je deze schijf wilt ontkoppelen?",
  Disconnect: "Ontkoppelen",
  "SharePoint Connections": "SharePoint verbindingen",

  // Activities
  Activities: "Activiteiten",
  "Activity Log": "Activiteitenlogboek",
  "No activities": "Geen activiteiten",
  Event: "Gebeurtenis",
  Date: "Datum",
  Payload: "Gegevens",
  User: "Gebruiker",
  Created: "Aangemaakt",
  Updated: "Bijgewerkt",
  Deleted: "Verwijderd",
  Contract_deleted_activity:
    "{{userName}} heeft contract {{contractId}} verwijderd",
  by: "door",

  // Chat bot
  Hi: "Hoi",
  Home: "Home",
  Hello: "Hallo",
  How_can_we_help: "Hoe kunnen we helpen?",
  Send_us_a_message: "Start een nieuw gesprek",
  We_typically_reply_in_under_minutes:
    "Wij antwoorden doorgaans binnen {{time}} minuten",
  "Bot welcome message in popup": "Hoe kan ik je helpen?",
  "Initiate chat": "Klik hier om te beginnen",
  "Initiate chat subtitle": "",
  "Welcome message body":
    "Ik ben {{botName}}, je persoonlijke assistent. Ik heb alles geleerd over je contracten, en meer! Hoe kan ik je helpen?",
  Initiate_Document: "Document initiëren",
  AI_Analysis: "AI-analyse",
  Add_Signatories: "Ondertekenaars toevoegen",
  Finish_Document: "Voltooi document",
  Upload_contract: "Contract uploaden",
  Upload_contracts: "Contracten uploaden",
  Click_to_upload: "Klik om te uploaden",
  or_drag_and_drop: "of slepen en neerzetten",
  Maximum_file_size: "Maximale bestandsgrootte {{size}}MB",
  Maximum_files_reached: "Maximum aantal documenten bereikt",
  Maximum_files_reached_description:
    "Je kunt maximaal {{count}} documenten tegelijk selecteren.",
  Import_from_Microsoft_resources: "Importeren uit Microsoft 365",
  Import_from_Microsoft_resources_subtitle:
    "OneDrive, SharePoint, Teams en Outlook",
  Upload_or_import_to_see_here: "Upload of importeer om een document te zien.",
  No_files_found: "Geen bestanden gevonden",
  "Ask your question": "Stel je vraag",
  "Connected to": "Verbonden met",
  "Shared with me": "Gedeeld met mij",
  botName: "AI Assistent",
  "Conversations regarding this contract": "Gesprekken over dit contract",
  "No conversations regarding this contract":
    "Geen gesprekken over dit contract",
  "Conversations regarding this vendor": "Gesprekken over deze leverancier",
  "No conversations regarding this vendor":
    "Geen gesprekken over deze leverancier",
  "Other conversations": "Andere gesprekken",

  // AI Contract
  DRAFT: "Concept",
  PUBLISHED: "Gepubliceerd",
  SIGNING: "Ter ondertekening",
  SIGNED: "Ondertekend",
  ACTIVE: "Actief",
  ARCHIVED: "Gearchiveerd",
  EXPIRED: "Verlopen",
  ALL: "Alle",
  Signing: "Ter ondertekening",
  "Signees who signed": "Handtekeningen",
  "Awaiting your signature": "Wacht op jouw handtekening",
  Requested: "Aangevraagd",

  Attachments: "Bijlagen",
  "Add attachment": "Bijlage toevoegen",
  "No attachments": "Geen bijlagen",
  published: "Gepubliceerd",
  draft: "Concept",
  drafts: "Concepten",
  "My draft contracts": "Mijn concept contracten",
  Published: "Gepubliceerd",
  contracts: "contracten",
  "Recent contracts": "Recente contracten",

  "Start date must be before end date":
    "De begindatum moet vóór de einddatum zijn",
  "End date must be after start date": "Einddatum moet na de startdatum liggen",
  "End date must be after today": "Einddatum moet na vandaag zijn",
  "Re-analysis": "Heranalyse",
  "Ok to process": "Ok om te verwerken",
  "GoBeyondProcurement is analyzing the document":
    "GoBeyondProcurement analyseert het document.",
  "The document is analyzed successfully":
    "Het document is met succes geanalyseerd!",
  "Analysis requested": "Analyse wordt gestart",

  Start_AI_analysis: "Start AI-analyse",
  Contract_has_been_analyzed: "Contract is geanalyseerd",
  Contract_has_not_been_analyzed: "Contract is niet geanalyseerd",
  "Please check the necessary contract information in the form below and update accordingly. When requesting a change we will kindly ask you to provide more details about the contract so we can send this to the AI.":
    "Controleer de benodigde contractinformatie en voer indien nodig een heranalyse uit.",
  "Please check the necessary contract information on the left section":
    "Controleer de benodigde contractinformatie in het linkergedeelte.",
  "Contract details": "Informatie",
  "Contract management": "Management",
  "Basic details": "Basisinformatie",
  "Fields found": "Bepalingen",
  "Fields not found": "Niet aanwezig",
  "Please review the contract carefully before you sign.":
    "Lees het contract zorgvuldig door voordat je tekent.",
  "This action cannot be undone.":
    "Deze actie kan niet ongedaan worden gemaakt.",
  Sign: "Ondertekenen",
  Signatures: "Handtekeningen",
  Formatting: "Opmaak",
  "Strength matrix": "Sterkte matrix",
  "View in contract": "Bekijk in contract",
  "Fields performance": "Volledigheid van de contracten",
  "Contract performance": "Volledigheid van het contract",
  "Fields performance description":
    "Staan alle relevante bepalingen in het contract?",
  "All relevant contracts mention something about":
    "Alle relevante contracten bevatten een bepaling m.b.t. {{subject}}.",
  "None of the relevant contracts mention something about":
    "Geen van de relevante contracten bevat een bepaling m.b.t. {{subject}}.",
  "Some of the relevant contracts mention something about":
    "{{count}} contracten bevatten geen bepaling m.b.t. {{subject}}.",
  "1 contract does not mention something about":
    "{{count}} contract bevat geen bepaling m.b.t. {{subject}}.",

  Reject: "Afwijzen",
  Invitees: "Genodigden",
  "List of signees to provide signatures for the contract":
    "Lijst van ondertekenaars om handtekeningen voor het contract te leveren.",
  "There are no signees.": "Er zijn geen ondertekenaars.",
  "Click on “Add signatories” to request digital signatures.":
    "Klik op 'Ondertekenaars toevoegen' om digitale handtekeningen aan te vragen.",
  "Edit the contract to add signatories.":
    "Bewerk het contract om ondertekenaars toe te voegen.",

  "Please check the information of the contract before you sign. This action is irreversible.":
    "Controleer de informatie van het contract voordat je tekent. Deze actie is onomkeerbaar.",
  "Add myself": "Voeg mezelf toe",
  "Manager needs to sign": "Manager moet tekenen",
  "Owner needs to sign": "Eigenaar moet tekenen",
  "You have signed this contract.": "Je hebt dit contract ondertekend.",
  "You have rejected this contract.": "Je hebt dit contract afgewezen.",

  Receipt: "Ontvangstbewijs",
  "Signing as": "Ondertekenen als:",
  "Signatures were updated": "Handtekeningen zijn bijgewerkt",
  "Send for signing": "Verstuur ter ondertekening",
  "Check the signature fields on the contract before you send the invitation. Drag them to the correct position if needed.":
    "Controleer de handtekeningsvelden op het contract voordat je de uitnodiging verstuurt. Sleep ze indien nodig naar de juiste positie.",
  "Move to first page": "Verplaats naar eerste pagina",
  "Move to last page": "Verplaats naar laatste pagina",
  "Move to previous page": "Verplaats naar vorige pagina",
  "Move to next page": "Verplaats naar volgende pagina",

  Manage: "Beheren",
  Signed: "Ondertekend",
  Not_sign: "Niet ondertekend",
  Rejected: "Afgewezen",
  Remove: "Verwijderen",
  Signees: "Ondertekenaars",
  recipient: "ontvanger",
  recipients: "ontvangers",
  Invite_Signees: "Ondertekenaars uitnodigen",
  "Invite the users you want to sign this contract":
    "Nodig de personen uit die dit contract moeten ondertekenen.",
  "Contract owner needs to signs": "Contracteigenaar moet tekenen",
  Signee_existed: "Ondertekenaar bestaat al",
  Email_address: "E-mailadres",
  Invalid_email_address: "Ongeldig e-mailadres",
  Continue: "OK",
  Reject_to_Sign: "Niet ondertekenen",
  "Are you sure you want to reject this invitation? This action cannot be undone.":
    "Weet je zeker dat je dit document niet wilt ondertekenen? Deze actie kan niet ongedaan worden gemaakt.",
  Reason: "Reden",
  Document_Signature: "Handtekening van het document",
  Draw: "Tekenen",
  Type: "Type",
  Upload: "Uploaden",
  "Draw your signature in the blank box below":
    "Teken uw handtekening in het lege vak hieronder.",
  "Draw your signature file in the blank box below":
    "Teken uw handtekeningbestand in het lege vak hieronder.",
  Signature: "Handtekening",
  Type_here: "Typ hier",
  "Click on 'Next' to proceed to the next step":
    "Klik op 'Volgende' om door te gaan naar de volgende stap.",
  "Document has been uploaded": "Document is geüpload",
  Check: "Controleren",
  Send: "Versturen",
  "Next step": "Volgende stap",
  "Previous step": "Vorige stap",
  Finish: "Voltooien",
  "Sign for": "Handtekening voor",
  "click here": "klik hier",
  "A signature request has been sent to the following persons:":
    "Er is een notificatiemail verstuurd naar onderstaande personen: ",
  "Drag and drop the signature fields":
    "Sleep de handtekeningsvelden naar de juiste positie",
  "Click on the button below to send the signature request to the signees.":
    "Klik op de knop hieronder om de handtekeningverzoek naar de genodigden te sturen.",
  "The invitees will receive an email with a link to the contract.":
    "De genodigden ontvangen een e-mail met een link naar de ondertekenpagina.",

  // error pages
  "Access Denied": "Toegang geweigerd",
  "Link Expired": "Link verlopen",
  "You don’t have permission to access this page. Please contact an administrator to get permission or go to the home page and browse other pages.":
    "Je hebt geen toestemming om toegang te krijgen tot deze pagina. Neem contact op met een beheerder om toestemming te krijgen of ga naar de startpagina en blader door andere pagina's.",
  "The link you are accessing might have been expired or is temporarily unavailable. Click to contact us for support or send a new request to contract owner for new access.":
    "De link die u opent, is mogelijk verlopen of tijdelijk niet beschikbaar. Klik om contact met ons op te nemen voor ondersteuning of stuur een nieuw verzoek naar de contracteigenaar voor nieuwe toegang.",
  "Contact us": "Neem contact met ons op",
  "Go to home": "Ga naar de startpagina",
  Request: "Verzoek",

  // Analyze dialog
  contract_analysis_tracking: "AI contractanalyses ({{quantity}})",
  analyzed_contracts: "Geanalyseerde {{quanity}} bestanden",
  "Wasn't analyzed": "Is niet geanalyseerd",

  "Save as Draft": "Opslaan als concept",
  "Quick edit": "Snelle aanpassing",

  "There are no published contracts to display":
    "Er zijn geen gepubliceerde contracten om weer te geven",
  Import1File: "1 bestand toevoegen",
  ImportXFiles: "{{count}} bestanden toevoegen",
  Import1Contract: "1 contract toevoegen",
  ImportXContracts: "{{count}} contracten toevoegen",
  Analyzing_contracts: "Analyseren contract(en) ({{result}} resterend)",
  "Contracts have been analyzed": "Contracten zijn geanalyseerd",

  Reading_contracts: "Inlezen contract(en) ({{result}} resterend)",
  "Contracts have been read": "Contracten zijn ingelezen",
  "1 contract read": "1 ingelezen contract",
  "X contracts read": "{{count}} ingelezen contracten",

  Syncing_vendors: "{{count}} leveranciers synchroniseren met KVK",
  "Vendors have been synced with KVK":
    "Leveranciers zijn gesynchroniseerd met KVK",

  KVK: "Kamer van Koophandel (KVK)",
  "Registration number": "Registratienummer",
  "Fraud detection": "Fraudedetectie",
  "Retrieve company profile": "Bedrijfsprofiel synchroniseren",
  "Founded date": "Oprichtingsdatum",
  "Founded year": "Jaar van oprichting",
  Founded: "Oprichting",
  "KVK number": "KVK-nummer",
  "Company profile": "Bedrijfsprofiel",
  "Search in KVK": "Zoek op handelsnaam",
  Companies: "Bedrijven",
  "Press enter to search": "Druk op enter om te zoeken",
  "Search in the business register of The Netherlands":
    "Zoeken in het handelsregister (kvk.nl)",
  "Enter manually": "Handmatig invoeren",
  "Use the web form to enter the company details manually":
    "Gebruik het webformulier om de bedrijfsgegevens handmatig in te voeren",
  "Add vendors": "Leverancier(s) toevoegen",
  "No results": "Geen resultaten",
  "Vendor page": "Leverancierspagina",

  // level: "Niveau {{level}}",
  "level 0": "Model-P3",
  "level 1": "Model-P1",
  "level 2": "Model-P2",

  "Estimate costs": "Kosten opvragen",
  Estimated_costs_result: "Geschatte kosten: {{cost}}",

  // custom fields
  Leveringsvoorwaarden: "Leveringsvoorwaarden",
  Borgstelling: "Borgstelling",
  Transitieproces: "Transitieproces",
  Stakeholders: "Stakeholders",
  "Collectieve inkoop": "Collectieve inkoop",
  Markttoetsing: "Markttoetsing",
  "Afspraken over duurzaamheid": "Afspraken over duurzaamheid",
  "Vergoedingen en tarieven": "Vergoedingen en tarieven",
  "Onrechtmatige beëindiging": "Onrechtmatige beëindiging",
  Misverstand: "Misverstand",
  "Ingebrekestelling / Niet-nakoming": "Ingebrekestelling / Niet-nakoming",
  "Contractuele boetes / boeteclausule": "Contractuele boetes / boeteclausule",
  Afnameverplichting: "Afnameverplichting",
  Contractduur: "Contractduur",
  Indexatie: "Indexatie",
  "Overdracht van rechten en plichten": "Overdracht van rechten en plichten",
  Prestaties: "Prestaties",
  "Toepasselijk recht": "Toepasselijk recht",
  Verlengingsclausule: "Verlengingsclausule",
  Opschortingsrecht: "Opschortingsrecht",
  "Non-concurrentiebeding of Exclusiviteitsclausule":
    "Non-concurrentiebeding of Exclusiviteitsclausule",
  "Force majeure": "Force majeure",
  Garanties: "Garanties",
  Betalingsvoorwaarden: "Betalingsvoorwaarden",
  "Intellectuele eigendomsrechten": "Intellectuele eigendomsrechten",
  "Beëindiging en opzegging": "Beëindiging en opzegging",
  Geschillenoplossing: "Geschillenoplossing",
  Vertrouwelijkheid: "Vertrouwelijkheid",
  Schadeloosstelling: "Schadeloosstelling",
  Aansprakelijkheid: "Aansprakelijkheid",
  Bonusafspraken: "Bonusafspraken",
  Partijen: "Partijen",
  Beëindiging: "Beëindiging",
  "Bonus afspraken": "Bonusafspraken",
  Duurzaamheid: "Duurzaamheid",
  Geschillen: "Geschillen",
  "Non-concurrentiebeding": "Non-concurrentiebeding",

  "Sync now": "Nu synchroniseren",
  "Successfully synchronized": "{{count}} contract(en) gesynchroniseerd",
  Complete: "Compleet",
  "View details": "Bekijk details",
  "View contract": "Bekijk contract",
  "Not synced": "Niet gesynchroniseerd",
  "Contract not found": "Contract niet gevonden.",
  subscriptionInfo_one: "Abonnement: {{count}} contract resterend",
  subscriptionInfo_other: "Abonnement: {{count}} contracten resterend",

  hidden_prompts: "Verborgen prompts: {{count}}",
  Prompt_visible: "Prompt is zichtbaar. Klik om te verbergen.",
  Prompt_hidden: "Prompt is verborgen. Klik om te tonen.",
  Subscription_limit_reached: "Abonnementslimiet bereikt",
  Subscription_limit_reached_description:
    "Je hebt het maximale aantal contracten bereikt. Upgrade je abonnement om meer contracten toe te voegen.",

  Folders: "Mappen",
  folder: "map",
  "New folder": "Nieuwe map",

  "Subscription expired": "Abonnement verlopen",
  "Please renew your subscription to continue using the app":
    "Vernieuw je abonnement om de applicatie te blijven gebruiken.",

  signOutCountDownMessage_one: "Je wordt over {{count}} seconde uitgelogd.",
  signOutCountDownMessage_other: "Je wordt over {{count}} seconden uitgelogd.",

  "Connected to MS Teams ID": "Verbonden met Microsoft Teams ID: {{id}}",
  "Verified by": "Geverifieerd door {{name}} op {{date, PPPp}}",
  "This is correct": "Dit is correct",
  "This is incorrect": "Dit is incorrect",
  "Processed at": "Gevonden op {{date, PPPp}}",
  "AI is incorrect": "Leg uit waarom de AI onjuist is",

  Relevant_paragraphs_one: "Gebaseerd op {{count}} relevante paragraaf",
  Relevant_paragraphs_other: "Gebaseerd op {{count}} relevante paragrafen",
};
