import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { blue, grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import {
  Box,
  Chip as MuiChip,
  Icon,
  LinearProgress,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  StepButton,
  Typography,
  useMediaQuery,
  useTheme,
  Menu,
  ListItemSecondaryAction,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  Fade,
  Alert,
  AlertTitle,
  Card,
  CardContent,
  Stack,
  CardActions,
  Button,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  AutoAwesome,
  CheckCircleOutlined,
  FileDownloadOutlined,
  FileUploadOutlined,
  InsertDriveFileOutlined,
  MoreHoriz as MoreHorizIcon,
  Person,
  Store,
} from "@mui/icons-material";
import { FileUploader } from "react-drag-drop-files";
import { DialogMode } from "../../../../types/dialogmode";
import { IContract } from "../../../../types/contract";
import { IDocument } from "../../../../types/document";
import CustomNoRowsOverlay from "../../../../components/datagrids/CustomNoRowsOverlay";
import { spacing } from "@mui/system";
import FileAsListItem from "../../../../components/lists/listitems/FileAsListItem";
import {
  useAddDocumentContentMutation,
  useAddDocumentMutation,
  useAddHumanDataVerificationMutation,
  useDeleteDocumentMutation,
  useGetAttachmentsQuery,
  useGetDocumentsQuery,
  useGetHumanDataVerificationsQuery,
  useUpdateContractMutation,
} from "../../../../redux/slices/indexApiSlice";
import { useNavigate, useParams } from "react-router-dom";
import FilesToUploadList from "../../../../components/lists/FilesToUploadList";
import { IVendor } from "../../../../types/vendor";
import { set } from "date-fns";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import VendorAsListItem from "../../../../components/lists/listitems/VendorAsListItem";
import VendorAvatar from "../../../../components/avatars/VendorAvatar";
import OwlAvatarIcon from "../../../../icons/OwlAvatarIcon";
import { C } from "@fullcalendar/core/internal-common";
import { ContractExtractionResult } from "../../../../types/aiOutput";
import useAuth from "../../../../hooks/useAuth";
import VerifiedAISuggestion from "./VerifiedAISuggestion";
import AISuggestion from "./AISuggestion";

const UploadButton = styled(MuiPaper)`
  height: 144px;
  border: 1px dashed ${(props) => props.theme.palette.divider};
  padding: 24px;
  cursor: pointer;
  &:hover {
    border: 1px dashed ${(props) => props.theme.palette.primary.main};
  }
`;

const PartyDetails = ({
  name,
  address,
  contactPersons,
}: {
  name: string;
  address: string;
  contactPersons: { name: string; role: string }[];
}) => {
  return (
    <Typography variant="body2">
      <Box>
        <Box>{name}</Box>
        <Box>{address}</Box>
      </Box>
    </Typography>
  );
};

interface IContractPartiesProps {
  contract: IContract;
  aiOutput?: ContractExtractionResult;
  hideTitle?: boolean;
  setBadgeCount?: (count: number) => void;
}
function ContractParties({ ...props }: IContractPartiesProps) {
  const { contract } = props;
  const { user } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [updateContract] = useUpdateContractMutation();
  const [addVerification] = useAddHumanDataVerificationMutation();
  const { data: verifications } = useGetHumanDataVerificationsQuery(contract);

  const confirmAiSuggestion = async () => {
    if (!contract) return;

    const updatedContract: IContract = {
      ...contract,
      buyer: props.aiOutput?.metadata?.structuralData?.buyer,
      seller: props.aiOutput?.metadata?.structuralData?.seller,
      vendorName: props.aiOutput?.metadata?.structuralData?.seller?.name,
      vendorAddress: props.aiOutput?.metadata?.structuralData?.seller?.address,
      vendorId: "", // TO DO
    };

    addVerification({
      contractId: contract.id,
      field: "contractParties",
      verifiedBy: user.displayName,
      verifiedDate: new Date().toUTCString(),
    });

    await updateContract(updatedContract);
  };

  const isVerified =
    contract?.buyer ||
    contract?.seller ||
    contract?.vendorName ||
    contract?.vendorId
      ? true
      : false;

  const hasAiSuggestion =
    props.aiOutput?.metadata?.structuralData?.buyer ||
    props.aiOutput?.metadata?.structuralData?.seller
      ? true
      : false;

  const verificationRecord = verifications?.find(
    (v) => v.field === "contractParties"
  );
  const verifiedBy = verificationRecord?.verifiedBy;
  const verifiedDate = verificationRecord?.verifiedDate;

  return (
    <Box pb={2}>
      {!props.hideTitle && (
        <Typography variant="h6" mb={2}>
          {t("Parties")}
        </Typography>
      )}

      {isVerified && (
        <VerifiedAISuggestion
          isVerified={isVerified}
          content={
            <Stack gap={2}>
              {contract?.seller && (
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="body2" mb={2}>
                      {t("Vendor")}
                    </Typography>

                    {contract?.seller && <PartyDetails {...contract.seller} />}

                    {contract?.explanations?.seller && (
                      <Alert
                        sx={{ mt: 2 }}
                        severity="info"
                        variant="outlined"
                        icon={<OwlAvatarIcon />}
                      >
                        <Typography variant="body1">
                          {contract?.explanations.seller}
                        </Typography>
                      </Alert>
                    )}

                    {contract?.vendorId && (
                      <Button
                        sx={{ mt: 3, mb: 2 }}
                        size="small"
                        color="primary"
                        variant="outlined"
                        startIcon={<Store />}
                        onClick={() => navigate(`/vendor/${contract.vendorId}`)}
                      >
                        {t("Vendor page")}
                      </Button>
                    )}
                  </CardContent>
                </Card>
              )}

              {contract?.buyer && (
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="body2" mb={2}>
                      {t("Customer")}
                    </Typography>

                    {contract?.buyer && <PartyDetails {...contract.buyer} />}

                    {contract?.explanations?.buyer && (
                      <Alert
                        sx={{ mt: 2 }}
                        severity="info"
                        variant="outlined"
                        icon={<OwlAvatarIcon />}
                      >
                        <Typography variant="body1">
                          {contract?.explanations.buyer}
                        </Typography>
                      </Alert>
                    )}
                  </CardContent>
                </Card>
              )}

              {contract?.vendorId && (
                <Button
                  sx={{ mt: 3, mb: 2 }}
                  size="small"
                  color="primary"
                  variant="outlined"
                  startIcon={<Store />}
                  onClick={() => navigate(`/vendor/${contract.vendorId}`)}
                >
                  {t("Vendor page")}
                </Button>
              )}
            </Stack>
          }
          verifiedBy={verifiedBy}
          verifiedDate={verifiedDate}
        />
      )}

      {hasAiSuggestion && (
        <AISuggestion
          contract={contract}
          isAiSuggestion={hasAiSuggestion}
          isVerified={isVerified || verificationRecord?.isCorrect !== undefined}
          field="buyer"
          value={props.aiOutput?.metadata?.structuralData?.buyer}
          field2="seller"
          value2={props.aiOutput?.metadata?.structuralData?.seller}
          content={
            <Stack gap={2}>
              {props.aiOutput?.metadata?.structuralData?.seller && (
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="body2" mb={2}>
                      {t("Vendor")}
                    </Typography>

                    <PartyDetails
                      {...props.aiOutput?.metadata?.structuralData?.seller}
                    />

                    {props.aiOutput?.metadata?.structuralData?.seller
                      ?.explanation && (
                      <Alert
                        sx={{ mt: 2 }}
                        severity="info"
                        variant="outlined"
                        icon={<OwlAvatarIcon />}
                      >
                        <Typography variant="body1">
                          {
                            props.aiOutput?.metadata?.structuralData?.seller
                              ?.explanation
                          }
                        </Typography>
                      </Alert>
                    )}
                  </CardContent>
                </Card>
              )}

              {props.aiOutput?.metadata?.structuralData?.buyer && (
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="body2" mb={2}>
                      {t("Customer")}
                    </Typography>

                    <PartyDetails
                      {...props.aiOutput?.metadata?.structuralData?.buyer}
                    />

                    {props.aiOutput?.metadata?.structuralData?.buyer
                      ?.explanation && (
                      <Alert
                        sx={{ mt: 2 }}
                        severity="info"
                        variant="outlined"
                        icon={<OwlAvatarIcon />}
                      >
                        <Typography variant="body1">
                          {
                            props.aiOutput?.metadata?.structuralData?.buyer
                              ?.explanation
                          }
                        </Typography>
                      </Alert>
                    )}
                  </CardContent>
                </Card>
              )}
            </Stack>
          }
          processedAt={props.aiOutput?.processedAt}
        />
      )}

      {/* {contract?.explanations?.seller && (
                <Alert
                  sx={{ mt: 2 }}
                  severity="info"
                  variant="outlined"
                  icon={<OwlAvatarIcon />}
                >
                  <Typography variant="body1">
                    {contract?.explanations.seller}
                  </Typography>
                </Alert>
              )} */}
    </Box>
  );
}

export default ContractParties;
